import axios from 'axios';

const hosts = {
    report: "/api/report/terminalturnover",
    users:"/api/marketinguser/users",
    marketingoverview:"/api/report/marketingoverview",
    marketingterminal:"/api/report/marketingterminal"
}

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}

export const reportService = {
    report,
    users,
    marketingoverview,
    marketingterminal
}
//营销用户获取终端业务报表
async function marketingoverview(userid,begin,end) {
    const response=await myget(hosts.marketingoverview,{
        params:{
            userid:userid,
            begin:begin,
            end:end
        }
    })
    return response;
    
}
//根据登录用户获取分成用户
async function users(userid) {
    const response = await myget(hosts.users,{
        params:{
            userid:userid
        }
    })
    return response; 
}
//根据时间生成报表
async function report(begin, end) {
    const response = await myget(hosts.report, {
        params: {
            begin: begin,
            end: end
        }
    })
    return response;
}
//获取终端业务报表(管理员)
async function  marketingterminal(begin,end) {
    const response=await myget(hosts.marketingterminal,{
        params:{
            begin: begin,
            end: end
        }
    })
    return response;
}