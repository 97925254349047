import React from 'react';
import { orderService } from '../../services';
import { connect } from 'react-redux';
import {
    Descriptions,
    Table,
    Tag,
    Modal,
    Button,
    Input,
    message,
    Form,
    Spin,
    Radio,
} from 'antd';
import CanceSettlement from '../OrdersPage/CanceSettlement'

class OrderModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: props.order.id,
            orderId: props.order.orderId,
            status: props.order.status,
            data: {},
            qualify: [],
            refundVisible: false,
            loggingIn: true,
            refunding: false,
            settlementVisible: false,
        };
    }
    //显示Model事件
    showRefundModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            refundVisible: true,
        });
    };
    //显示取消结算model事件
    showSettlementModal = (order) => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            selected: order,
            settlementVisible: true
        });
    };

    //退款提交事件
    handleSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { refundFee, pwd, remark, IsFold } = values;
                    try {
                        this.setState({ refunding: true });
                        await orderService
                            .refund(this.state.order, parseFloat(refundFee), pwd, remark, IsFold)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    if (!result.success) {
                                        message.error(result.message);
                                    }
                                    else {
                                        const order = this.state.data;
                                        order.status = 512;
                                        message.success('退款成功');
                                        this.setState({
                                            refundVisible: false,
                                            status: 512,
                                            data: order
                                        })
                                        this.props.handlePaging(null, 1, this.state.pagesize);
                                    }
                                }
                            })
                    }
                    catch (ex) {
                        message.error('退款失败，请检查网络', 5)
                    }
                    this.setState({
                        refunding: false
                    });
                }
                catch (ex) {
                    alert(ex);
                }
            }
        });
    };
    //关闭model事件
    handleRefundCancel = () => {
        this.setState({
            refundVisible: false,
        });
    };

    //关闭取消结算model事件
    handleSettlementCancel = () => {
        const { data } = this.state;
        data.status = 128;
        this.setState({
            settlementVisible: false,
            status: 128,
            data: data
        });
    };

    renderKey(key) {
        if (key === 'terminalId') {
            return '终端机编号';
        }
        if (key === 'orderId') {
            return '订单号';
        }
        if (key === 'status') {
            return '订单状态';
        }
        if (key === 'title') {
            return '标题';
        }
        if (key === 'amount') {
            return '金额'
        }
        if (key === 'paymentNo') {
            return '支付单号';
        }
        if (key === 'payTime') {
            return '支付时间';
        }
        if (key === 'method') {
            return '支付方式';
        }
        if (key === 'remark') {
            return '备注';
        }

        return key;
    }

    renderValue(key, value) {
        if (key === 'method') {
            switch (value) {
                case 257:
                    return (
                        <Tag color="green" >
                            微信支付
            </Tag>)
                case 258:
                    return (
                        <Tag color="blue" >
                            支付宝
            </Tag>)
                case 16:
                    return (
                        <Tag color="gold" >
                            现金
            </Tag>)
                default:
                    return (
                        <Tag color="red" >
                            未知
            </Tag>)
            }
        }
        if (key === 'status') {
            switch (value) {
                case 0:
                    return (
                        <Tag color="skyblue" >
                            已下单
                        </Tag>)
                case 16:
                    return (
                        <Tag color="#bababa" >
                            已关闭
                        </Tag>
                    )
                case 128:
                    return (
                        <Tag color="#0cef7d" >
                            已支付
                        </Tag>
                    )
                case 512:
                    return (
                        <Tag color="#4f4f4f" >
                            已退款
                        </Tag>
                    )
                case 525:
                    return (
                        <Tag color="#868686" >
                            部分退款
                        </Tag>
                    )
                case 1024:
                    return (
                        <Tag color="#0693e3" >
                            已完成
                        </Tag>
                    )
            }
        }
        return value;
    }
    //加载数据
    componentDidMount() {
        try {
            var response = orderService.order(this.state.order)
                .then(response => {
                    if (response) {
                        var body = response.data;
                        if (!body.success) {
                        }
                        var data = body.data;
                        if (data.qualifyphotolog) {
                            data.qualifyphotolog.map((item, index) =>
                                item['key'] = index
                            );
                        }
                        this.setState({
                            data: data.order,
                            qualify: data.qualifyphotolog,
                            loggingIn: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }

    render() {
        const { data } = this.state;
        const { refundVisible } = this.state;
        const { settlementVisible } = this.state;
        const { loggingIn } = this.state;
        const { refunding } = this.state;
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: '认证编号',
                dataIndex: 'zztCode',
                key: 'zztCode',
                width: 400,
            },
            {
                title: '认证模式',
                dataIndex: 'zztUserId',
                key: 'zztUserId',
                width: 400,
                render: (text) => {
                    if (text) {
                        return (
                            <Tag color="green">
                                接口
              </Tag>
                        )
                    }
                    else {
                        return (
                            <Tag color="gold">
                                模拟
              </Tag>
                        )
                    }
                }
            },
            {
                title: '审核状态',
                dataIndex: 'status',
                key: 'status',
                width: 400,
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                待审核
              </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="#0cef7d" key={record.status}>
                                已通过
              </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="#bababa" key={record.status}>
                                未通过
              </Tag>
                        )
                    }
                }
            },
            {
                title: '审核意见',
                dataIndex: 'comment',
                key: 'comment',
                width: 600,
            },
            {
                title: '操作',
                dataIndex: 'receipt',
                key: 'receipt',
                width: 600,
                render: (text, record) => {
                    if (record.receipt !== null) {
                        return (
                            <a target="_blank" href={record.receipt}>查看回执</a>
                        )
                    }
                }
            }
        ]
        return (
            <Spin tip="加载中..." spinning={loggingIn}>

                <Descriptions bordered title="订单信息" border >
                    {
                        Object.keys(data).filter(key => {
                            //filter过滤数组中元素
                            if (key === 'id') return false;
                            if (key === 'key') return false;
                            return true;
                        }).map(key =>
                            <Descriptions.Item
                                key={key}
                                label={this.renderKey(key)}>
                                {this.renderValue(key, data[key])}
                            </Descriptions.Item>
                        )
                    }
                </Descriptions>
                {
                    this.state.qualify && this.state.qualify.length != 0 ?
                        <Table
                            columns={columns}
                            style={{ marginTop: 20 }}
                            dataSource={this.state.qualify}
                            pagination={false} /> : null
                }
                {
                    this.state.data.status === 1024 ?
                        <Button
                            type="danger"
                            onClick={this.showSettlementModal}
                            style={{ float: "right", marginTop: 20, marginLeft: 5 }}>
                            取消结算
                    </Button> : null
                }

                {
                    this.state.data.status === 128 || this.state.data.status === 525 ?
                        <Button
                            type="danger"
                            value={this.state.pwdChange}
                            onClick={this.showRefundModal}
                            style={{ float: "right", marginTop: 20 }}>
                            退款
                      </Button> : null
                }
                <Modal
                    title='取消结算'
                    visible={settlementVisible}
                    onCancel={() => this.handleSettlementCancel()}
                    footer={null}
                >
                    <CanceSettlement
                        order={this.state.orderId}
                        handlePaging={this.props.handlePaging}
                        orderstaus={this.props.order}
                        handleSettlementCancel={this.handleSettlementCancel.bind(this)}
                    />

                </Modal>

                <Modal
                    title='确认退款'
                    visible={refundVisible}
                    onCancel={() => this.handleRefundCancel()}
                    footer={null}
                >
                    <Spin tip="加载中..." spinning={refunding}>
                        <Form layout="vertical" onSubmit={e => this.handleSubmit(e)}>
                            <Form.Item label="部分退款策略">
                                {
                                    getFieldDecorator('IsFold',
                                        {
                                            initialValue: true
                                        })(
                                            <Radio.Group>
                                                <Radio.Button defaultChecked={true}
                                                    value={true}>订单作废</Radio.Button>
                                                <Radio.Button value={false}>新增订单</Radio.Button>
                                            </Radio.Group>
                                        )}
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('refundFee', {
                                        rules: [{
                                            required: false,
                                            pattern: new RegExp(/^\d+((.)\d+)?$/, ""),
                                            message: '请输入正确退款金额'
                                        }],
                                        initialValue: ''
                                    })
                                        (<Input
                                            placeholder="请输入数字"
                                            addonBefore="退款金额"
                                            addonAfter="元" />)}
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('pwd', {
                                        rules: [{
                                            required: true,
                                            message: '请输入当前管理员密码!'
                                        }],
                                    })(
                                        <Input.Password placeholder="请输入当前管理员密码" />)}
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('remark', {
                                        rules: [{
                                            required: false
                                        }],
                                    })(
                                        <TextArea style={{ resize: "none" }}
                                            placeholder="请输入需要备注的信息"
                                            autosize={{ minRows: 3, maxRows: 3 }}
                                        />)
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button key='confirm' htmlType="submit"
                                    type="primary" style={{ float: "right" }}>
                                    确认
                                 </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Spin>
        )
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const OrderModuleFrom = Form.create()(OrderModule)
const connectedOrederModule = connect(mapState, null)(OrderModuleFrom);

export default connectedOrederModule