import React from 'react';
import { Button, Descriptions, Tag, Spin, message } from 'antd';
import { terminalManagenmentService } from '../../services';

class TerminalDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            body: {},
            loading: false
        }
    }
    async componentDidMount() {
        await this.loadTerminal();
    }
    //加载终端机信息
    loadTerminal() {
        try {
            this.setState({
                loading: true
            })
            terminalManagenmentService.terminal(this.props.record.terminalId)
                .then(response => {
                    var body = response.data;
                    if (!body.success) {
                        message.error(body.message)
                    }
                    this.setState({
                        body: body.data,
                        loading: false
                    })
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    renderKey(key) {
        if (key === 'terminalId') {
            return '设备编号'
        }
        if (key === 'name') {
            return '设备名'
        }
        if (key === 'status') {
            return '状态'
        }
        if (key === 'putOnDate') {
            return '部署时间'
        }
        if (key === 'remark') {
            return '备注'
        }
        if (key === 'gpsLng') {
            return '经度'
        }
        if (key === 'gpsLag') {
            return '纬度'
        }
        if (key === 'secret') {
            return '终端授权密钥'
        }

        return key;
    }
    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 255:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        return value;
    }
    render() {
        const { body, loading } = this.state;
        return (
            <Spin tip="加载中..." spinning={loading}>
                {
                    body ? <Descriptions bordered border >
                        {
                            Object.keys(body).filter(key => {
                                //filter过滤数组中元素
                                if (key === 'uid') return false;
                                if (key === 'putOnDate') return false;
                                if (key === 'gpsLng') return false;
                                if (key === 'gpsLag') return false;
                                if (key === 'driverbusiness') return false;
                                if (key === 'Marketing') return false;
                                return true;
                            }).map(key =>
                                <Descriptions.Item
                                    key={key}
                                    label={this.renderKey(key)}>
                                    {this.renderValue(key, body[key])}
                                </Descriptions.Item>
                            )
                        }
                    </Descriptions> : null
                }
            </Spin>)
    }
}
export { TerminalDetails }