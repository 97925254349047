import React from 'react';
import {
    Table,
    Input,
    Tag,
    Spin,
    Button,
    Modal,
    Form,
    message,
    Drawer,
    Select
} from 'antd';
import { Link } from 'react-router-dom';
import { terminalManagenmentService } from '../../services';
import { TerminalManagementModule } from './TerminalManagementModule'
import { connect } from 'react-redux';

class TerminalManagementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loading: true,
            visibleDrawer: false,
            statusValue: 0,
            visibleModal: false,
        };
    }

    showDrawer = terminal => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: terminal
        });
    }
    onCloseDrawer = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    handlePaging(keyword, statusValue, page, size) {
        try {
            this.setState({
                ...this.state,
                loading: true
            })
            const { form } = this.props;
            form.validateFieldsAndScroll((err, values) => {
                const { statusValue } = values;
                terminalManagenmentService.terminals(keyword, statusValue, page - 1, size)
                    .then(response => {
                        if (response) {
                            var result = response.data;
                            var list = result.data;
                            list.map((item, index) => item['key'] = index);
                            this.setState({
                                page: page,
                                keyword: keyword,
                                pagesize: size,
                                statusValue: statusValue,
                                list: list,
                                totalCount: result.count,
                                loading: false
                            })
                        }
                    });
            }
            )
        }
        catch (ex) {
            alert(ex)
        }
    }

    //调取terminalManagenmentService terminals()方法，即调取所有设备信息
    componentDidMount() {
        const { pagesize, keyword, statusValue } = this.state;
        this.handlePaging(keyword, statusValue, 1, pagesize);
        this.setState({
            loading: true
        })
    }

    //清空zredux状态
    StatusClear() {
        const { dispatch } = this.props;
        dispatch({
            type: 'RESET'
        });
    }
    //显示Modal事件
    showModal = key => {
        this.setState({
            visibleModal: true,
            displayKey: key
        });
    };

    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { loading } = this.state;
        const { Search } = Input;
        const columns = [
            {
                title: '设备编号',
                dataIndex: 'terminalId',
                key: 'terminalId',
            },
            {
                title: '设备名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '终端授权秘钥',
                dataIndex: 'secret',
                key: 'secret',
                render: (text, record) => {
                    return <>
                        <a onClick={() => this.showModal(text)}>查看秘钥</a>
                        <Modal
                            visible={this.state.visibleModal}
                            onCancel={this.handleCancel}
                            footer={null}
                            mask={false}
                            destroyOnClose={true}
                        >
                            终端授权密钥: {this.state.displayKey}
                        </Modal>
                    </>
                }
            },
            {
                title: '投放地点坐标',
                dataIndex: 'gpsLng',
                render: (text, record, index) => `${record.gpsLng != null ? record.gpsLng : ""}${record.gpsLag != null || record.gpsLng != null ? "," : " "}${record.gpsLag != null ? record.gpsLag : ""}`
            },
            {
                title: '是否加入营销',
                dataIndex: 'Marketing',
                key: 'Marketing',
                render: (text, record) => {
                    if (record.Marketing === true) {
                        return (
                            <Tag color="red" key={record.Marketing}>
                                已加入营销
                         </Tag>
                        )
                    }
                    if (record.Marketing === false) {
                        return (
                            <Tag color="gray" key={record.Marketing}>
                                未加入营销
                       </Tag>
                        )
                    }     
                    }
                
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                         </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                       </Tag>
                        )
                    }
                    if (record.status === 255) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
                     </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                )
            }
        ];
        return (

            <Spin tip='加载中' spinning={loading}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('statusValue', {
                            initialValue: 'all',
                            rules: [
                                {
                                    required: false,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select placeholder="请选择"
                                style={{ width: 120 }}
                            >
                                <Option value='all'>全部</Option>
                                <Option value={1}>启用</Option>
                                <Option value={0}>禁用</Option>
                                <Option value={255}>删除</Option>
                            </Select>)}
                    </Form.Item>
                </Form>
                <Search placeholder="请输入设备名或设备编号"
                    onSearch={value => this.handlePaging(
                        value, this.state.statusValue,
                        this.state.page,
                        this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Link to={`/app/terminal-seetings`} >
                    <Button
                        type="primary"
                        onClick={() => this.StatusClear()}
                        style={{ float: "right" }}
                    >添加设备
                </Button>
                </Link>
                <Table columns={columns} dataSource={this.state.list}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pageSize, page) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pageSize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(
                                this.state.keyword,
                                this.state.statusValue,
                                page,
                                pageSize);
                        }
                    }}
                />
                <Drawer
                    title="终端机配置"
                    placement={"top"}
                    onClose={this.onCloseDrawer}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}
                >
                    <TerminalManagementModule
                        terminal={this.state.selected}
                        handlePaging={this.handlePaging.bind(this)}
                        onCloseDrawer={this.onCloseDrawer.bind(this)}
                    />
                </Drawer>
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.terminalSetting
    }
}

const TerminalManagementPageForm = Form.create()(TerminalManagementPage);
const TerminalManagementPageConnect = connect(mapStateToProps)(TerminalManagementPageForm)
export { TerminalManagementPageConnect as TerminalManagementPage }