import axios from 'axios';
import { async } from 'q';

const hosts = {
    orders: "/api/trade/orders",
    order: "/api/trade/orders",
    refund: "/api/payment/refund",
    cancelSettlement: "/api/trade/cancelSettlement"
}

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}

export const orderService = {
    orders,
    order,
    refund,
    cancelSettlement
}
//订单
async function orders(search, pageindex, pagesize) {
    const response = await myget(hosts.orders, {
        params: {
            search: search,
            pageindex: pageindex,
            pagesize: pagesize
        }
    })
    return response;
}
//获取详细订单信息
async function order(id) {
    const url = `${hosts.order}/${id}`;
    const response = await myget(url)
    return response;
}
//退款
async function refund(id, refundFee, pwd, remark, isFold) {
    try {
        const response = await mypost(hosts.refund, {
            id: id,
            refundFee: refundFee,
            pwd: pwd,
            remark: remark,
            isFold: isFold
        })
        return response;
    }
    catch (error) {
        throw error.message;
    }
}

//取消一完成订单状态，并删除老系统订单信息
async function cancelSettlement(orderId, password) {
    const response = await mypost(hosts.cancelSettlement, {
        orderId: orderId,
        password: password
    })
    return response;
}