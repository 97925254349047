import React from 'react';
import { Table, Spin, Button, Tooltip, Modal, Form, message,Drawer,Radio,Divider  } from 'antd';
import { marketingService } from '../../services';
import { connect } from 'react-redux';
import { NoDetailedConfig,NoLadderConfiguration } from '../MarketingActionPage';

class NoBusiness extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            visibleModal: false,
            submiting: false,
            loading: false,
            visibleDrawer:false,
            disp:true
        }
    }
    showModal = (e) => {
        //获取点击按钮的id
        let id=e.id;
        this.setState({
            visibleModal: true,
            id: id
        });
        const { resetFields } = this.props.form;
        resetFields();
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };

    //显示Drawer事件
    showDrawer = (e) => {
        //获取点击按钮的id
        // let id = e.currentTarget.getAttribute('data-id')
        let id=e.id;
        this.setState({
            visibleDrawer: true,
            id: id
        });
        const { resetFields } = this.props.form;
        resetFields();
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    async componentDidMount() {
        await this.business();
    }
    async business() {
        try {
            this.setState({
                loading: true
            })
            await marketingService.business(this.props.record.terminalId)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let list = result.data;
                        list.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            this.setState({
                                loading: false
                            })
                        }
                        this.setState({
                            data: list,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //提交分成信息
    async sumitMarketing() {
        this.setState({
            submiting: true
        })
        const {  noConfig } = this.props;
        let marketingUserId = this.props.marketingUser.id;
        let terminalId = this.props.record.terminalId;
        
        

        await marketingService
            .createMarketing(
                marketingUserId,
                terminalId,
                noConfig
            )
            .then(response => {
                if (response) {
                    let result = response.data;
                    if (!result.success) {
                        message.error(result.message)
                        this.setState({
                            submiting: false
                        })
                    }
                    else {
                        message.success("保存成功")
                        this.setState({
                            submiting: false
                        })
                        this.props.onClose();
                        this.props.handlePaging(null, 1, 5)
                    }
                }
            })
    }
    //修改分成信息
    async changeMarketing() {
        this.setState({
            submiting: true
        })
        const { ifParticipationSplit, nodetailedConfig } = this.props;
        let marketingUserId = this.props.marketingUser.id;
        let terminalId = this.props.record.terminalId;
        await marketingService
            .update(
                marketingUserId,
                terminalId,
                ifParticipationSplit,
                nodetailedConfig
            )
            .then(response => {
                if (response) {
                    let result = response.data;
                    if (!result.success) {
                        message.error(result.message)
                        this.setState({
                            submiting: false
                        })
                    }
                    else {
                        message.success("保存成功")
                        this.setState({
                            submiting: false
                        })
                        this.props.onClose();
                        this.props.handlePaging(null, 1, 5)
                    }
                }
            })
    }
    onClicka(){
        this.setState({
            disp:true
        })
    }
    onClickb(){
        this.setState({
            disp:false
        })
    }

    render() {
        const { data, submiting, loading } = this.state;
        const columns = [
            {
                title: '业务名',
                dataIndex: 'businessname',
                key: 'businessname',
             
              
            },
            {
                title: '单价',
                dataIndex: 'price',
                key: 'price',
              
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, e) => (
                    <span>
                        {/* <a onClick={() => this.showModal(e)}>配置业务</a>&nbsp;&nbsp; */}
                        <a onClick={() => this.showDrawer(e)}>配置业务</a>
                    </span>
                ),
            },
        ];
        const {dataSource}=this.props;
        let value=dataSource.length===0&&dataSource!==null?"a":"b"
        return (
            <Spin tip={"加载中..."} spinning={loading}>
                <Spin tip={"提交数据中,请稍后..."} spinning={submiting}>

                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    style={{ marginBottom: 10 }}
                    defaultExpandAllRows={true}
                    bordered
                />
                    <Button
                        type="primary"
                        style={{ marginTop:10,float:"right"}}
                        onClick={() => this.sumitMarketing()}>提交数据</Button>
                      <Modal
                        title="配置价格"
                        visible={this.state.visibleModal}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <NoDetailedConfig
                            record={this.props.record}
                            id={this.state.id}
                            onClose={this.onClose.bind(this)} />
                       </Modal>

                       <Drawer
                        title="阶梯配置"
                        width={1024}
                        onClose={this.onClose}
                        visible={this.state.visibleDrawer}
                        destroyOnClose={true}
                    >
                        <>
                            <Radio.Group defaultValue={"a"}>
                                <Radio.Button onClick={() => this.onClicka()} value="a">分成配置</Radio.Button>
                                <Radio.Button onClick={() => this.onClickb()} value="b">阶梯范围分成</Radio.Button>
                            </Radio.Group>
                            {this.state.disp === true ? <>
                                <Divider orientation="left"><b>该终端所属业务配置</b></Divider>
                                <NoDetailedConfig
                                    record={this.props.record}
                                    id={this.state.id}
                                    onClose={this.onClose.bind(this)} /></> :
                                <>
                                    <Divider orientation="left"><b>该终端阶梯范围所属业务配置</b></Divider>
                                    <NoLadderConfiguration
                                        record={this.props.record}
                                        id={this.state.id}
                                        onClose={this.onClose.bind(this)} />
                                </>
                            }
                        </>
                    </Drawer>

                </Spin>
            </Spin>)
    }
}

const mapStateToProps = state => {
    return { ...state.setparameters };
}
const NoBusinessPage = connect(mapStateToProps)(NoBusiness)
const NoBusinessPageForm = Form.create()(NoBusinessPage)
export { NoBusinessPageForm as NoBusiness }