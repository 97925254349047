import React from 'react';
import {
    Button,
    Table,
    DatePicker,
    Form,
    message,
    Spin,
    Input,
    Icon
} from 'antd';
import { reportService } from '../../services'
import moment from 'moment';

import { summarizers } from 'istanbul-lib-report';

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
};

class TerminalTurnoverReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportlist: [],
            loading: false,
            start: new moment().clone().set({
                hour: 0, minute: 0, second: 0, millisecond: 0
            }),
            end: new moment().clone().set({
                hour: 23, minute: 59, second: 59, millisecond: 999
            }),
            searchText: ''
        }
    }

    async loadReport(start_time, end_time) {
        try {
            try {
                await reportService
                    .report(
                        start_time.format("YYYY-MM-DD HH:mm:ss"),
                        end_time.format("YYYY-MM-DD HH:mm:ss"))
                    .then(response => {
                        if (response) {
                            let result = response.data;
                            let reportlist = result.data;
                            reportlist.map((item, index) => {
                                item['key'] = index;
                                if (item.children) {
                                    item.children.map((cItem, cIndex) =>
                                        cItem['key'] = `${item.name}_${cItem.name}`)
                                }
                            });
                            this.setState({
                                reportlist: reportlist,
                                loading: false
                            })
                        }
                    })
            }
            catch (ex) {
                message.error('请选择需要查询的日期')
                this.setState({
                    loading: false
                })
            }
        }
        catch (ex) {
            message.error('加载失败，请检查网络')
            this.setState({
                loading: false
            })
        }
    }
    report = (e) => {
        e.preventDefault();
        const { form } = this.props;
        this.setState({
            loading: true
        })
        form.validateFields((err, values) => {
            if (!err) {
                try {
                    const { start_time, end_time } = values;
                    this.loadReport(start_time, end_time);
                }
                catch (ex) {
                    alert(ex)
                }
            }
        })
    }

    async componentDidMount() {
        const { start, end } = this.state;
        await this.loadReport(start, end);
    }

    //列搜索
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={'搜索终端'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    重置
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const { loading } = this.state;
        const { getFieldDecorator } = this.props.form
        const { reportlist } = this.state;
        const columns = [
            {
                title: '项目名称',
                dataIndex: 'name',
                key: 'name',
                width: "300",
                ...this.getColumnSearchProps('name')
            },
            {
                title: '订单成交量',
                dataIndex: 'count',
                key: 'count',
                width: "300",
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.count - b.count,
            },
            {
                title: '订单总金额',
                dataIndex: 'subtotal',
                key: 'subtotal',
                width: "300",
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.subtotal - b.subtotal
            },
        ];
        return (
            <Spin tip="加载中..." spinning={loading}>
                <Form layout="inline" onSubmit={e => this.report(e)} >
                    <Form.Item>
                        {getFieldDecorator('start_time', {
                            initialValue: this.state.start,
                            'rules': [{ type: 'object', required: false }]
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="开始时间"
                            />)}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('end_time', {
                            initialValue: this.state.end,
                            'rules': [{ type: 'object', required: false }]
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="结束时间"
                            />)}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            key='confirm'
                            htmlType="submit"
                            type="primary" >
                            查询
              </Button>
                    </Form.Item>
                </Form>
                <Table
                    columns={columns}
                    dataSource={reportlist}
                    pagination={false}
                    style={{ marginBottom: 10 }}
                    defaultExpandAllRows={true}
                    bordered
                // rowSelection={rowSelection}
                // footer={()=>`${'总金额:'}${1}`}
                />
            </Spin>
        )
    }
}
const ReportPageForm = Form.create()(TerminalTurnoverReport)
export { ReportPageForm as TerminalTurnoverReport }