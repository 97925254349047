import React from 'react';
import {
    Select,
    Spin,
    message,
    Table,
    AutoComplete,
    Drawer,
    Input,
    Divider
} from 'antd';
import { marketingService } from '../../services';
import { TerminalDetails } from '../MarketingUserPage';
import { NoBusiness } from '../MarketingActionPage';
import { connect } from 'react-redux';

class NoDistributionAccountModule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fetching: false,
            pageindex: 1,
            pagesize: 5,
            value: null,
            totalCount: 0,
            visibleDrawer: false
        }
    }
    componentDidMount() {
        const { value } = this.state;
        this.terminalsinfo(value);
    }
    //显示Drawer事件
    showDrawer = record => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: record,
        })
        const { dispatch } = this.props;
        dispatch({
            type: 'RESET'
        });
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    handlePaging = (value, pageindex, pagesize) => {
        try {
            marketingService.marketingterminal(this.props.marketingUser.id, value, pageindex - 1, pagesize)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            message.error(result.message)
                        }
                        this.setState({
                            data: list,
                            value: value,
                            pageindex: pageindex,
                            pagesize: pagesize,
                            totalCount: result.count,
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    };

    terminalsinfo = async (value) => {
        try {
            await marketingService.terminalsinfo(this.props.marketingUser.id, value)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let terminalslist = result.data;
                        terminalslist.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            message.error(result.message)
                        }
                        this.setState({
                            terminalslist: terminalslist,
                            value: value,
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    completesearch() {
        const text = this.state.data
            .filter(terminal => terminal.terminalname != null)
            .map(terminal => terminal.terminalname)
        return text;
    }
    render() {
        const { Search } = Input;
        const columns = [
            {
                title: '设备编码',
                dataIndex: 'terminalId',
                key: 'terminalId',
                width: "30%"
            },
            {
                title: '设备名称',
                dataIndex: 'terminalname',
                key: 'terminalname',
                width: "30%"
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: 300,
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>配置</a>
                    </span>
                ),
            },
        ];
        return (
            <>
                <AutoComplete
                    dataSource={this.completesearch()}
                    style={{ width: 330, marginBottom: 15 }}
                    enterButton
                    onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                    placeholder="请输入设备名称"
                >
                    <Search placeholder="请输入设备名称"
                        onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                        enterButton style={{ width: 330 }}
                    />
                </AutoComplete>
                <Drawer
                    title="设备详情"
                    width={"90%"}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <TerminalDetails record={this.state.selected} />
                    <Divider orientation="left"><b>该终端所属业务</b></Divider>
                    <NoBusiness
                        record={this.state.selected}
                        marketingUser={this.props.marketingUser}
                        onClose={this.onClose.bind(this)}
                        handlePaging={this.handlePaging.bind(this)}
                    />
                </Drawer>
                <Table columns={columns} dataSource={this.state.data}
                    size="middle"
                    pagination={{
                        pageSize: this.state.pagesize,
                        current: this.state.pageindex,
                        total: this.state.totalCount,
                        onChange: (pageindex, pageSize) => {
                            this.handlePaging(this.state.value, pageindex, pageSize);
                        }
                    }
                    }
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    return { ...state.setparameters };
}

const NoDistributionAccountModuleConnect = connect(mapStateToProps)(NoDistributionAccountModule)
export { NoDistributionAccountModuleConnect as NoDistributionAccountModule }