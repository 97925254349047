//默认状态
const defaultStatus = 'process';
//初始状态
const initialState = () => {
    return ({
        last: 0,
        current: 0,
        posting: false,
        status: defaultStatus,
        deviceInformation: {},
        phototype: {},
        price: {},
        config: {}
    });
};

const terminalSettings = (state = initialState(), action) => {
    if (action.type === 'PREVIOUS') {
        return {
            ...state,
            current: state.last,
            last: --state.last,
            status: defaultStatus,
        }
    }
    //提交设备信息
    if (action.type === 'SUBMIT_DEVICE_INFORMATION') {
        let deviceInformation = action.deviceInformation;
        return {
            ...state,
            deviceInformation: deviceInformation,
            last: state.current,
            current: action.current
        }
    }
    //提交设备信息
    if (action.type === 'SUBMIT_CONFIG') {
        let config = action.config;

        return {
            ...state,
            config: config,
            current: state.current + 1,
            last: state.current
        };
    }
    //提交设别照片类型
    if (action.type === 'SUBMIT_PHOTOTYPE') {
        let phototype = action.phototype;
        return {
            ...state,
            phototype: phototype,
            current: state.current + 1,
            last: state.current,
        }
    }

    //修改指定价目表
    if (action.type === 'SUBMIT_PRICELIST') {
        let price = action.price;
        return {
            ...state,
            price: price,
            current: state.current + 1,
            last: state.current
        }
    }
    //提交表单
    if (action.type === 'SUBMIT_FORM') {
        return {
            ...state,
            posting: true,
        };
    }
    if (action.type === 'RESET') {
        return {
            ...state,
        };
    }
    //提交失败
    if (action.type === 'SUBMIT_FINISH') {
        let status = 'finish'
        let current = state.current + 1;
        let posting = true;
        if (action.error) {
            status = 'error';
            current -= 1;
            posting = false;
        }
        return {
            ...state,
            posting: posting,
            current: current,
            status: status
        }
    }
    return state;
}

const terminalSettingsRoot = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined;
    }

    return terminalSettings(state, action);
}

export default terminalSettingsRoot