import React from 'react';
import {
    Table,
    Input,
    Tag,
    Spin,
    Button,
    Modal,
    Form,
    message,
    Drawer,
    Select
} from 'antd';
import { terminalManagenmentService } from '../../services';

class obtainDeletedTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loading: true,
            statusValue: 0,
            visibleModal: false,
            deleteTerminalVisible: false,
            selectedRowKeys: [],
            statusValue: 255,
            deleteing: false,
            undoDeleteTerminalVisible: false,
            undodeleteing: false
        };
    }
    handlePaging(keyword, statusValue, page, size) {
        try {
            this.setState({
                ...this.state,
                loading: true
            })
            terminalManagenmentService.terminals(keyword, statusValue, page - 1, size)
                .then(response => {
                    var result = response.data;
                    var list = result.data;
                    list.map((item, index) => item['key'] = index);
                    this.setState({
                        page: page,
                        keyword: keyword,
                        pagesize: size,
                        list: list,
                        totalCount: result.count,
                        loading: false
                    })
                });
        }
        catch (ex) {
            alert(ex)
        }
    }
    //调取terminalManagenmentService terminals()方法，即调取所有设备信息
    componentDidMount() {
        const { pagesize, keyword, statusValue, page } = this.state;
        this.handlePaging(keyword, statusValue, page, pagesize);
        this.setState({
            loading: true
        })
    }
    //显示删除Modal事件
    showDeleteTerminalModal = id => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            deleteTerminalVisible: true,
            terminalId: id
        })
    }
    //关闭删除model事件
    handleDeleteTerminalCancel = () => {
        this.setState({
            deleteTerminalVisible: false
        })
    }
    //显示撤销删除Modal事件
    showUndoDeleteTerminalModal = id => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            undoDeleteTerminalVisible: true,
            terminalId: id
        })
    }
    //关闭撤销删除model事件
    handleUndoDeleteTerminalCancel = () => {
        this.setState({
            undoDeleteTerminalVisible: false
        })
    }
    //显示Modal事件
    showModal = key => {
        this.setState({
            visibleModal: true,
            displayKey: key
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //批量删除磁盘用户信息
    onClickDeletedTerminal = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { password } = values;
                try {
                    this.setState({
                        deleteing: true
                    })
                    await terminalManagenmentService.erase(password, this.state.selectedRowKeys)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        deleteing: false
                                    })
                                }
                                else {
                                    message.success('删除成功')
                                    this.handleDeleteTerminalCancel();
                                    const { statusValue, page, pagesize, keyword } = this.state;
                                    this.handlePaging(keyword, statusValue, page, pagesize)
                                    this.setState({
                                        selectedRowKeys: [],
                                        deleteing: false
                                    })
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        });
    }
    //撤销删除
    onClickUndoDelete = () => {
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    undodeleteing: true
                })
                const { statusValue, password } = values;
                terminalManagenmentService.undodelete(this.state.selectedRowKeys, statusValue, password)
                    .then(response => {
                        if (response) {
                            let result = response.data;
                            if (!result.success) {
                                message.error(result.message)
                                this.setState({
                                    undodeleteing: false
                                })
                            }
                            else {
                                message.success("撤销成功")
                                this.handleUndoDeleteTerminalCancel();
                                const { statusValue, page, pagesize, keyword } = this.state;
                                this.handlePaging(keyword, statusValue, page, pagesize)
                                this.setState({
                                    undodeleteing: false
                                })
                            }
                        }
                    })
            }
        });
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    render() {
        const { loading, undodeleteing, deleteing, selectedRowKeys } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { Search } = Input;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const columns = [
            {
                title: '设备编号',
                dataIndex: 'terminalId',
                key: 'terminalId',
            },
            {
                title: '设备名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '终端授权秘钥',
                dataIndex: 'secret',
                key: 'secret',
                render: (text, record) => {
                    return <>
                        <a onClick={() => this.showModal(text)}>查看秘钥</a>
                        <Modal
                            visible={this.state.visibleModal}
                            onCancel={this.handleCancel}
                            footer={null}
                            mask={false}
                            destroyOnClose={true}
                        >
                            终端授权密钥: {this.state.displayKey}
                        </Modal>
                    </>
                }
            },
            {
                title: '投放地点坐标',
                dataIndex: 'gpsLng',
                render: (text, record, index) => `${record.gpsLng},${record.gpsLag}`
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                         </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                       </Tag>
                        )
                    }
                    if (record.status === 255) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
                     </Tag>
                        )
                    }
                }
            },

        ];
        return (

            <Spin tip='加载中' spinning={loading}>

                <Search placeholder="请输入设备名或设备编号"
                    onSearch={value => this.handlePaging(
                        value, this.state.statusValue,
                        this.state.page,
                        this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <div>
                    <Button
                        type="danger"
                        style={{ float: "right", marginBottom: 16, marginLeft: 10 }}
                        onClick={() => this.showDeleteTerminalModal()}
                        disabled={!hasSelected}

                    >删除设备
                </Button>
                    <Button
                        type="primary"
                        style={{ float: "left", marginBottom: 16 }}
                        onClick={() => this.showUndoDeleteTerminalModal()}
                        disabled={!hasSelected}
                    >撤销删除
                </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `选中 ${selectedRowKeys.length} 个` : ''}
                    </span>
                </div>
                <Table
                    columns={columns}
                    dataSource={this.state.list}
                    rowSelection={rowSelection}
                    rowKey={record => record.uid}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pageSize, page) => {
                            this.handlePaging(this.state.keyword, this.state.statuserValue, page, pageSize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(
                                this.state.keyword,
                                this.state.statusValue,
                                page,
                                pageSize);
                        }
                    }}
                />

                <Modal
                    title='删除设备'
                    visible={this.state.deleteTerminalVisible}
                    onCancel={() => this.handleDeleteTerminalCancel()}
                    footer={null}
                > <Spin tip='删除中' spinning={deleteing}>
                        <Form onSubmit={e => this.onClickDeletedTerminal(e)}>
                            <Form.Item>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入当前管理员密码'
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='请输入当前管理员密码' />
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ float: "right" }}
                                >
                                    确认
                      </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title='撤销删除设备'
                    visible={this.state.undoDeleteTerminalVisible}
                    onCancel={() => this.handleUndoDeleteTerminalCancel()}
                    footer={null}
                >
                    <Spin tip="撤销删除中..." spinning={undodeleteing}>
                        <Form>
                            <Form.Item>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入当前管理员密码'
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='请输入当前管理员密码' />
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('statusValue', {
                                    initialValue: 1,
                                    rules: [
                                        {
                                            required: false,
                                            message: '请选择状态',
                                        },
                                    ],
                                })(
                                    <Select placeholder="请选择">
                                        <Option value={1}>启用</Option>
                                        <Option value={0}>禁用</Option>
                                    </Select>)}
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ float: "right" }}
                                    onClick={() => this.onClickUndoDelete()}
                                >
                                    确认
                      </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Spin>

        )
    }
}

const TerminalManagementPageForm = Form.create()(obtainDeletedTerminal);
export { TerminalManagementPageForm as obtainDeletedTerminal }