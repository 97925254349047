import React from 'react';
import { Button, Table, Input, Tag, Form, Select, Modal, message, Drawer, Spin } from 'antd';
import { menuService } from '../../services';
import { MenuModule } from '../MenusPage'

class Menus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menunodelist: [],
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loggingIn: true,
            visible: false,//对话框是否可见
            visibleModal: false,
            visibleDrawer: false,
            statusValue: 0,
            creating: false
        };
    }

    //显示Modal事件
    showModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
        });
    };
    //Modal关闭事件
    handleOk = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
            creating: false
        });
    };

    //Drawer关闭事件
    hanleCancel = () => {
        this.setState({
            visibleDrawer: false,
        });
    };
    //显示Drawer事件
    showDrawer = menu => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: menu,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }
    //查询菜单信息，list分页，搜索功能
    handlePaging(keyword, statusValue, page, size) {
        this.setState({
            ...this.state,
            loggingIn: true
        })
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const { statusValue } = values;
            menuService.allMenus(keyword, statusValue, page - 1, size)
                .then(response => {
                    if (response) {
                        var result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        this.setState({
                            page: page,
                            keyword: keyword,
                            pagesize: size,
                            list: list,
                            totalCount: result.count,
                            loggingIn: false,
                            statusValue: statusValue
                        })
                    }
                });
        });
    }

    //调取menus接口加载菜单list页面
    componentDidMount() {
        const { pagesize, page, keyword, statusValue } = this.state;
        this.handlePaging(keyword, statusValue, page, pagesize);
        this.getmenunode();
        this.setState({
            loggingIn: true
        })
    }
    //调用创建菜单方法
    creatMenusSubmt = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    this.setState({
                        creating: true
                    })
                    const { icon, menuName, url, parentId, status } = values;
                    try {
                        await menuService
                            .createMenu(icon, menuName, url, parentId, status)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    if (!result.success) {
                                        message.error(result.message);
                                        this.setState({
                                            creating: false
                                        })
                                    }
                                    else {
                                        message.success('添加成功');
                                        this.setState({
                                            creating: false,
                                            visibleModal: false
                                        })
                                    }
                                }
                            })
                    }
                    catch (ex) {
                        message.error('保存失败', 5);
                    }
                }
                catch (ex) {
                    alert(ex);
                }
            }
        }
        )
    }

    //调取获取根节点方法
    getmenunode() {
        try {
            menuService.rootMenus().then(
                response => {
                    if (response) {
                        var result = response.data;
                        this.setState({
                            menunodelist: result.data
                        })
                    }
                }
            );
        }
        catch (ex) {
            alert(ex);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { Search } = Input;
        const { menunodelist } = this.state;
        const { creating } = this.state
        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const { loggingIn } = this.state;
        const { visible } = this.state;

        const columns = [
            {
                title: '图标',
                dataIndex: 'icon',
                key: 'icon',
                width: 200,
            },
            {
                title: '菜单名',
                dataIndex: 'name',
                key: 'name',
                width: 200,
            },
            {
                title: '菜单url',
                dataIndex: 'url',
                key: 'url',
                width: 200,
            },
            {
                title: '父级名称',
                dataIndex: 'parentName',
                key: 'parentName',
                width: 200,
            },
            {
                title: '菜单状态',
                dataIndex: 'status',
                key: 'status',
                width: 200,
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                            </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                          </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
                        </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: 400,
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },

        ]
        return (
            <Spin tip="加载中" spinning={loggingIn}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('statusValue', {
                            initialValue: 'all',
                            rules: [

                                {
                                    required: false,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select
                                placeholder="请选择"
                                style={{ width: 120 }}
                            >
                                <Option value="all">全部</Option>
                                <Option value={1}>启用</Option>
                                <Option value={0}>禁用</Option>
                                <Option value={2}>删除</Option>
                            </Select>)}
                    </Form.Item>
                </Form>
                <Search placeholder="请输入菜单名"
                    onSearch={
                        value => this.handlePaging(value, this.state.statusValue, this.state.page, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Button type="primary"
                    style={{ float: "right" }}
                    onClick={this.showModal}>添加菜单</Button>
                <Table
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pageSize, page) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pageSize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pageSize);
                        }
                    }}
                />
                <Drawer
                    title="用户详情"
                    width={1024}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <MenuModule menu={this.state.selected}
                        handlePaging={this.handlePaging.bind(this)}
                        onClose={this.onClose.bind(this)}
                        menunodelist={this.state.menunodelist} />
                </Drawer>

                <Modal
                    title="添加菜单项"
                    visible={this.state.visibleModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}>
                    <Spin tip="加载中" spinning={creating}>
                        <Form   {...formItemLayout}
                            onSubmit={this.creatMenusSubmt}>
                            <Form.Item label="图标名">
                                {getFieldDecorator('icon', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入图标名',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="菜单名">
                                {getFieldDecorator('menuName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入菜单名',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="状态">
                                {getFieldDecorator('status', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择状态',
                                        },
                                    ],
                                })(
                                    <Select placeholder="请选择状态">
                                        <Option value="1">启用</Option>
                                        <Option value="0">禁用</Option>
                                    </Select>)}
                            </Form.Item>
                            <Form.Item label="Url地址">
                                {getFieldDecorator('url', {
                                    rules: [
                                        {
                                            required: false,
                                            message: '请输入Url地址',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="父级名">
                                {getFieldDecorator('parentId', {
                                    rules: [
                                        {
                                            required: false,
                                            message: '请选择父级菜单名',
                                        },
                                    ],
                                })(<Select placeholder="请选择父级节点">
                                    <Option value={null} >系统根目录</Option>
                                    {
                                        menunodelist.map((item, index) => (
                                            <Option value={item.id}
                                                key={item.id}
                                            >
                                                {item.menuName}
                                            </Option>
                                        ))
                                    }
                                </Select>)}
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 17, offset: 5 }}>
                                <Button
                                    style={{ float: "right" }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确认添加
                            </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>

            </Spin>
        )
    }
}
const MenusPage = Form.create()(Menus);
export { MenusPage as MenusPage }