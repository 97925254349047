import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authService } from '../../services'

export const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={props => (

        sessionStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />

)