import {
    Table,
    Tag,
    Spin,
    Input,
    Button,
    Form,
    message,
    Modal,
    Select
} from 'antd';
import React from 'react';
import { roleService } from '../../services';

class obtainDeletedRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loggingIn: true,
            statusValue: 2,
            deleteRoleVisible: false,
            selectedRowKeys: [],
            deleteing: false,
            undoDeleteRoleVisible: false,
            undodeleteing: false
        }
    }
    //查询用户信息，list分页，搜索功能
    handlePaging(keyword, statusValue, page, size) {
        this.setState({
            ...this.state,
            loggingIn: true
        })

        roleService.roles(keyword, statusValue, page - 1, size)
            .then(response => {
                if (response) {
                    var result = response.data;
                    var list = result.data;
                    list.map((item, index) => item['key'] = index);
                    this.setState({
                        page: page,
                        keyword: keyword,
                        pagesize: size,
                        list: list,
                        totalCount: result.count,
                        loggingIn: false,
                    })
                }
            })
    }

    //调取role.servicers allroles()方法，即调取所有用户信息
    componentDidMount() {
        const { statusValue, page, pagesize, keyword } = this.state;
        this.handlePaging(keyword, statusValue, page, pagesize);
        this.setState({
            loggingIn: true
        })
    }
    //显示删除Modal事件
    showDeleteRoleModal = id => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            deleteRoleVisible: true,
            roleid: id
        })
    }
    //关闭删除model事件
    handleDeleteRoleCancel = () => {
        this.setState({
            deleteRoleVisible: false
        })
    }

    //显示撤销删除Modal事件
    showUndoDeleteRoleModal = id => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            undoDeleteRoleVisible: true,
            roleid: id
        })
    }
    //关闭撤销删除model事件
    handleUndoDeleteRoleCancel = () => {
        this.setState({
            undoDeleteRoleVisible: false
        })
    }
    //批量删除磁盘用户信息
    onClickDeletedRoles = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { password } = values;
                try {
                    this.setState({
                        deleteing: true
                    })
                    await roleService.erase(password, this.state.selectedRowKeys)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        deleteing: false
                                    })
                                }
                                else {
                                    message.success('删除成功')
                                    this.handleDeleteRoleCancel();
                                    const { statusValue, page, pagesize, keyword } = this.state;
                                    this.handlePaging(keyword, statusValue, page, pagesize)
                                    this.setState({
                                        selectedRowKeys: [],
                                        deleteing: false
                                    })
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        });
    }
    //撤销删除
    onClickUndoDelete = () => {
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    undodeleteing: true
                })
                const { statusValue, password } = values;
                roleService.undodelete(this.state.selectedRowKeys, statusValue, password)
                    .then(response => {
                        if (response) {
                            let result = response.data;
                            if (!result.success) {
                                message.error(result.message)
                                this.setState({
                                    undodeleteing: false
                                })
                            }
                            else {
                                message.success("撤销成功")
                                this.handleUndoDeleteRoleCancel();
                                const { statusValue, page, pagesize, keyword } = this.state;
                                this.handlePaging(keyword, statusValue, page, pagesize)
                                this.setState({
                                    undodeleteing: false
                                })
                            }
                        }
                    })
            }
        });
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    render() {
        const { loggingIn, selectedRowKeys, deleteing, undodeleteing } = this.state;
        const { Search } = Input;
        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const columns = [
            {
                title: '角色名',
                dataIndex: 'roleName',
                key: 'roleName',
                width: 400
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',

                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                    </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                  </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
                </Tag>
                        )
                    }
                }
            },
        ];
        return (
            <Spin tip="加载中..." spinning={loggingIn}>
                <Search placeholder="请输入角色名"
                    onSearch={value => this.handlePaging(value, 2, this.state.page, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <div>
                    <Button
                        type="danger"
                        style={{ float: "right", marginBottom: 16, marginLeft: 10 }}
                        onClick={() => this.showDeleteRoleModal()}
                        disabled={!hasSelected}
                    >删除角色
                </Button>
                    <Button
                        type="primary"
                        style={{ float: "left", marginBottom: 16 }}
                        onClick={() => this.showUndoDeleteRoleModal()}
                        disabled={!hasSelected}
                    >撤销删除
                </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `选中 ${selectedRowKeys.length} 个` : ''}
                    </span>
                </div>
                <Table columns={columns} dataSource={this.state.list}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    pagination={{
                        showSizeChanger: true,//是否可以改变pagesize
                        onShowSizeChange: (current, pagesize, page) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pagesize);
                        },//pagesize 变化的回调
                        pageSizeOptions: ['10', '25', '50', '100', '200'],//指定每页可以显示多少条
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pageSize);
                        }
                    }}
                />
                <Modal
                    title='删除角色'
                    visible={this.state.deleteRoleVisible}
                    onCancel={() => this.handleDeleteRoleCancel()}
                    footer={null}
                >
                    <Spin tip='删除中' spinning={deleteing}>
                        <Form onSubmit={e => this.onClickDeletedRoles(e)}>
                            <Form.Item>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入当前管理员密码'
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='请输入当前管理员密码' />
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ float: "right" }}
                                >
                                    确认
                      </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title='撤销删除角色'
                    visible={this.state.undoDeleteRoleVisible}
                    onCancel={() => this.handleUndoDeleteRoleCancel()}
                    footer={null}
                >
                    <Spin tip="撤销删除中..." spinning={undodeleteing}>
                        <Form>
                            <Form.Item>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入当前管理员密码'
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='请输入当前管理员密码' />
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('statusValue', {
                                    initialValue: 1,
                                    rules: [
                                        {
                                            required: false,
                                            message: '请选择状态',
                                        },
                                    ],
                                })(
                                    <Select placeholder="请选择">
                                        <Option value={1}>启用</Option>
                                        <Option value={0}>禁用</Option>
                                    </Select>)}
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    style={{ float: "right" }}
                                    onClick={() => this.onClickUndoDelete()}
                                >
                                    确认
                      </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Spin>
        )
    }
}
const obtainDeletedRoleForm = Form.create()(obtainDeletedRole)
export { obtainDeletedRoleForm as obtainDeletedRole }