import React from 'react';
import { Table, Input, Button, Popconfirm, Form,Select } from 'antd';
import { connect } from 'react-redux';

const EditableContext = React.createContext();
const {Option}=Select;

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell  extends React.Component {
    state = {
        editing: false,
      };
    
      toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
          if (editing) {
            this.input.focus();
          }
        });
      };
    
      save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
          if (error && error[e.currentTarget.id]) {
            return;
          }
          this.toggleEdit();
          handleSave({ ...record, ...values });
        });
      };
    
      renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
          <Form.Item style={{ margin: 0 }}>
            {form.getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `${title} 不能为空.`,
                },
              ],
              initialValue: record[dataIndex],
            })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{ paddingRight: 24 }}
            onClick={this.toggleEdit}
          >
            {children}
          </div>
        );
      };
    
      render() {
        const {
          editable,
          dataIndex,
          title,
          record,
          index,
          handleSave,
          children,
          ...restProps
        } = this.props;
        return (
          <td {...restProps}>
            {editable ? (
              <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
            ) : (
              children
            )}
          </td>
        );
      }
    }
    
    class NoLadderConfiguration extends React.Component {
      constructor(props) {
        super(props);
        this.columns = [
        //   {
        //     title: '分成类型',
        //     dataIndex: 'operator',
        //     width: '30%',
        //     editable: true,
        //   },
        //   {
        //     title: '范围标识',
        //     dataIndex: 'type',
        //     editable: true,
        //   },
          {
            title: '起始参数',
            dataIndex: 'startValue',
            editable:true,
            width: '30%',
          },
          {
            title: '止点参数',
            dataIndex: 'endValue',
            editable: true,
            width: '30%',
          },
          {
            title: '分成金额',
            dataIndex: 'profit',
            editable: true,
            width: '30%',
          },
          {
            title: '操作',
            dataIndex: 'id',
            width: '10%',
            render: (text, record) =>(
                    <Popconfirm title="你确认删除吗?" onConfirm={() => this.handleDelete(record.key)}>
                        <a>删除</a>
                    </Popconfirm>
                    )
            
          },
        ];
    
            this.state = {
                dataSource: [
                    {
                        key: 0,
                        operator: 2,
                        type: 1,
                        id: this.props.id,
                        startValue: '100',
                        endValue: '200',
                        profit: 12,
                    },
                ],
                count: 1,
            };
        }

      handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
      };
    
      handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
          key: count,
          operator: 2,
          type: 1,
          id:this.props.id,
          startValue: '100',
          endValue: '200',
          profit: 12,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
        });
      };
    
      handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ dataSource: newData });
      };
        //详细配置
        DetailedConfig = () => {
            const { dispatch } = this.props;
            const { dataSource } = this.state;
            dispatch({
                type: 'SUBMIT_NODETAILEDCONFIG',
                nodetailedConfig: dataSource,
            })
            this.props.onClose()
            this.props.form.resetFields();
        }

      render() {
        const { dataSource } = this.state;
        const { nodetailedConfig, id } = this.props;
        // let selected = null;
       
        // if (this.props.dataSource && this.props.dataSource.length) {
        //    const marketing= this.props.dataSource
        //         .map(marketing => ({
        //           id:marketing.id,
        //           key:marketing.key,
        //           startValue:marketing.startValue,
        //           endValue:marketing.endValue,
        //           profit:marketing.profit
        //         }))
           
        //     if (marketing) {
        //       for (let i = 0; i < marketing.length; i++) {
        //         selected = marketing[i];
        //         if (marketing[i].id===id) {
                  
        //         }
        //       }
               
        //     }
        // }
      
        // const data=selected?selected:dataSource
        const components = {
          body: {
            row: EditableFormRow,
            cell: EditableCell,
          },
        };
        const columns = this.columns.map(col => {
          if (!col.editable) {
            return col;
          }
          return {
            ...col,
            onCell: record => ({
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              handleSave: this.handleSave,
            }),
          };
        });
    
        return (
          <div>
            <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
             添加行
            </Button>
          <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                size="middle"
                defaultExpandAllRows={true}
              />
           <Button 
           type="primary" 
           onClick={()=>this.DetailedConfig()}
           style={{float:"right",marginTop:20}}
           >保存</Button>
          </div>
        );
      }
    }
const mapStateToProps = state => {
    return { ...state.setparameters };
}
const NoLadderConfigurationPage = connect(mapStateToProps)(NoLadderConfiguration)
const NoLadderConfigurationForm = Form.create()(NoLadderConfigurationPage)
export { NoLadderConfigurationForm as NoLadderConfiguration }