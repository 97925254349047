import React from 'react';
import { Form, Button, Select, Input, Cascader } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { marketingService, accountService } from '../../services';

const InputGroup = Input.Group;
const { Option } = Select;

class DetailedConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }

    //详细配置
    DetailedConfig = e => {
        e.preventDefault();
        const { form } = this.props;
        const { dispatch } = this.props;

        form.validateFieldsAndScroll(async (err, values) => {
            dispatch({
                type: 'SUBMIT_DETAILEDCONFIG',
                current: 0,
                detailedConfig: values,
                id: this.props.id,
                change: this.state.change,
                changeFixed: this.state.changeFixed,
                operator: this.state.operator
            })

            this.props.handleCancel()
        })
        this.props.form.resetFields();
    }

    async componentDidMount() {
        await this.marketingAction()
        const { dispatch } = this.props;
        const { data } = this.state;
        dispatch({
            type: 'SAVE_MARKETING_INFO',
            marketingInfo: data
        })
    }

    //获取分成信息
    async marketingAction() {
        try {
            let accountId;
            accountId = this.props.record.accountId
            await marketingService.marketingAction(accountId)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        this.setState({
                            data: result.data,
                            changeFixed: result.data.operator,
                            onInput: result.data.type
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    onClickChange0() {
        this.setState({
            change: false,
            operator: 0,
            changeFixed: true
        })
    }
    onClickChange1() {
        this.setState({
            change: true,
            operator: 1,
            changeFixed: false
        })
    }
    onClickChange2() {
        this.setState({
            change: true,
            operator: 2,
            changeFixed: true
        })
    }
    onClickInput1() {
        this.setState({
            onInput: 1
        })
    }
    onClickInput2() {
        this.setState({
            onInput: 2
        })
    }
    render() {
        const { detailedConfig, statusArray, data, id } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { operator, onInput } = this.state;
        //判断数据库数据显示,显示默认值
        let marketing = [];
        marketing = data !== undefined ? data.marketingModel ? (data.marketingModel[0] === undefined ? null :
            data.marketingModel.map(business => ({
                marketingBusinessId: business.marketingBusinessId,
                businessId: business.businessId,
                makretingBusiness: business.makretingBusiness
                    .map(condition => ({
                        type: condition.type,
                        operator: condition.operator,
                        parameterValue: condition.parameterValue,
                        profit: condition.profit,
                    }))
            }))) : null : null
        let m = null;
        let mf = null;
        if (marketing) {
            const f = marketing.filter(x => x.businessId == id);
            if (f && f.length > 0) {
                m = f[0].makretingBusiness[0];
                mf = f[0];
            }
        }

        //从reducer中取出状态值,显示默认值
        let selected = null;
        if (detailedConfig && detailedConfig.length) {
            const marketing = detailedConfig
                .filter(marketing => marketing.id == id);
            if (marketing) {
                selected = marketing[0];
            }
        }

        //从reducer取出文本框状态值
        let stated = null;
        if (statusArray && statusArray.length) {
            const stateArray = statusArray.filter(state => state.id == id);
            if (stateArray) {
                stated = stateArray[0];
            }
        }
        //拆除数据
        let parameter = m ? m.parameterValue ? m.parameterValue.split(/[(,]/) : null : null;

        return (
            <Form onSubmit={e => this.DetailedConfig(e)}>
                <Form.Item style={{ display: 'none' }}>
                    {
                        getFieldDecorator('bisid', {
                            initialValue: mf ? mf.marketingBusinessId : null
                        })(<Input />)
                    }
                </Form.Item>
                <Form.Item style={{ display: 'none' }}>
                    {
                        getFieldDecorator('id', {
                            initialValue: id
                        })(<Input />)
                    }
                </Form.Item>
                <Form.Item label='分成类型'>
                    {
                        getFieldDecorator('operator', {
                            initialValue: selected ? selected.operator : m ? m.operator : null,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择分成类型',
                                }
                            ]
                        })(<Select disabled={true}>
                            <Option onClick={() => this.onClickChange0()} value={0}>不分成</Option>
                            <Option onClick={() => this.onClickChange1()} value={1}>固定分成</Option>
                            <Option onClick={() => this.onClickChange2()} value={2}>阶梯</Option>
                        </Select>)
                    }
                </Form.Item>
                {operator !== 0 && operator !== 1 ? m ? m.operator !== 0 && m.operator !== 1 ?
                    stated ? stated.change === true && stated.changeFixed === true ?
                        <Form.Item label='范围标识'>
                            {
                                getFieldDecorator('type', {
                                    initialValue: selected ? selected.type : m ? m.type : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择分成范围',
                                        }
                                    ]
                                })(<Select disabled={true}>
                                    <Option onClick={() => this.onClickInput1()} value={1}>范围</Option>
                                    <Option onClick={() => this.onClickInput2()} value={2}>大于</Option>
                                    {/* <Option disabled={this.state.change} value={3}>无</Option> */}
                                </Select>)
                            }
                        </Form.Item> : null : <Form.Item label='范围标识'>
                            {
                                getFieldDecorator('type', {
                                    initialValue: selected ? selected.type : m ? m.type : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择分成范围',
                                        }
                                    ]
                                })(<Select disabled={true}>
                                    <Option onClick={() => this.onClickInput1()} value={1} >范围</Option>
                                    <Option onClick={() => this.onClickInput2()} value={2}>大于</Option>
                                    {/* <Option disabled={this.state.change} value={3}>无</Option> */}
                                </Select>)
                            }
                        </Form.Item> : null : null : null
                }
                {onInput !== 1 ? operator !== 0 && operator !== 1 ? m ? m.operator !== 0 && m.operator !== 1 ? m ? m.type === 1 ?
                    stated ? stated.change === true && stated.changeFixed === true ?
                        <Form.Item label='分成参数'>
                            {
                                getFieldDecorator('startValue', {
                                    initialValue: selected ? selected.parameterValue : m ? m.parameterValue : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入分成参数',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item> : null : <>
                            <Form.Item label='起点参数'>
                                {
                                    getFieldDecorator('startValue', {
                                        initialValue: selected ? selected.parameterValue : m ? parameter[1] : null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入起点参数',
                                            }
                                        ]
                                    })(<Input />)
                                }
                            </Form.Item>
                            <Form.Item label='止点参数'>
                                {
                                    getFieldDecorator('endValue', {
                                        initialValue: selected ? selected.parameterValue : m ? parameter[2].split("]")[0] : null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入止点参数',
                                            }
                                        ]
                                    })(<Input />)
                                }
                            </Form.Item>

                        </> : <Form.Item label='分成参数'>
                        {
                            getFieldDecorator('startValue', {
                                initialValue: selected ? selected.parameterValue : m ? m.parameterValue : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成参数',
                                    }
                                ]
                            })(<Input />)
                        }
                    </Form.Item> : <Form.Item label='分成参数'>
                        {
                            getFieldDecorator('startValue', {
                                initialValue: selected ? selected.parameterValue : m ? m.parameterValue : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成参数',
                                    }
                                ]
                            })(<Input />)
                        }
                    </Form.Item> : null : null : null :
                    <>
                        <Form.Item label='起点参数'>
                            {
                                getFieldDecorator('startValue', {
                                    initialValue: selected ? selected.parameterValue : m ? m.parameterValue : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入起点参数',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item>
                        <Form.Item label='止点参数'>
                            {
                                getFieldDecorator('endValue', {
                                    initialValue: selected ? selected.parameterValue : m ? m.parameterValue : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入止点参数',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item>
                    </>
                }
                {operator !== 0 ? m ? m.operator !== 0 ?
                    stated ? stated.change === true ?
                        <Form.Item label='分成金额'>
                            {
                                getFieldDecorator('profit', {
                                    initialValue: selected ? selected.profit : m ? m.profit : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入分成金额',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item> : null : <Form.Item label='分成金额'>
                            {
                                getFieldDecorator('profit', {
                                    initialValue: selected ? selected.profit : m ? m.profit : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入分成金额',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item> : null : null : null
                }
                <Form.Item >
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        确定
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}
const mapStateToProps = state => {
    return { ...state.setparameters };
}
const DetailedConfigPage = connect(mapStateToProps)(DetailedConfig)
const DetailedConfigPageForm = Form.create()(DetailedConfigPage)
export { DetailedConfigPageForm as DetailedConfig }