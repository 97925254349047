import React from 'react';
import { Table, Spin, DatePicker, Form, Button } from 'antd';
import { reportService } from '../../services';
import moment from 'moment';

class MarketingOverViewReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            loading: false,
            start: new moment().startOf('day').subtract(1, 'months').clone().set({
                hour: 0, minute: 0, second: 0, millisecond: 0
            }),
            end: new moment().clone().set({
                hour: 23, minute: 59, second: 59, millisecond: 999
            }),
            id:this.props.record
        }
    }
    async componentDidMount() {
        const {id, start, end } = this.state;
        await this.marketingOverView(id,start, end);
    }
    //获取营销用户业务报表
    async marketingOverView(id,start_time, end_time) {
        try {
            this.setState({
                loading: true
            })
            const {id } = this.state;
            await reportService
                .marketingoverview(this.props.record.id?this.props.record.id:id,
                    start_time.format("YYYY-MM-DD HH:mm:ss"),
                    end_time.format("YYYY-MM-DD HH:mm:ss"))
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let list = result.data;
                      
                        this.setState({
                            list: list,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    report = (e) => {
        e.preventDefault();
        const { form } = this.props;
        this.setState({
            loading: true
        })
        form.validateFields((err, values) => {
            if (!err) {
                try {
                    const {id } = this.state;
                    const { start_time, end_time } = values;
                    this.marketingOverView(id,start_time, end_time);
                }
                catch (ex) {
                    alert(ex)
                }
            }
        })
    }
    render() {
        const { loading } = this.state;
        const { getFieldDecorator } = this.props.form
        const { list } = this.state;
        const columns = list ? list.columns : null;
        const rows = list ? list.rows : null;
        if (rows && rows.length) {
            rows.map((row, index) => {
                row["key"] = index + 1;
            })
        }
        if (columns && columns.length) {
            columns.map((col, index) => {
                col["key"] = index;
                if (col.children) {
                    col.children.map(sub => {
                        sub['align'] = 'center';
                    })
                } else {
                    col['align'] = 'center';
                }

                if (col.dataIndex === 'terminal') {
                    col.minWidth = 100;
                }
            })
        }

        return (
            <Spin tip="加载中..." spinning={loading}>
                <Form layout="inline" style={{marginBottom:20}} onSubmit={e => this.report(e)} >
                    <Form.Item>
                        {getFieldDecorator('start_time', {
                            initialValue: this.state.start,
                            'rules': [{ type: 'object', required: false }]
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="开始时间"
                            />)}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('end_time', {
                            initialValue: this.state.end,
                            'rules': [{ type: 'object', required: false }]
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="结束时间"
                            />)}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            key='confirm'
                            htmlType="submit"
                            type="primary" >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Table
                    style={{
                        width:'100%'
                    }}
                    columns={columns}
                    dataSource={rows}
                    bordered
                    pagination={false}
                    size="middle"
                    scroll={{ x: 1920 }}
                />
            </Spin>
        )
    }
}
const MarketingOverViewReportForm = Form.create()(MarketingOverViewReport)
export { MarketingOverViewReportForm as MarketingOverViewReport }