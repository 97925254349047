import { Steps, Input, AutoComplete, Button } from 'antd';
import React, { Component } from 'react';
import { isDate } from 'moment';
import { Link } from 'react-router-dom';

const { Step } = Steps;

class StepBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: new Date() }
    }
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    tick() {
        //setState改变状态的方式
        this.setState({ date: new Date() });
    }

    state = { open: false, supports: [] }
    render() {
        return (
            <h2>现在是 {this.state.date.toLocaleTimeString()}</h2>
        );
    }
}

export default StepBar