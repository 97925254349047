import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { accountService } from '../services';
import { history } from '../utils';

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        const { location } = history;

        this.state = {
            menus: [],
            currentUrl: location.pathname
        };
    }

    handleMenuRedirect(url) {
        if (url) {
            history.push(url);
        }
    }

    handleMenuRender(url) {
        if (url) {
            if (url.endWith('app'))
                return '0'
            return url;
        }
        return '0';
    }

    //调取user.servicers menus()方法，即调取用户权限菜单
    componentWillMount() {
        accountService.menus().then(response => {
            const { data } = response.data;

            this.setState({
                menus: data
            })
        });
    }

    initialMenu(menus) {
        let menuKey = null;
        for (let index = 0; index < menus.length; index++) {
            const menu = menus[index];

            if (menu.url === this.state.currentUrl) {
                menuKey = menu.name;
                break;
            }

            if (menu.children) {
                for (let subIndex = 0; subIndex < menu.children.length; subIndex++) {
                    const sub = menu.children[subIndex];
                    if (sub.url === this.state.currentUrl) {
                        menuKey = `${menu.name}:${sub.name}`;
                        break;
                    }
                }
            }

            if (menuKey !== null) {
                break;
            }
        }
        return menuKey;
    }

    render() {
        const { menus, selectedKey } = this.state;
        const { onSelect } = this.props;

        return (
            <Menu theme="dark" defaultSelectedKeys={[selectedKey]} mode="inline" onSelect={onSelect}>
                {
                    this.state.menus ?
                        menus.map((menu, index) => {
                            //循环遍历菜单

                            if (menu.children) {
                                let subs = menu.children;
                                return (
                                    <SubMenu key={menu.name} title={
                                        <>
                                            <Icon type={menu.icon} />
                                            <span>{menu.name}</span>
                                        </>
                                    } >
                                        {
                                            subs.map((subItem, subIndex) => {
                                                return (
                                                    <MenuItem key={`${menu.name}:${subItem.name}`} onClick={() =>
                                                        this.handleMenuRedirect(subItem.url)
                                                    }>
                                                        <Icon type={subItem.icon} />
                                                        <span>
                                                            {subItem.name}
                                                        </span>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </SubMenu>
                                );
                            }
                            else {
                                return (<MenuItem key={index}
                                    onClick={() =>
                                        this.handleMenuRedirect(menu.url)
                                    }
                                > <Icon type={menu.icon} /><span>{menu.name}</span></MenuItem>);
                            }
                        })
                        : null
                }
            </Menu>
        )
    }
}

export default MainMenu;