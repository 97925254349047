import React from 'react';
import {
    Spin,
    message,
    Table,
    AutoComplete,
    Button,
    Drawer,
    Tag,
    Input,
    Divider,
    Modal,
    Form
} from 'antd';
import { marketingService } from '../../services';
import { TerminalDetails } from '../MarketingUserPage';
import { Business } from '../MarketingActionPage';
import { connect } from 'react-redux';

class YesDistributionAccountModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fetching: false,
            pageindex: 1,
            pagesize: 5,
            value: null,
            totalCount: 0,
            visibleDrawer: false,
            terminalslist: [],
            selected: {},
            deleteMarketingUserVisible: false,
            deleteMaretingUsering: false
        }
    }
    componentDidMount() {
        const { value } = this.state;
        this.terminalsinfo(value);
    }
    //显示Drawer事件
    showDrawer = record => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: record,
        })
        const { dispatch } = this.props;
        dispatch({
            type: 'RESET'
        });
    }

    showMarketingUserModal = () => {
        this.props.form.resetFields();
        this.setState({
            deleteMarketingUserVisible: true,
        })
    }
    handleMarketingUserCancel = () => {
        this.setState({
            deleteMarketingUserVisible: false
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }
    //删除该分成用户的分成信息
    deleteMaretingUser = e => {
        e.preventDefault();
        const { form } = this.props;
        try {
            this.setState({
                deleteMaretingUsering: true
            })
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { password } = values;
                    await marketingService
                        .deleteMarketingUser(this.state.selected.accountId, password)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        deleteMaretingUsering: false
                                    })
                                }
                                else {
                                    message.success("删除分成用户信息成功")
                                    this.setState({
                                        deleteMaretingUsering: false
                                    })
                                    this.handleMarketingUserCancel();
                                    this.handlePaging(null, this.state.pageindex, this.state.pagesize);
                                    this.onClose();
                                }
                            }
                        })
                }
            })
        }
        catch (ex) {
            alert(ex)
        }
    }
    handlePaging = (value, pageindex, pagesize) => {
        try {
            marketingService.intoUserTerminal(this.props.marketingUser.id, value, pageindex - 1, pagesize)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            message.error(result.message)
                        }
                        this.setState({
                            data: list,
                            value: value,
                            pageindex: pageindex,
                            pagesize: pagesize,
                            totalCount: result.count,
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    };

    terminalsinfo = async (value) => {
        try {
            await marketingService.terminalsinfo(this.props.marketingUser.id, value)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let terminalslist = result.data;
                        terminalslist.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            message.error(result.message)
                        }
                        this.setState({
                            terminalslist: terminalslist,
                            value: value,
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }

    completesearch() {
        const text = this.state.data.filter(terminal => terminal.terminalname != null).map(terminal => terminal.terminalname)
        return text;
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { deleteMaretingUsering } = this.state;
        const { Search } = Input;
        const columns = [
            {
                title: '设备编码',
                dataIndex: 'terminalId',
                key: 'terminalId',
                width: "30%"
            },
            {
                title: '设备名称',
                dataIndex: 'terminalname',
                key: 'terminalname',
                width: "30%"
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: 300,
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>配置</a>
                    </span>
                ),
            },
        ];
        return (
            <>
                <AutoComplete
                    dataSource={this.completesearch()}
                    style={{ width: 330, marginBottom: 15 }}
                    onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                >
                    <Search placeholder="请输入设备名称"

                        onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                        enterButton style={{ width: 330 }}
                    />
                </AutoComplete>

                <Modal
                    title='删除分成用户信息'
                    visible={this.state.deleteMarketingUserVisible}
                    onCancel={() => this.handleMarketingUserCancel()}
                    footer={null}
                ><Spin tip='删除分成用户信息中...' spinning={deleteMaretingUsering}>
                        <Form onSubmit={e => this.deleteMaretingUser(e)}>
                            <Form.Item>
                                {
                                    getFieldDecorator('password', {
                                        rules: [{
                                            required: true,
                                            message: '请输入当前管理员密码!'
                                        }],
                                    })(
                                        <Input.Password placeholder="请输入当前管理员密码" />)}
                            </Form.Item>
                            <Form.Item>
                                <Button key='settle' htmlType="submit"
                                    type="primary" style={{ float: "right" }}>
                                    确认
                    </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>

                <Drawer
                    title="设备详情"
                    width={1024}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <Button
                        type="danger"
                        style={{ float: 'right', marginBottom: 10 }}
                        onClick={() => this.showMarketingUserModal()}>删除该分成用户信息</Button><br />
                    <TerminalDetails record={this.state.selected} />
                    <Divider orientation="left"><b>该终端以分成所属业务</b></Divider>
                    <Business
                        record={this.state.selected}
                        marketingUser={this.props.marketingUser}
                        onClose={this.onClose.bind(this)}
                        handlePaging={this.handlePaging.bind(this)}
                    />
                </Drawer>
                <Table columns={columns} dataSource={this.state.data}
                    size="middle"
                    pagination={{
                        pageSize: this.state.pagesize,
                        current: this.state.pageindex,
                        total: this.state.totalCount,
                        onChange: (pageindex, pageSize) => {
                            this.handlePaging(this.state.value, pageindex, pageSize);
                        }
                    }
                    }
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    return { ...state.setparameters };
}

const YesDistributionAccountModuleConnect = connect(mapStateToProps)(YesDistributionAccountModule)
const YesDistributionAccountModuleConnectForm = Form.create()(YesDistributionAccountModuleConnect)
export { YesDistributionAccountModuleConnectForm as YesDistributionAccountModule }