import React from 'react';
import { userService } from '../../services';
import { connect } from 'react-redux'
import {
    Descriptions,
    Table,
    Tag,
    Modal,
    Button,
    Input,
    message,
    Form,
    Spin,
    Radio,
    Checkbox,
    Row,
    Col,
    Divider
} from 'antd';

class CreatUserRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.user.id,
            data: {},
            body: {},
            user: [],
            userRoles: [],
            loading:false,
            createing:false
        }
    }
 
    //页面加载所需信息
    componentDidMount() {
        this.userRoles();
    }
  
    //获取用户与角色的关联
    userRoles() {
        this.setState({
          loading:true
        })
        userService.userRoles(this.state.userId)
            .then(response => {
                let result = response.data;
                this.setState({
                    userRoles: result.data,
                    loading:false
                })
            })
    }
    //更改用户权限
    checkedUserRights = e => {
        e.preventDefault();

        const { form } = this.props;
        try {
            form.validateFieldsAndScroll(async (err, values) => {

                if (!err) {
                    this.setState({
                        createing:true
                    })
                    const { roles } = values;
                    await userService.changeUserRights(this.state.userId, roles)
                        .then(response => {
                            let isok = response.data;
                            if (!isok.success) {
                                message.error(isok.message)
                            }
                            else {
                                message.success('添加成功');
                                this.setState({
                                    createing:false
                                })
                            }
                        })

                }
            })
        }
        catch (ex) {
            message.error('保存失败', 5);
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { user } = this.state;
        const {loading}=this.state;
        const {createing}=this.state;

        const roles = [];
        this.state.userRoles.filter(role => role.chooseRole)
            .map(role => roles.push(role.roleId));
        return (
            <Spin tip="添加中" spinning={createing}>
            <Spin tip="加载中" spinning={loading}>
      
                <Divider  orientation="left">用户角色</Divider>

                <Form onSubmit={e => this.checkedUserRights(e)}>
                    <Form.Item>
                        {getFieldDecorator('roles', {
                            initialValue: roles,
                        })(
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    {
                                        this.state.userRoles.map(role =>
                                            <Col key={role.roleId}
                                                span={8}
                                            >
                                                <Checkbox value={role.roleId}>
                                                    {role.roleName}
                                                </Checkbox>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Checkbox.Group>,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit"
                            key='confirm'
                            type="primary" 
                        >
                                    确认
                        </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Spin>
      
        )
    }
}
const CreatUserRolesFrom = Form.create()(CreatUserRoles)
export {CreatUserRolesFrom as CreatUserRoles}