import React from 'react';
import {
    Button,
    Form,
    Input,
    Spin,
    InputNumber
} from 'antd';
import { terminalManagenmentService } from '../../services';
import { connect } from 'react-redux';

class Pricelist extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false
        }
    }
    //上一步
    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
    }
    //加载信息
    componentDidMount() {
        this.priceList()
    }

    //获取指定设备价目表
    priceList = async () => {
        try {
            this.setState({
                loading: true
            })
            await terminalManagenmentService.pricelist()
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                        }
                        else {
                            this.setState({
                                data: result.data,
                                loading: false
                            })
                        }
                    }
                });
        }
        catch (ex) {
            alert(ex)
        }
    }
    //修改指定价目表
    changePrice = e => {
        e.preventDefault();

        const { form } = this.props;
        const { dispatch } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                dispatch({
                    type: 'SUBMIT_PRICELIST',
                    current: 4,
                    price: [...values.priceList]
                })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        const { loading } = this.state;
        const { price } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };

        return (
            <Spin tip="加载中" spinning={loading}>
                <Form onSubmit={e => this.changePrice(e)}>
                    {data.map((item, index) =>
                        [
                            <Form.Item key={`bin[${index}}]_${item.businessId}`} style={{ display: 'none' }} >
                                {
                                    getFieldDecorator(`priceList[${index}].businessid`, {
                                        initialValue: item.businessId,
                                    })(
                                        <InputNumber />
                                    )
                                }
                            </Form.Item>,
                            <Form.Item label={item.businessName} key={`price[${index}]_${item.businessId}`} >
                                {
                                    getFieldDecorator(`priceList[${index}].price`, {
                                        initialValue: price.priceList ?
                                            price.priceList[index].price :
                                            (item.price ? item.price : item.defaultPrice)
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        ])}
                    <Form.Item>
                        <Button onClick={() => this.prev()} style={{ float: "left" }}>上一步</Button>
                        <Button htmlType="submit" type='primary' style={{ float: "right" }}>下一步</Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.terminalSetting,
    }
}
const PricelistForm = Form.create()(Pricelist)
const PricelistConnect = connect(mapStateToProps)(PricelistForm)
export { PricelistConnect as Pricelist }