import React from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './utils';
import { alertActions } from './actions';
import {Router as MainRouter} from './components/Routings';
import logo from './logo.svg';
import '@/App.css';

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <MainRouter />
        </Router> 
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export default connectedApp;
