import React from 'react';
import {
    Button,
    Table,
    Input,
    Spin,
    message,
    Modal,
    Form,
    Tag,
    Drawer,
    Select
} from 'antd';
import { marketingService } from '../../services';
import { MarketingUserModule } from '../MarketingUserPage';

class MarketingUsersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            visibleModal: false,
            visibleDrawer: false,
            loading: false,
        }
    }
    //添加分成用户调用方法
    creatMarketingUser = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { marketingUserName, bankcardnumber, phonenumber, wechat, office } = values;
                    await marketingService
                        .createIntoUser(marketingUserName, bankcardnumber, phonenumber, wechat, office)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                }
                                else {
                                    message.success("添加成功")
                                    this.handlePaging();
                                    this.setState({
                                        visibleModal: false
                                    })
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex);
                }
            }
        })
    }
    //显示Drawer事件
    showDrawer = marketingUser => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: marketingUser,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    //显示Modal事件
    showModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };

    //查询用户信息，list分页，搜索功能
    handlePaging(keyword, page, size) {
        this.setState({
            ...this.state,
            loading: true
        })
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const { statusValue } = values;
            marketingService.intoUser(keyword, statusValue, page - 1, size)
                .then(response => {
                    if (response) {
                        var result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        this.setState({
                            page: page,
                            keyword: keyword,
                            pagesize: size,
                            list: list,
                            totalCount: result.count,
                            loading: false,
                        })
                    }
                })
        });
    }
    //调取所有用户信息
    componentDidMount() {
        const { pagesize, keyword } = this.state;
        this.handlePaging(keyword, 1, pagesize);
        this.setState({
            loggingIn: true
        })
    }
    render() {
        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const columns = [
            {
                title: '分成用户姓名',
                dataIndex: 'marketingusername',
                key: 'marketingusername',
            },
            {
                title: '所属用户',
                dataIndex: 'username',
                key: 'username',
                render: (text, record) => {
                    if (record.username === null) {
                        return <Tag color="black" key={record.status}>
                            禁止登陆
                     </Tag>
                    }
                    if (record.username !== null) {
                        return record.username
                    }
                }
            },
            {
                title: '银行卡号',
                dataIndex: 'bankcardnumber',
                key: 'bankcardnumber',
            },
            {
                title: '手机号',
                dataIndex: 'phonenumber',
                key: 'phonenumber'
            },
            {
                title: '微信号',
                dataIndex: 'wechat',
                key: 'wechat'
            },
            {
                title: '描述',
                dataIndex: 'office',
                key: 'office'
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                  </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                  </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
                  </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },
        ];

        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const { Search } = Input;
        const { loading } = this.state;

        return (
            <Spin tip={"加载中"} spinning={loading}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('statusValue', {
                            initialValue: 'all',
                            rules: [
                                {
                                    required: false,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select placeholder="请选择"
                                style={{ width: 120 }}
                            >
                                <Option value='all'>全部</Option>
                                <Option value={1}>启用</Option>
                                <Option value={0}>禁用</Option>
                            </Select>)}
                    </Form.Item>
                </Form>
                <Search placeholder="请输入分成用户名"
                    onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Button type="primary" icon="plus"
                    style={{ float: "right" }}
                    onClick={this.showModal}>添加分成用户
            </Button>
                <Table columns={columns} dataSource={this.state.list}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pagesize) => {
                            this.handlePaging(this.state.keyword, 1, pagesize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, page, pageSize);
                        }
                    }
                    }
                />
                <Drawer
                    title="分成用户详情页"
                    width={"100%"}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <MarketingUserModule
                        onClose={this.onClose.bind(this)}
                        showDrawer={this.showDrawer.bind(this)}
                        marketingUser={this.state.selected}
                        handlePaging={this.handlePaging.bind(this)} />
                </Drawer>

                <Modal
                    title="添加分成用户"
                    visible={this.state.visibleModal}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form  {...formItemLayout} onSubmit={e => this.creatMarketingUser(e)}>
                        <Form.Item label="用户名">
                            {getFieldDecorator('marketingUserName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成用户名',
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="银行卡号">
                            {getFieldDecorator('bankcardnumber', {
                                rules: [
                                    {
                                        required: false,
                                        pattern: new RegExp(/^[0-9]*$/),
                                        message: '请输入数字'
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="手机号">
                            {getFieldDecorator('phonenumber', {
                                rules: [
                                    {
                                        required: false,
                                        pattern: new RegExp(/^[0-9]*$/),
                                        message: '请输入数字'
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="微信号">
                            {getFieldDecorator('wechat', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="描述">
                            {getFieldDecorator('office', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 17, offset: 5 }}>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                htmlType="submit">
                                确认
                </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        )
    }
}
const MarketingUsersPageForm = Form.create()(MarketingUsersPage);
export { MarketingUsersPageForm as MarketingUsersPage }