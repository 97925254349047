import React from 'react';
import {
    Tabs,
    Input,
    Form,
    Button,
    Spin,
    InputNumber,
    message,
    Tooltip,
    Icon
} from 'antd';
import { terminalManagenmentService } from '../../services';

const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

class TerminalConfigPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false
        }
    }

    async componentDidMount() {
        await this.TerminalConfig();
    }
    //终端设备配置信息
    async TerminalConfig() {
        try {
            this.setState({
                loading: true
            })
            let uid = this.props.terminal.uid
            await terminalManagenmentService.TerminalConfig(uid)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                        }
                        else {
                            this.setState({
                                data: result.data,
                                loading: false
                            })
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }

    //修改终端配置信息
    changeTerminalConfig = e => {
        e.preventDefault();
        try {
            const { form } = this.props;
            try {
                form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        await terminalManagenmentService
                            .changeTerminalConfig(this.props.terminal.uid, values)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    try {
                                        if (!result.success) {
                                            message.error('修改失败')
                                        }
                                        else {
                                            this.props.updataDeploy();
                                            message.success('修改成功')
                                            this.props.handleAddTerminalConfigCancel();
                                        }
                                    }
                                    catch (ex) {
                                        message.success('修改成功')
                                        this.props.handleAddTerminalConfigPageCancel();
                                    }
                                }
                            })
                    }
                })
            }
            catch (ex) {
                alert('请检查网络')
            }
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const { loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        const keys = Object.keys(data);
        const { config } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };
        return (

            <Spin tip="加载中" spinning={loading}>
                <Form onSubmit={e => this.changeTerminalConfig(e)}>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        {keys.map(key =>
                            <TabPane tab={key} key={`tab_${key}`}>{
                                data[key].map((item, index) => [
                                    <Form.Item label={item.name}
                                        key={`id[${index}]_${item.id}`} style={{ display: 'none' }}>
                                        {
                                            getFieldDecorator(`${key}[${index}].id`, {
                                                initialValue: item.id
                                            })(<InputNumber />)
                                        }
                                    </Form.Item>,
                                    <Form.Item
                                        label={<span>
                                            {item.name}&nbsp;
                                        <Tooltip title={item.description}>
                                                <Icon type="question-circle-o" style={{ color: "blue" }} />
                                            </Tooltip>
                                        </span>}
                                        key={`val[${index}_${item.id}]`}>
                                        {
                                            getFieldDecorator(`${key}[${index}].value`, {
                                                initialValue: item.value ? item.value : item.defaultvalue
                                            })(<Input
                                                suffix={
                                                    <Tooltip title={item.description}>
                                                        <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                }
                                            />)
                                        }
                                    </Form.Item>
                                ])
                            }
                            </TabPane>)}
                    </Tabs>
                    <Form.Item>
                        <Button
                            style={{ float: "right" }}
                            type="primary"
                            htmlType="submit"
                        >
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}

const TerminalConfigForm = Form.create()(TerminalConfigPage)
export { TerminalConfigForm as TerminalConfigPage }