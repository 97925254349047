import React from 'react';
import { Form, Button, Spin, Input } from 'antd';

class EnableMarketingUser extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.onClickDeletedMarketingUser}>
                <Form.Item>
                    {
                        getFieldDecorator('pwd', {
                            rules: [{
                                required: true,
                                message: '请输入当前管理员密码!'
                            }],
                        })(
                            <Input.Password placeholder="请输入当前管理员密码" />)}
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit">
                        确认
            </Button>
                </Form.Item>
            </Form>
        )
    }
}
const EnableMarketingUserForm = Form.create()(EnableMarketingUser)
export { EnableMarketingUserForm as EnableMarketingUser }