import React from 'react';
import {
    Form,
    Input,
    message,
    Spin,
    Button,
    Radio
} from 'antd';
import { terminalManagenmentService } from '../../services/terminalManagenment.service';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

class AddTreminal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            random: 0
        }
    }
    //添加终端机信息
    AddTreminal = e => {
        e.preventDefault();
        const { form } = this.props;
        const { dispatch } = this.props;

        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                dispatch({
                    type: 'SUBMIT_DEVICE_INFORMATION',
                    current: 1,
                    deviceInformation: values
                })
            }
        })
    }

    render() {
        const { TextArea } = Input;
        const { deviceInformation } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };
        return (

            <Form onSubmit={e => this.AddTreminal(e)}>
                <Form.Item label='授权驾驶证接口访问'>
                    {
                        getFieldDecorator('driverbusiness', {
                            initialValue: deviceInformation.driverbusiness,
                            rules: [
                                {
                                    required: true,
                                    message: '请授权驾驶证接口访问',
                                }
                            ]
                        })(<Radio.Group>
                            <Radio.Button defaultChecked={true}
                                value={true}>准许访问</Radio.Button>
                            <Radio.Button value={false}>禁止访问</Radio.Button>
                        </Radio.Group>)
                    }
                </Form.Item>
                <Form.Item label='是否参与营销'>
                    {
                        getFieldDecorator('marketing', {
                            initialValue: deviceInformation.marketing,
                            rules: [
                                {
                                    required: true,
                                    message: '请授权参与营销',
                                }
                            ]
                        })(<Radio.Group>
                            <Radio.Button defaultChecked={true}
                                value={true}>参与营销</Radio.Button>
                            <Radio.Button value={false}>不参与营销</Radio.Button>
                        </Radio.Group>)
                    }
                </Form.Item>
                <Form.Item label='设备编号'>
                    {
                        getFieldDecorator('terminalId', {
                            initialValue: deviceInformation.terminalId,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入设备编号',
                                }
                            ]
                        })(<Input placeholder='请输入设备编号' />)
                    }
                </Form.Item>
                <Form.Item label='设备名'>
                    {
                        getFieldDecorator('name', {
                            initialValue: deviceInformation.name,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入设备名',
                                }
                            ]
                        })(<Input placeholder='请输入设备名' />)
                    }
                </Form.Item>
                <Form.Item label='经度'>
                    {
                        getFieldDecorator('gpsLng', {
                            initialValue: deviceInformation.gpsLng,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入经度'
                                }
                            ]
                        })(<Input placeholder='请输入经度' />)
                    }
                </Form.Item>
                <Form.Item label='纬度'>
                    {
                        getFieldDecorator('gpsLag', {
                            initialValue: deviceInformation.gpsLag,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入纬度'
                                }
                            ]
                        })(<Input placeholder='请输入纬度' />)
                    }
                </Form.Item>
                <Form.Item label='备注'>
                    {
                        getFieldDecorator('remark', {
                            initialValue: deviceInformation.remark,
                            rules: [{
                                required: false,
                            }]
                        })(
                            <TextArea style={{ resize: "none" }}
                                placeholder="请输入需要备注的信息"
                                autosize={{ minRows: 3, maxRows: 3 }}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item >

                    <Button
                        style={{ float: 'left' }}
                    >
                        <Link to='/app/terminal-managenment'>返回</Link>
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        下一步
            </Button>
                </Form.Item>
            </Form>

        )
    }
}
const mapStateToProps = state => {
    return { ...state.terminalSetting };
}

const AddTreminalForm = Form.create()(AddTreminal);
const AddTreminalConnect = connect(mapStateToProps)(AddTreminalForm)
export { AddTreminalConnect as AddTreminal }