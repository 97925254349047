import React from 'react';
import { Button, message, Result, Spin } from 'antd';
import { terminalManagenmentService } from '../../services';
import { connect } from 'react-redux';

class ConfirmPage extends React.Component {
    constructor(props) {
        super(props)
    }
    async submit() {
        const {
            deviceInformation,
            config,
            phototype,
            price,
        } = this.props;

        const { dispatch } = this.props;
        dispatch({ type: 'SUBMIT_FORM' })
        let loading = message.loading('正在提交资料...', 2);
        let uid;
        try {
            const { terminalId, marketing,name, gpsLng, gpsLag, remark, driverbusiness } = deviceInformation;
            await terminalManagenmentService
                .createTerminal(terminalId, marketing,name, remark, gpsLng, gpsLag, driverbusiness)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (result.success) {
                            uid = result.data;
                            dispatch({
                                type: 'SUBMIT_FINISH',
                                error: false
                            });
                            try {
                                terminalManagenmentService.changeTerminalConfig(uid, config)
                                try {
                                    terminalManagenmentService.changephototypes(uid, phototype)
                                    try {
                                        terminalManagenmentService.changepricelist(uid, price)
                                    }
                                    catch (ex) {
                                        message.error(ex)
                                    }
                                }
                                catch (ex) {
                                    message.error(ex)
                                }
                            }
                            catch (ex) {
                                message.error(ex)
                            }
                        }
                        else {
                            dispatch({
                                type: 'SUBMIT_FINISH',
                                error: true
                            });
                            message.error(result.message);
                        }
                    }
                })
        }
        catch (ex) {
            dispatch({
                type: 'SUBMIT_FINISH',
                error: true
            });
            message.error(ex.message, 5);
        }
        finally {
            loading();
        }
    }
    //上一步
    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
    }
    render() {
        const { posting } = this.props;
        return (
            <div>
                <Result
                    status="success"
                    title="您确认提交设备配置信息吗!"
                    subTitle="请确认您的数据正确"
                />
                <div style={{ clear: 'both', textAlign: "center" }}>
                    <Button onClick={() => this.prev()} style={{ float: "left" }}>
                        上一步
                    </Button>
                    <Button onClick={() => this.submit()} type='primary'
                        disabled={posting} style={{ float: "right" }}>
                        确认
                    </Button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.terminalSetting
    }
}

const ConfirmConnect = connect(mapStateToProps)(ConfirmPage)
export { ConfirmConnect as ConfirmPage };