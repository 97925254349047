import {
    Form,
    Icon,
    Input,
    Button,
    Spin,
    Alert
} from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../actions/alert.actions'
import '@/pages/LoginPage/LoginPage.css';
import { userActions } from '../../actions';
import { history } from '../../utils';


class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            this.props.clearAlerts();
        });
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = e => {
        e.preventDefault();
        const { login, clearAlerts, form } = this.props;

        clearAlerts();
        form.validateFields((err, values) => {
            if (!err) {
                try {
                    const { username, password } = values;
                    login(username, password)
                }
                catch (ex) {
                    alert(ex);
                }
            }
        });
    }

    render() {
        const { loggingIn, alert } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className='login-container'>
                <Spin tip="登陆中..." spinning={loggingIn}>

                    <Form onSubmit={this.handleSubmit} className="login-form">
                        {alert.message &&
                            <Alert message={alert.message}
                                type='error'
                                showIcon
                            />
                        }
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '请输入你的用户名!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="用户名"
                                    className="lnput-height"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入你的密码!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="密码"
                                    className="lnput-height"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登陆
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    const { authentication, alert, } = state;
    return { authentication, alert, loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    clearAlerts: alertActions.clear
};

const Page = Form.create()(LoginPage)
const connectedLoginPage = connect(mapState, actionCreators)(Page);
export default connectedLoginPage;