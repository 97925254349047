import React from 'react';
import { Button, Table, message, Spin, Drawer } from 'antd';
import { marketingService } from '../../services'

class TerminalIntoUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            loading: false,
            visibleDrawer: false
        }
    }
    //显示Drawer事件
    showDrawer = record => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: record,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }
    async componentDidMount() {
        await this.TerminalIntoUsers();
    }
    async TerminalIntoUsers() {
        try {
            this.setState({
                loading: true
            })
            await marketingService.terminalsIntoUsers(this.props.record.terminalId)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let list = result.data;
                        list.map((item, index) => item['key'] = index)
                        if (!result.success) {
                            message.error(result.data)
                            this.setState({
                                loading: false
                            })
                        }
                        this.setState({
                            list: list,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const columns = [
            {
                title: '分成用户名',
                dataIndex: 'marketingusername',
                key: 'marketingusername',
            },
            // {
            //     title: '操作',
            //     dataIndex: 'id',
            //     key: 'id',
            //     render: (text, record) => (
            //         <span>
            //             <a onClick={() => this.showDrawer(record)}>查看</a>
            //         </span>
            //     ),
            // },
        ];
        const { loading } = this.state;
        return (
            <Spin tip="加载中..." spinning={loading} >
                <Table columns={columns} dataSource={this.state.list}
                    size="middle"
                />
                <Drawer
                    title="配置"
                    width={"100%"}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    0
                </Drawer>
            </Spin>)
    }
}

export { TerminalIntoUsers }