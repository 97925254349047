import React from 'react';
import {
    Button,
    Descriptions,
    message,
    Modal,
    Form,
    Spin,
    Input,
    Tag
} from 'antd';
import { terminalManagenmentService } from '../../services';
import ChangePhototypes from '../../pages/TerminalManagementPage/ChangePhototypes';
import { AddTreminalPage } from './AddTerminalPage';
import { ChangeTerminalPage } from './ChangeTerminalPage';
import { TerminalConfigPage } from './TerminalConfigPage';
import { PricelistPage } from './PricelistPage';

const { confirm } = Modal;

class TerminalManagementModule extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            terminal: props.terminal,
            body: {},
            deleteVisible: false,
            DownloadVisible: false,
            loading: false,
            confirmState: undefined,
            updateing: false,
            addTerminalVisible: false,
            terminalConfigVisible: false,
            priceListVisible: false,
            photoTypeVisible: false,
            KeyPassword: false,
            changeTerminalVisible: false,
            terminalConfigPageVisible: false,
            photoTypePageVisible: false,
            priceListPageVisible: false,
            deleteing: false
        }
    }

    //逻辑删除设备
    deleteTerminal = async () => {
        try {
            try {
                this.setState({
                    deleteing: true
                })
                await terminalManagenmentService
                    .deleteTerminal([this.state.terminal.uid])
                    .then(response => {
                        if (response) {
                            let result = response.data;
                            if (!result.success) {
                                message.error(result.message)
                                this.setState({
                                    deleteing: false
                                })
                            }
                            else {
                                message.success('删除成功')
                                this.setState({
                                    deleteing: false
                                })
                                this.props.onCloseDrawer();
                                this.props.handlePaging(null, 1, this.state.pagesize);
                            }
                        }
                    })
            }
            catch (ex) {
                message.error('请检查网络是否出现问题，删除失败')
            }
        }

        catch (ex) {
            alert(ex);
        }
    }
    //加载终端机信息
    loadTerminal() {
        try {
            this.setState({
                loading: true
            })
            terminalManagenmentService.terminal(this.state.terminal.terminalId)
                .then(response => {
                    if (response) {
                        var body = response.data;
                        if (!body.success) {
                        }
                        this.setState({
                            body: body.data,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }

    //更新设备密钥
    updatekey = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { password } = values;
                try {
                    this.setState({
                        updateing: true
                    })
                    let id = this.state.terminal.uid;
                    await terminalManagenmentService
                        .updatekey(id, password)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error('密码错误,请重试', 5)
                                    this.setState({
                                        updateing: false
                                    })
                                }
                                else {
                                    message.success('更新成功', 5)
                                    let updatekey = result.data;
                                    let updatesecret = this.state.body;
                                    updatesecret.secret = updatekey;
                                    this.setState({
                                        secret: updatekey,
                                        updateing: false,
                                        KeyPassword: false
                                    })
                                    this.props.handlePaging(null, 1, this.state.pagesize);
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        })
    }

    renderKey(key) {
        if (key === 'terminalId') {
            return '设备编号'
        }
        if (key === 'name') {
            return '设备名'
        }
        if (key === 'status') {
            return '状态'
        }
        if (key === 'putOnDate') {
            return '部署时间'
        }
        if (key === 'remark') {
            return '备注'
        }
        if (key === 'gpsLng') {
            return '经度'
        }
        if (key === 'gpsLag') {
            return '纬度'
        }
        if (key === 'secret') {
            return '终端授权密钥'
        }

        return key;
    }
    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 255:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        return value;
    }
    //显示删除Modal事件
    showDeleteModal = () => {
        this.setState({
            deleteVisible: true
        })
    }
    //关闭删除model事件
    handleDeleteCancel = () => {
        this.setState({
            deleteVisible: false
        })
    }
    //显示添加设备modal事件
    showAddTerminalModal = () => {
        this.setState({
            addTerminalVisible: true
        })
    }
    //关闭添加设备modal事件
    handleAddTerminalCancel = () => {
        this.setState({
            addTerminalVisible: false
        })
    }
    //显示添加设备modal事件
    showChangeTerminalModal = () => {
        this.setState({
            changeTerminalVisible: true
        })
    }
    //关闭添加设备modal事件
    handleChangeTerminalCancel = () => {
        this.setState({
            changeTerminalVisible: false
        })
    }

    //显示添加设备配置modal事件
    showAddTerminalConfigModal = () => {
        this.setState({
            terminalConfigVisible: true,
        })
    }
    //关闭添加设备配置modal事件
    handleAddTerminalConfigCancel = () => {
        this.setState({
            terminalConfigVisible: false
        })
    }

    //显示添加设备配置Page modal事件
    showAddTerminalConfigPageModal = () => {
        this.setState({
            terminalConfigPageVisible: true,
        })
    }
    //关闭添加设备配置PAgemodal事件
    handleAddTerminalConfigPageCancel = () => {
        this.setState({
            terminalConfigPageVisible: false
        })
    }
    //显示添加价格表modal事件
    showPriceListModal = () => {
        this.setState({
            priceListVisible: true
        })
    }
    //关闭添加价格表modal事件
    handlePriceListCancel = () => {
        this.setState({
            priceListVisible: false
        })
    }

    //显示添加价格表Page modal事件
    showPriceListPageModal = () => {
        this.setState({
            priceListPageVisible: true
        })
    }
    //关闭添加价格表Page modal事件
    handlePriceListPageCancel = () => {
        this.setState({
            priceListPageVisible: false
        })
    }

    //显示修改照片类型modal事件
    showPhotoTypesModal = () => {
        this.setState({
            photoTypeVisible: true
        })
    }

    //关闭修改照片类型modal事件
    handlePhotoTypesCancel = () => {
        this.setState({
            photoTypeVisible: false
        })
    }
    //关闭修改照片类型Page modal事件
    handlePhotoTypesPageCancel = () => {
        this.setState({
            photoTypePageVisible: false
        })
    }
    //显示修改照片类型Page modal事件
    showPhotoTypesPageModal = () => {
        this.setState({
            photoTypePageVisible: true
        })
    }
    //显示修改照片类型modal事件
    showKeyPasswordsModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            KeyPassword: true
        })
    }
    //关闭修改照片类型modal事件
    handleKeyPasswordCancel = () => {
        this.setState({
            KeyPassword: false
        })
    }
    async componentDidMount() {
        await this.loadTerminal();
    }

    //显示下载配置Modal事件
    showDownloadModal = async () => {
        confirm({
            title: '是否配置新设备',
            okText: '是',
            cancelText: '否',
            onOk: () => {
                this.updataDeploy(true);
            },
            onCancel: () => {
                this.updataDeploy(false);
            }
        })
    }
    //modal销毁事件
    destroyAll() {
        Modal.destroyAll();
    }
    //修改配置点击事件
    onClickAddTerminalConfig() {
        this.showAddTerminalConfigModal();
        this.destroyAll();
    }
    //修改价目表点击事件
    onClickPriceList() {
        this.showPriceListModal();
        this.destroyAll();
    }
    //修改照片类型点击事件
    onClickPhotoTypes() {
        this.showPhotoTypesModal();
        this.destroyAll();
    }
    //修改缺失认证信息点击事件
    onClickAddTerminal() {
        this.showAddTerminalModal();
        this.destroyAll();
    }
    //下载配置信息
    updataDeploy = async isNew => {
        try {
            await terminalManagenmentService
                .deploy(this.props.terminal.uid, isNew)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (result.success === false) {
                            confirm({
                                title: '缺失配置的信息',
                                okText: '确认',
                                cancelText: '返回',
                                content: result.data.map(key => {
                                    if (key === "config") {
                                        return <div key={key} style={{ margin: "10 0" }}>
                                            <Button style={{ marginTop: 8 }}
                                                onClick={() => this.onClickAddTerminalConfig()} >
                                                缺失配置
                                            </Button>
                                        </div>
                                    }
                                    if (key === "pricelist") {
                                        return <div key={key} style={{ margin: "10 0" }}
                                            onClick={() => this.onClickPriceList()}>
                                            <Button style={{ marginTop: 8 }}>
                                                缺失价目表
                                            </Button><br />
                                        </div>
                                    }
                                    if (key === "phototype") {
                                        return <div key={key} style={{ margin: "10 0" }}
                                            onClick={() => this.onClickPhotoTypes()}>
                                            <Button style={{ marginTop: 8 }}>
                                                缺失照片类型
                                            </Button><br />
                                        </div>
                                    }
                                    if (key === "zzt_terminal") {
                                        return <div key={key} style={{ margin: "10 0" }}>
                                            <Button style={{ marginTop: 8 }}
                                                onClick={() => this.onClickAddTerminal()}>
                                                缺失驾驶证认证信息
                                            </Button><br />
                                        </div>
                                    }
                                })
                            })
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const { body } = this.state;
        const { loading } = this.state;
        const { updateing } = this.state;
        const { deleteing } = this.state;
     
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin tip='加载中..' spinning={loading}>
                <Spin tip='删除中..' spinning={deleteing}>
                    <div style={{ minWidth: 980 }}>
                        {
                            this.state.body.status !== 255 ?
                                <Button type="danger"
                                    onClick={this.deleteTerminal}
                                    style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                                >
                                    删除设备
                            </Button> : null
                        }
                        <Button type="ghost"
                            onClick={() => this.showDownloadModal()}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            下载配置文件
                </Button>
                        <Button type="ghost"
                            onClick={this.showKeyPasswordsModal}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            更新密钥
                </Button>
                        <Button type="ghost"
                            onClick={this.showPhotoTypesPageModal}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            更改照片类型
                </Button>
                        <Button type="ghost"
                            onClick={this.showPriceListPageModal}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            更改价目表
                </Button>
                        <Button type="ghost"
                            onClick={this.showAddTerminalConfigPageModal}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            更改设备配置
                </Button>
                        <Button type="ghost"
                            onClick={this.showChangeTerminalModal}
                            style={{ marginRight: 15, marginBottom: 10, float: "right" }}
                        >
                            更改设备
                </Button>
                    </div>
                    <Descriptions bordered border >
                        {
                            Object.keys(body).filter(key => {
                                //filter过滤数组中元素
                                if (key === 'uid') return false;
                                if (key === 'putOnDate') return false;
                                if (key === 'gpsLng') return false;
                                if (key === 'gpsLag') return false;
                                if (key === 'driverbusiness') return false;
                                return true;
                            }).map(key =>
                                <Descriptions.Item
                                    key={key}
                                    label={this.renderKey(key)}>
                                    {this.renderValue(key, body[key])}
                                </Descriptions.Item>
                            )
                        }
                    </Descriptions>
                    <Modal
                        title='添加设备'
                        visible={this.state.addTerminalVisible}
                        onCancel={() => this.handleAddTerminalCancel()}
                        footer={null}
                    >
                        <AddTreminalPage />
                    </Modal>
                    <Modal
                        title='修改设备'
                        visible={this.state.changeTerminalVisible}
                        onCancel={() => this.handleChangeTerminalCancel()}
                        footer={null}
                    >
                        <ChangeTerminalPage terminal={this.props.terminal}
                            handlePaging={this.props.handlePaging.bind(this)}
                            handleChangeTerminalCancel={this.handleChangeTerminalCancel.bind(this)}
                        />
                    </Modal>
                    <Modal
                        title='修改设备配置信息'
                        visible={this.state.terminalConfigVisible}
                        onCancel={() => this.handleAddTerminalConfigCancel()}
                        footer={null}
                    >
                        <TerminalConfigPage
                            terminal={this.props.terminal}
                            updataDeploy={this.updataDeploy.bind(this)}
                            handleAddTerminalConfigCancel={this.handleAddTerminalConfigCancel.bind(this)}

                        />
                    </Modal>
                    <Modal
                        title='修改设备配置信息'
                        visible={this.state.terminalConfigPageVisible}
                        onCancel={() => this.handleAddTerminalConfigPageCancel()}
                        footer={null}
                    >
                        <TerminalConfigPage
                            terminal={this.props.terminal}
                            handleAddTerminalConfigPageCancel={this.handleAddTerminalConfigPageCancel.bind(this)}
                        />
                    </Modal>
                    <Modal
                        title='修改价目表'
                        visible={this.state.priceListVisible}
                        onCancel={() => this.handlePriceListCancel()}
                        footer={null}
                    >
                        <PricelistPage
                            terminal={this.props.terminal}
                            updataDeploy={this.updataDeploy.bind(this)}
                            handlePriceListCancel={this.handlePriceListCancel.bind(this)}
                        />
                    </Modal>
                    <Modal
                        destroyOnClose={true}
                        title='修改价目表'
                        visible={this.state.priceListPageVisible}
                        onCancel={() => this.handlePriceListPageCancel()}
                        footer={null}
                    >
                        <PricelistPage
                            terminal={this.props.terminal}
                            handlePriceListPageCancel={this.handlePriceListPageCancel.bind(this)}
                        />
                    </Modal>
                    <Modal

                        title='修改照片类型'
                        visible={this.state.photoTypeVisible}
                        onCancel={() => this.handlePhotoTypesCancel()}
                        footer={null}
                    >
                        <ChangePhototypes
                            terminal={this.props.terminal}
                            updataDeploy={this.updataDeploy.bind(this)}
                            handlePhotoTypesCancel={this.handlePhotoTypesCancel.bind(this)}
                        />
                    </Modal>
                    <Modal
                        destroyOnClose={true}
                        title='修改照片类型'
                        visible={this.state.photoTypePageVisible}
                        onCancel={() => this.handlePhotoTypesPageCancel()}
                        footer={null}
                    >
                        <ChangePhototypes
                            terminal={this.props.terminal}
                            handlePhotoTypesPageCancel={this.handlePhotoTypesPageCancel.bind(this)}
                        />
                    </Modal>
                    <Modal
                        title='更新密钥'
                        visible={this.state.KeyPassword}
                        onCancel={() => this.handleKeyPasswordCancel()}
                        footer={null}
                    ><Spin tip='更新中..' spinning={updateing}>
                            <Form onSubmit={e => this.updatekey(e)}>
                                <Form.Item>
                                    {
                                        getFieldDecorator('password', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入当前管理员密码'
                                                }
                                            ]
                                        })(
                                            <Input.Password placeholder='请输入当前管理员密码' />
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        style={{ float: "right" }}
                                    >
                                        确认
                                </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Modal>
                </Spin>
            </Spin>
        )
    }
}
const TerminalManagementModuleForm = Form.create()(TerminalManagementModule)
export { TerminalManagementModuleForm as TerminalManagementModule }