import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class SuccessPage extends React.Component {
    render() {
        return (
            <div>
                <Result
                    status="success"
                    title="数据提交成功!"
                />
                <div style={{ clear: 'both', textAlign: "center" }}>
                    <Link to='/app/terminal-managenment'><Button>返回</Button></Link>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.terminalSetting
    }
}
const SuccessConnect = connect(mapStateToProps)(SuccessPage)
export { SuccessConnect as SuccessPage };