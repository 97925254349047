import axios from 'axios';
import { async } from 'q';
import Item from 'antd/lib/list/Item';

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
const hosts = {
    createIntoUser: "/api/marketinguser/create",
    updateIntoUser: "/api/marketinguser/update",
    intoUser: "/api/marketinguser",
    disableUser:"/api/marketinguser/disable",
    eraseUser:"/api/marketinguser/erase",
    intoUserTerminal:"/api/marketinguser/terminals",
    marketingterminal:"/api/marketinguser/marketingterminal",
    updateusermap:'/api/marketinguser/updateusermap',
    loginUser:'/api/marketinguser/loginuser',
    terminalsinfo:'/api/marketinguser/terminalsinfo',
    marketiTerminals:'/api/marketingterminal',
    terminalsIntoUsers:'/api/marketingterminal/users',
    business:'/api/marketingaction/business',
    createMarketing:'/api/marketingaction/create',
    marketingAction:'/api/marketingaction',
    update:'/api/MarketingAction/update',
    deleteMarketingUser:'/api/marketingaction/delete'
}

export const marketingService = {
    createIntoUser,
    intoUser,
    disableUser,
    eraseUser,
    intoUserTerminal,
    updateIntoUser,
    marketingterminal,
    updateusermap,
    marketiTerminals,
    loginUser,
    terminalsinfo,
    terminalsIntoUsers,
    business,
    createMarketing,
    marketingAction,
    update,
    deleteMarketingUser
}
//获取待分成业务设备
async function business(terminalid){
    const response=await myget(hosts.business,{
        params:{
            terminalid:terminalid
        }
    })
    return response;
}
//获取单个分成设备的所有分成用户
async function terminalsIntoUsers(terminalid){
     const response=await myget(hosts.terminalsIntoUsers,{
         params:{
            terminalid:terminalid
         }
     })
     return response;
}
//获取分成用户拥有的设备诱导信息
async function terminalsinfo(marketinguserid,search){
    const response=await myget(hosts.terminalsinfo,{
        params:{
            marketinguserid:marketinguserid,
            search:search
        }
    })
    return response;
}
//获取可绑定分成用户的登陆用户
async function loginUser(search){
    const response=await myget(hosts.loginUser,{
        params:{
            search:search
        }
    })
    return response;
}
//更改分成用户与登录用户的关联
async function updateusermap(userid,marketinguserid,add){
    const response=await mypost(hosts.updateusermap,{
        userid:userid,
        marketinguserid:marketinguserid,
        add:add
    })
    return response;
}
//获取分成用户未参与分成的的设备,可绑定的分成设备
async function marketingterminal(marketinguserid,search,pageindex,pagesize) {
    const response = await myget(hosts.marketingterminal, {
        params: {
            marketinguserid:marketinguserid,
            search:search,
            pageindex:pageindex,
            pagesize:pagesize
        }
    })
    return response;
}
//获取分成用户的分成设备
async function intoUserTerminal(marketinguserid,search,pageindex,pagesize){
     const response=await myget(hosts.intoUserTerminal,{
         params:{
            marketinguserid:marketinguserid,
             search:search,
             pageindex:pageindex,
             pagesize:pagesize
         }
     })
     return response;
}
//擦除分成用户
async function eraseUser(marketinguserid,pwd,about){
    const response=await mypost(hosts.eraseUser,{
        marketinguserid:marketinguserid,
        pwd:pwd,
        about:about,
    })
    return response;
}
//禁用分成用户
async function disableUser(marketinguserid,pwd,about){
    const response=await mypost(hosts.disableUser,{
        marketinguserid:marketinguserid,
        pwd:pwd,
        about:about,
    })
    return response;
}
//添加分成用户
async function createIntoUser(Marketingusername,Bankcardnumber,Phonenumber,wechat,office) {
    const response = await mypost(hosts.createIntoUser, {
        Marketingusername: Marketingusername,
        Bankcardnumber:Bankcardnumber,
        Phonenumber:Phonenumber,
        wechat:wechat,
        office:office
    })
    return response;
}
//更新分成用户
async function updateIntoUser(Marketinguserid,Marketingusername,Bankcardnumber,Phonenumber,wechat,office){
     const response=await mypost(hosts.updateIntoUser,{
        Marketinguserid:Marketinguserid,
        Marketingusername: Marketingusername,
        Bankcardnumber:Bankcardnumber,
        Phonenumber:Phonenumber,
        wechat:wechat,
        office:office
     })
     return response;
}
//获取分成用户
async function intoUser(search, status, pageindex, pagesize) {
    if (isNaN(status)) {
        status = null;
    }
    const response = await myget(hosts.intoUser, {
        params: {
            search: search,
            status: status,
            pageindex: pageindex,
            pagesize: pagesize
        }
    })
    return response;
}

//获取所有分成设备
async function marketiTerminals(search,pageindex,pagesize){
  const response=await myget(hosts.marketiTerminals,{
    params: {
      search: search,
      pageindex: pageindex,
      pagesize: pagesize
    }
  })
   return response;
}
//添加分成信息
async function createMarketing(marketingUserId, terminalId, noConfig) {
    const businessx =noConfig
    .map(business => {
        const data= noConfig.filter(x=>x.id==business.id);
        let payload=[];
        data.map(item=>{
            if(item.conditions){
                payload=[...item.conditions]
            }
            else{
                payload.push(item);
            }
        })

        return (
            {
                businessId:  business.id,
                joinMarketing: true,
                makretingBusiness:payload
            }
        )
    })
    const json= JSON.stringify(businessx);
    console.log(json);

    const response = await mypost(hosts.createMarketing, {
        marketingUserId: marketingUserId,
        terminalId: terminalId,
        marketingModel: businessx
    })
    return response;
}
//获取分成信息
async function marketingAction(accountId) {
    const url=`${hosts.marketingAction}/${accountId}`;
    const response=await myget(url)
    return response;
}

//更改分成信息
async function update(acctionId,  detailedConfig) {
    const business = detailedConfig.map(business => ({
        marketingBusinessId:business.bisid,
        joinMarketing: true,
        makretingBusiness: detailedConfig.filter(x=>x.id==business.id).map(condition => ({
            type: condition.type,
            operator: condition.operator,
            parameterValue: condition.parameterValue,
            profit: condition.profit,
            }))
    })
    )

    console.log(JSON.stringify({
        acctionId:acctionId,
        marketingModel: business
    }));

    const response = await mypost(hosts.update, {
        acctionId:acctionId,
        marketingModel: business
    })
    return response;
}

//删除分成信息,传值分成用户Id,当前登陆人密码
async function deleteMarketingUser(id,password) {
    const response=await mypost(hosts.deleteMarketingUser,{
        id:id,
        password:password
    })
    return response;
}