import { Table, Divider, Input, Tag, Drawer, Spin } from 'antd';
import React from 'react';
import { orderService } from '../../services';
import GetOrder from './OrderModule';
import { connect } from 'react-redux';

class OrdersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loggingIn: true,
            visible: false,//对话框是否可见
        };
    }
    showDrawer = order => {
        this.setState({
            ...this.state,
            visible: true,
            selected: order,
        });
    }
    onClose = () => {
        this.setState({
            ...this.state,
            visible: false,
        });
    }
    handlePaging(keyword, page, size) {
        this.setState({
            ...this.state,
            loggingIn: true
        })
        orderService.orders(keyword, page - 1, size)
            .then(response => {
                if (response) {
                    var result = response.data;
                    var list = result.data;
                    list.map((item, index) => item['key'] = index);
                    this.setState({
                        page: page,
                        keyword: keyword,
                        pagesize: size,
                        list: result.data,
                        totalCount: result.count,
                        loggingIn: false
                    })
                }
            });
    }

    //调取orders.servicers getodrder()方法，即调取所有订单信息
    componentDidMount() {
        const { pagesize, keyword } = this.state;
        this.handlePaging(keyword, 1, pagesize);
        this.setState({
            loggingIn: true
        })
    }
    render() {
        const { loggingIn } = this.state;
        const { Search } = Input;
        const { visible } = this.state;
        const columns = [
            {
                title: '订单号',
                dataIndex: 'orderId',
                key: 'orderId',
                width: 200,
            },
            {
                title: '订单状态',
                dataIndex: 'status',
                key: 'status',
                width: 400,
                align: 'center',
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                已下单
                            </Tag>
                        )
                    }
                    if (record.status === 16) {
                        return (
                            <Tag color="#bababa" key={record.status}>
                                已关闭
                            </Tag>
                        )
                    }
                    if (record.status === 128) {
                        return (
                            <Tag color="#0cef7d" key={record.status}>
                                已支付
                            </Tag>
                        )
                    }
                    if (record.status === 512) {
                        return (
                            <Tag color="#4f4f4f" key={record.status}>
                                已退款
                            </Tag>
                        )
                    }
                    if (record.status === 525) {
                        return (
                            <Tag color="#868686" key={record.status}>
                                部分退款
                            </Tag>
                        )
                    }
                    if (record.status === 1024) {
                        return (
                            <Tag color="#0693e3" key={record.status}>
                                已完成
                            </Tag>
                        )
                    }
                }
            },
            {
                title: '类型',
                dataIndex: 'title',
                key: 'title',
                width: 300,
            },
            {
                title: '支付单号',
                dataIndex: 'paymentNo',
                key: 'paymentNo',
                width: 300,
            },
            {
                title: '金额',
                dataIndex: 'amount',
                key: 'amount',
                width: 300,
            },
            {
                title: '支付时间',
                dataIndex: 'payTime',
                key: 'payTime',
                width: 300,
            },
            {
                title: '支付方式',
                dataIndex: 'method',
                key: 'method',
                width: 300,
                render: (text, record) => {
                    if (record.method === 257) {
                        return (
                            <Tag color="green" key={record.method}>
                                微信支付
                            </Tag>
                        )
                    }
                    if (record.method === 258) {
                        return (
                            <Tag color="blue" key={record.method}>
                                支付宝
                            </Tag>
                        )
                    }
                    if (record.method === 16) {
                        return (
                            <Tag color="gold" key={record.method}>
                                现金
                            </Tag>
                        )
                    }
                    return (
                        <Tag color="red" key={record.method}>
                            未知
                        </Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)} >查看</a>
                    </span>
                ),
            },
        ];
        return (
            <>
                <Spin tip="加载中..." spinning={loggingIn}>
                    <Search placeholder="请输入订单号或支付流水号"
                        onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                        enterButton style={{ width: 330, marginBottom: 15 }}
                    />
                    <Table columns={columns} dataSource={this.state.list}
                        pagination={{
                            showSizeChanger: true,
                            onShowSizeChange: (current, pageSize) => {
                                this.handlePaging(this.state.keyword, 1, pageSize);
                            },
                            pageSizeOptions: ['10', '25', '50', '100', '200'],
                            pageSize: this.state.pagesize,
                            current: this.state.page,
                            total: this.state.totalCount,
                            onChange: (page, pageSize) => {
                                this.handlePaging(this.state.keyword, page, pageSize);
                            }
                        }}
                    />
                    <Drawer
                        title="订单详情"
                        width={1024}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        destroyOnClose={true}
                    >
                        <GetOrder order={this.state.selected}
                            handlePaging={this.handlePaging.bind(this)}
                        />
                    </Drawer>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}
const connectedOredersPage = connect(mapState, null)(OrdersPage);
export { connectedOredersPage as OrdersPage }