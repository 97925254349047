import React, { Component } from 'react';
import { Layout, Icon, Spin, Alert, Dropdown, message, Menu, Divider } from 'antd';
import '@/layouts/Main.css';
import Menus from '../components/MainMenu';
import Breadcrumb from '../components/BreadcrumbMap';
import { userActions } from '../actions'
import { SubRoute } from '../components/Routings'

const { Header, Content, Footer, Sider } = Layout;

class Main extends React.Component {
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleLogout = () => {
        userActions.logout();
    }

    handlMenuSelect = e => {
        const { key } = e;

        this.setState({
            ...this.state,
            currentMenu: key
        })
    }

    render() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        return (

            <Layout id='layout-main' className="layout-main">

                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                    <div className="logo" ></div>
                    <Menus {...this.props} onSelect={this.handlMenuSelect} />
                </Sider>
                <Layout>
                    <Header style={{ background: '#fff', padding: 0 }}>
                        {/* <span style={{ float: "right", fontSize: 16, marginRight: 45 }}>
                            欢迎用户&nbsp;{user.name}&nbsp;登录！！！ &nbsp;
                        <a onClick={this.handleLogout}>注销</a>
                        </span> */}
                       <span style={{ float: "right", fontSize: 16, marginRight: 45 }}>
                           
                        <a onClick={this.handleLogout}>注销</a>
                        </span>
                        <Icon
                            className="trigger"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                        />
                    </Header>

                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb currentMenu={this.state.currentMenu} />
                        <div className="content">
                            <SubRoute {...this.props} />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>快拍 ©2019 Created by 成都百奥达科技有限公司</Footer>
                </Layout>
            </Layout>
        );
    }
}

export default Main;