import React from 'react';
import {
    Button,
    Tree,
    message
} from 'antd'
import { terminalManagenmentService } from '../../services';

const { TreeNode } = Tree;

class PhototypesPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phototypes: [],
            checkedKeys: {},
            selectedPhototypes: [],
            expandedKeys: ['0'],
            selectedKeys: []
        }
    }
    async componentDidMount() {
        await this.Addphototypes();
        const selectedPhototypes = [];
        const { phototypes } = this.state;
        const keys = Object.keys(phototypes);
        keys.map(key => {
            phototypes[key].filter(photo => photo.choosephoto)
                .map(photo => {
                    selectedPhototypes.push(photo.photoid)
                });
        })
        this.setState({
            checkedKeys: selectedPhototypes
        })
    }
    //循环遍历图片类型节点
    renderTreeNodes = phototypes =>
        Object.keys(phototypes)
            .map(key => {
                return (
                    <TreeNode title={key} key={key} >
                        {
                            phototypes[key].map(type =>
                                <TreeNode
                                    disabled={!type.enabled}
                                    title={type.photoname}
                                    key={type.photoid} />
                            )
                        }
                    </TreeNode>
                );
            });
    //更改图片类型节点
    changePhototypes = async () => {
        try {
            const { selectedKeys } = this.state;
            await terminalManagenmentService
                .changephototypes(this.props.terminal.uid,
                    selectedKeys.filter(key => key.indexOf('-') > 7))
                .then(response => {
                    if (response) {
                        let changedata = response.data;
                        if (!changedata.success) {
                            message.error(changedata.message)
                        }
                        else {
                            message.success('修改成功')
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    //获取终端机照片类型信息
    async Addphototypes() {
        try {
            await terminalManagenmentService.phototypes(this.props.terminal.uid)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        this.setState({
                            phototypes: result.data
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = (checkedKeys, e) => {
        const checked = [...checkedKeys, ...e.halfCheckedKeys];
        this.setState({
            selectedKeys: checked,
            checkedKeys: checkedKeys
        });
    };

    render() {
        return (
            <>
                <Tree
                    checkable
                    selectable={false}
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={this.state.autoExpandParent}
                    checkedKeys={this.state.checkedKeys}
                    onCheck={this.onCheck}
                >
                    <TreeNode key={0} title='所有类型'>
                        {this.renderTreeNodes(this.state.phototypes)}
                    </TreeNode>
                </Tree>
                <Button
                    type="primary"
                    onClick={() => this.changePhototypes()}
                    style={{ float: 'right' }}
                >提交</Button>
            </>
        )
    }
}
export default PhototypesPage