import axios from 'axios';
import { async } from 'q';

const hosts = {
    createTerminal: '/api/terminal/create',
    terminals: '/api/terminal',
    terminal: '/api/terminal/info',
    erase: '/api/terminal/erase',
    phototypes: '/api/terminal/phototypes',
    changephototypes: '/api/terminal/phototypes',
    pricelist: '/api/terminal/pricelist',
    changepricelist: '/api/terminal/pricelist',
    deleteTerminal: '/api/terminal/delete',
    TerminalConfig: '/api/terminal/config',
    changeTerminalConfig: '/api/terminal/config',
    change: '/api/terminal/change',
    deploy: '/api/terminal/deploy',
    updatekey: '/api/terminal/updatekey',
    undodelete: "/api/terminal/undodelete"
}

export const terminalManagenmentService = {
    createTerminal,
    terminals,
    terminal,
    erase,
    phototypes,
    changephototypes,
    pricelist,
    changepricelist,
    deleteTerminal,
    TerminalConfig,
    changeTerminalConfig,
    change,
    deploy,
    updatekey,
    undodelete
}

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}

//撤销删除
async function undodelete(ids, status, pwd) {
    const response = await mypost(hosts.undodelete, {
        ids: ids,
        status: status,
        pwd: pwd
    })
    return response;
}

//更改终端设备
async function change(tid,marketing, name, status, remark, gpsLng, gpsLag, driverbusiness) {
    const response=await mypost(hosts.change, {
        tid: tid,
        marketing:marketing,
        name: name,
        status: status,
        remark: remark,
        gpsLag: gpsLag,
        gpsLng: gpsLng,
        driverbusiness: driverbusiness
    })
    return response;
}
//添加终端
async function createTerminal(terminalId,marketing, name, remark, gpsLng, gpsLag, driverbusiness) {
    const response=await  mypost(hosts.createTerminal, {
        terminalId: terminalId,
        marketing:marketing,
        name: name,
        remark: remark,
        gpsLng: gpsLng,
        gpsLag: gpsLag,
        driverbusiness: driverbusiness
    })
    return response;
}

//获取终端列表
async function terminals(search, status, pageindex, pagesize) {
    if (isNaN(status)) {
        status = null;
    }
    const response = await myget(hosts.terminals, {
        params: {
            search: search,
            status: status,
            pageindex: pageindex,
            pagesize: pagesize
        }
    })
    return response;
}

//获取单个设备详细信息
async function terminal(terminalId) {
    const response=await axios.get(hosts.terminal,{
        params:{
            terminalId:terminalId
        }
    })
    return response;
}

//磁盘删除设备
async function erase(password, deleteIds) {
    const response = await mypost(hosts.erase, {
        password: password,
        deleteIds: deleteIds
    })
    return response;
}

//获取设备照片类型
async function phototypes(id) {
    if (!id) {
        id = null;
    }
    const response = await myget(hosts.phototypes, {
        params: {
            id: id
        }
    })
    return response;
}

//更改设备照片类型
async function changephototypes(terminalid, phototypeids) {
    const response = mypost(hosts.changephototypes, {
        terminalid: terminalid,
        phototypeids: phototypeids
    })
    return response;
}

//获取指定设备价目表
async function pricelist(id) {
    if (!id) {
        id = null;
    }

    const response = await myget(hosts.pricelist, {
        params: {
            id: id
        }
    });
    return response;
}

//修改指定设备价目表
async function changepricelist(terminalid, pricelist) {
    console.log("priceList", JSON.stringify({
        terminalid: terminalid,
        pricelist: pricelist,
    }));

    const response = await mypost(hosts.changepricelist, {
        terminalid: terminalid,
        pricelist: pricelist,
    })
    return response;
}
//逻辑删除设备信息
async function deleteTerminal(ids) {
    const response = await mypost(hosts.deleteTerminal, ids)
    return response;
}
//查看设备配置信息
async function TerminalConfig(id) {
    if (!id) {
        id = null;
    }
    const response = await myget(hosts.TerminalConfig, {
        params: {
            id: id
        }
    })
    return response;
}

//修改设备配置信息
async function changeTerminalConfig(terminalid, tconfig) {
    const configs = [];
    const keys = Object.keys(tconfig)
    keys.map(key => {
        tconfig[key].map(item => configs.push(item));
    });
    console.log(configs);

    const response = await mypost(hosts.changeTerminalConfig, {
        terminalid: terminalid,
        tconfig: configs
    })
    return response;
}

//下载设备配置文件
async function deploy(Id, Isnew) {
    const response = await myget(hosts.deploy, {
        params: {
            Id: Id,
            Isnew: Isnew
        }
    });
    if (response) {
        if (response.headers['content-disposition']) {
            if (response.request.responseURL) {
                window.open(response.request.responseURL);
            }
        }
    }
    return response;
}
//更新设备密钥
async function updatekey(id, password) {
    const response = await mypost(hosts.updatekey, {
        id: id,
        password: password
    })
    return response;
}