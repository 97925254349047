import React from 'react';
import { marketingService } from '../../services';
import { Form, Button, Input, message, Spin } from 'antd';

class ChangeMarketingUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            changeing: false
        }
    }
    updateIntoUser = e => {
        e.preventDefault();
        try {
            const { form } = this.props;
            const marketingUserid = this.props.marketingUser.id;
            form.validateFields(async (err, values) => {
                if (!err) {
                    this.setState({
                        changeing: true
                    })
                    const { marketingUserName, bankcardnumber, phonenumber, wechat, office } = values;
                    await marketingService
                        .updateIntoUser(marketingUserid, marketingUserName, bankcardnumber, phonenumber, wechat, office)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        changeing: false
                                    })
                                }
                                else {
                                    message.success("修改成功")
                                    this.setState({
                                        changeing: false
                                    })
                                    this.props.handleMarketingUserCancel();
                                    this.props.onClose();
                                    this.props.handlePaging(null, 1, 10);
                                }
                            }
                        })
                }
            })
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const formItemLayout = {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const { changeing } = this.state;
        const { marketingUser } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin tip={"修改中..."} spinning={changeing}>
                <Form  {...formItemLayout} onSubmit={e => this.updateIntoUser(e)} >
                    <Form.Item label="用户名" >
                        {getFieldDecorator('marketingUserName', {
                            initialValue: marketingUser.marketingusername,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入分成用户名',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="银行卡号">
                        {getFieldDecorator('bankcardnumber', {
                            initialValue: marketingUser.bankcardnumber,
                            rules: [
                                {
                                    required: false,
                                    pattern: new RegExp(/^[0-9]*$/),
                                    message: '请输入数字'
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="手机号">
                        {getFieldDecorator('phonenumber', {
                            initialValue: marketingUser.phonenumber,
                            rules: [
                                {
                                    required: false,
                                    pattern: new RegExp(/^[0-9]*$/),
                                    message: '请输入数字'
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="微信号">
                        {getFieldDecorator('wechat', {
                            initialValue: marketingUser.wechat,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="描述">
                        {getFieldDecorator('office', {
                            initialValue: marketingUser.office,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 18, offset: 5 }}>
                        <Button
                            htmlType="submit"
                            style={{ float: "right" }}
                            type="primary"
                        >
                            确认
        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}
const ChangeMarketingUserForm = Form.create()(ChangeMarketingUser)
export { ChangeMarketingUserForm as ChangeMarketingUser }