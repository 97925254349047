import {
    Table,
    Tag,
    Spin,
    Input,
    Button,
    Form,
    message,
    Modal,
    Select
} from 'antd';
import React from 'react';
import { marketingService } from '../../services';
import { EnableMarketingUser } from '../MarketingUserPage'

class obtainDeletedMarketingUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            statusValue: 0,
            loading: false,
            visibleModal: false,
            visibleenableModal: false
        }
    }
    //显示Modal事件
    showModal = (record) => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
            record: record
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //显示enableModal事件
    showenableModal = (record) => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleenableModal: true,
            record: record
        });
    };
    //enableModal关闭事件
    handleenableCancel = () => {
        this.setState({
            visibleenableModal: false,
        });
    };
    //查询用户信息，list分页，搜索功能
    handlePaging(keyword, statusValue, page, size) {
        this.setState({
            ...this.state,
            loading: true
        })

        marketingService.intoUser(keyword, statusValue, page - 1, size)
            .then(response => {
                if (response) {
                    var result = response.data;
                    var list = result.data;
                    list.map((item, index) => item['key'] = index);
                    this.setState({
                        page: page,
                        keyword: keyword,
                        pagesize: size,
                        list: list,
                        totalCount: result.count,
                        loading: false,
                    })
                }
            })
    }

    //调取role.servicers allroles()方法，即调取所有用户信息
    componentDidMount() {
        const { statusValue, page, pagesize, keyword } = this.state;
        this.handlePaging(keyword, statusValue, page, pagesize);
        this.setState({
            loading: true
        })
    }

    //删除磁盘分成用户信息
    onClickDeletedMarketingUser = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { pwd, remark } = values;
                    await marketingService.eraseUser(this.state.record.id, pwd, remark)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                }
                                else {
                                    message.success("擦除成功")
                                    this.handleCancel();
                                    this.handlePaging(null,
                                        this.state.statusValue,
                                        this.state.page,
                                        this.state.pagesize);
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        })
    }

    render() {
        const { loading } = this.state;
        const { Search } = Input;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        const columns = [
            {
                title: '分成用户姓名',
                dataIndex: 'marketingusername',
                key: 'marketingusername',
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                  </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
              </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showenableModal(record)}>启用</a>&nbsp;
                        <a onClick={() => this.showModal(record)}>删除</a>
                    </span>
                ),
            },
        ];
        return (
            <Spin tip="加载中..." spinning={loading}>
                <Search placeholder="请输入分成用户名"
                    onSearch={value => this.handlePaging(value, this.state.statusValue, 1, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Table columns={columns} dataSource={this.state.list}
                    size="middle"
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pagesize) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, this.state.page, pagesize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pageSize);
                        }
                    }
                    }
                />
                <Modal
                    title="启用分成用户"
                    visible={this.state.visibleenableModal}
                    onCancel={this.handleenableCancel}
                    footer={null}
                >
                    <EnableMarketingUser />
                </Modal>
                <Modal
                    title="擦除分成用户"
                    visible={this.state.visibleModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.onClickDeletedMarketingUser}>
                        <Form.Item>
                            {
                                getFieldDecorator('pwd', {
                                    rules: [{
                                        required: true,
                                        message: '请输入当前管理员密码!'
                                    }],
                                })(
                                    <Input.Password placeholder="请输入当前管理员密码" />)}
                        </Form.Item>
                        <Form.Item>
                            {
                                getFieldDecorator('remark', {
                                    rules: [{
                                        required: true,
                                        message: '请输入禁用该用户原因!'
                                    }],
                                })(
                                    <TextArea style={{ resize: "none" }}
                                        placeholder="请输入需要备注的信息"
                                        autosize={{ minRows: 3, maxRows: 3 }}
                                    />)
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                htmlType="submit">
                                确认
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        )
    }
}
const obtainDeletedMarketingUserForm = Form.create()(obtainDeletedMarketingUser)
export { obtainDeletedMarketingUserForm as obtainDeletedMarketingUser }