import React from 'react';
import {
    Form,
    Input,
    message,
    Spin,
    Button,
    Radio
} from 'antd';
import { terminalManagenmentService } from '../../services/terminalManagenment.service';

class AddTreminalPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            random: 0
        }
    }

    //添加终端机信息
    AddTreminal = e => {
        e.preventDefault();
        try {
            const { form, action } = this.props;
            try {
                form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        const { terminalId, name, remark, gpsLng, gpsLag, driverbusiness } = values;
                        await terminalManagenmentService.createTerminal(terminalId, name, remark, gpsLng, gpsLag, driverbusiness)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    if (!result.success) {
                                        message.error(result.message)
                                        this.setState({
                                            adding: false
                                        })
                                    }
                                    else {
                                        message.success('添加成功');
                                        this.setState({
                                            adding: false
                                        })
                                        this.props.handlePaging(null, 1, this.state.pagesize);
                                        this.handleCancel();
                                    }
                                }
                            })
                    }
                })
            }
            catch (ex) {
                message.error('请检查网络出现问题')
                this.setState({
                    adding: false
                })
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    render() {
        const { TextArea } = Input;

        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };
        return (

            <Form onSubmit={e => this.AddTreminal(e)}>
                <Form.Item label='授权驾驶证接口访问'>
                    {
                        getFieldDecorator('driverbusiness', {
                            rules: [
                                {
                                    required: true,
                                    message: '请授权驾驶证接口访问',
                                }
                            ]
                        })(<Radio.Group>
                            <Radio.Button defaultChecked={true}
                                value={true}>准许访问</Radio.Button>
                            <Radio.Button value={false}>禁止访问</Radio.Button>
                        </Radio.Group>)
                    }
                </Form.Item>
                <Form.Item label='设备编号'>
                    {
                        getFieldDecorator('terminalId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入设备编号',
                                }
                            ]
                        })(<Input placeholder='请输入设备编号' />)
                    }
                </Form.Item>
                <Form.Item label='设备名'>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入设备名',
                                }
                            ]
                        })(<Input placeholder='请输入设备名' />)
                    }
                </Form.Item>
                <Form.Item label='经度'>
                    {
                        getFieldDecorator('gpsLng', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入经度'
                                }
                            ]
                        })(<Input placeholder='请输入经度' />)
                    }
                </Form.Item>
                <Form.Item label='纬度'>
                    {
                        getFieldDecorator('gpsLag', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入纬度'
                                }
                            ]
                        })(<Input placeholder='请输入纬度' />)
                    }
                </Form.Item>
                <Form.Item label='备注'>
                    {
                        getFieldDecorator('remark', {
                            rules: [{
                                required: false,
                            }]
                        })(
                            <TextArea style={{ resize: "none" }}
                                placeholder="请输入需要备注的信息"
                                autosize={{ minRows: 3, maxRows: 3 }}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item wrapperCol={{ span: 18, offset: 5 }}>
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        确认
            </Button>
                </Form.Item>
            </Form>

        )
    }
}

const AddTreminalPageForm = Form.create()(AddTreminalPage);
export { AddTreminalPageForm as AddTreminalPage }