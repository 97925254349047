import React from 'react';
import { Button, Modal, message, Form, Input, Spin } from 'antd'
import { orderService } from '../../services';

class CanceSettlement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: props.order,
            cancelSuccessfulStatus: false,
        }
    }
    //取消已完成订单状态
    cancelSettlementSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { password } = values;
                    this.setState({
                        cancelSuccessfulStatus: true
                    })
                    await orderService.cancelSettlement(this.state.order, password)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        cancelSuccessfulStatus: false
                                    })
                                }
                                else {
                                    this.setState({
                                        cancelSuccessfulStatus: false,
                                    })
                                    this.props.handlePaging(null, 1, this.state.pagesize);
                                    message.success('取消订单成功', 5)
                                    this.props.handleSettlementCancel();
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { cancelSuccessfulStatus } = this.state;
        return (

            <Form layout="vertical" onSubmit={e => this.cancelSettlementSubmit(e)}>
                <Spin tip="加载中" spinning={cancelSuccessfulStatus}>
                    <Form.Item>
                        {
                            getFieldDecorator('password', {
                                rules: [{
                                    required: true,
                                    message: '请输入当前管理员密码!'
                                }],
                            })(
                                <Input.Password placeholder="请输入当前管理员密码" />)}
                    </Form.Item>
                    <Form.Item>
                        <Button key='settle' htmlType="submit"
                            type="primary" style={{ float: "right" }}>
                            确认
                    </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}
const CanceSettlementForm = Form.create()(CanceSettlement)
export default CanceSettlementForm