import {
    Table,
    Tag,
    Drawer,
    Spin,
    Input,
    Modal,
    Button,
    Form,
    Select,
    message,
    Steps
} from 'antd';
import React from 'react';
import { userService } from '../../services';
import { connect } from 'react-redux';
import UserModule from './UserModule';
import { CreatUserRoles } from './CreatUserRoles'
const { Step } = Steps

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            visibleDrawer: false,
            loggingIn: true,
            refunding: false,
            visibleModal: false,
            confirmDirty: false,
            autoCompleteResult: [],
        }
    }
    //显示Modal事件
    showModal = user => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
            selected: user,
        });
    };
    //Modal关闭事件
    handleOk = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //Drawer关闭事件
    hanleCancel = () => {
        this.setState({
            visibleDrawer: false,
        });
    };
    //显示Drawer事件
    showDrawer = user => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: user,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    //创建用户点击事件
    creatUserSubmit = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { userName, name, password } = values;
                    try {
                        this.setState({ refunding: true });
                        await userService.createUser(userName, name, password, '')
                            .then(response => {
                                let result = response.data;
                                if (result) {
                                    if (!result.success) {
                                        message.error(result.message);
                                        this.setState({
                                            refunding: false,
                                        })
                                        this.handlePaging(null, 1, this.state.pagesize);
                                    }
                                    else {
                                        message.success('添加成功');
                                        this.handlePaging(null, 1, this.state.pagesize);

                                        this.setState({
                                            visibleModal: false,
                                            refunding: false,
                                        })
                                    }
                                }
                            })
                    }
                    catch (ex) {
                        message.error('保存失败', 5);
                    }
                }
                catch (ex) {
                    alert(ex);
                }
            }
        });
    }
    //查询用户信息，list分页，搜索功能
    async handlePaging(keyword, page, size) {
        this.setState({
            ...this.state,
            loggingIn: true
        })
        const { form } = this.props;
        const statusValue = form.getFieldValue('statusValue');
        const response = await userService.allusers(keyword, statusValue, page - 1, size);

        if (response) {
            try {
                var result = response.data;
                var list = result.data;
                list.map((item, index) => item['key'] = index);
                this.setState({
                    page: page,
                    keyword: keyword,
                    pagesize: size,
                    list: list,
                    totalCount: result.count,
                    loggingIn: false,
                })
            }
            catch (ex) {
                alert(ex)
            }
        }
    }

    //调取user.servicers allusers()方法，即调取所有用户信息
    componentDidMount() {
        const { pagesize, keyword } = this.state;
        this.handlePaging(keyword, 1, pagesize);
        this.setState({
            loggingIn: true
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { loggingIn } = this.state;
        const { refunding } = this.state;

        const { Search } = Input;
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const columns = [
            {
                title: '用户名',
                dataIndex: 'userName',
                key: 'userName',
                width: 400
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                width: 400
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',

                render: (text, record) => {
                    if (record.status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                  </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
              </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: 400,
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },
        ];
        return (
            <Spin tip="加载中..." spinning={loggingIn}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('statusValue', {
                            initialValue: 'all',
                            rules: [
                                {
                                    required: false,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select placeholder="请选择"
                                style={{ width: 120 }}
                            >
                                <Option value='all'>全部</Option>
                                <Option value={1}>启用</Option>
                                <Option value={0}>禁用</Option>
                                <Option value={2}>删除</Option>
                            </Select>)}
                    </Form.Item>
                </Form>
                <Search placeholder="请输入姓名"
                    onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={this.showModal}>添加用户</Button>
                <Table columns={columns} dataSource={this.state.list}
                    pagination={{
                        showSizeChanger: true,//是否可以改变pagesize
                        onShowSizeChange: (current, pagesize, page) => {
                            this.handlePaging(this.state.keyword, this.state.statusValue, page, pagesize);
                        },//pagesize 变化的回调
                        pageSizeOptions: ['10', '25', '50', '100', '200'],//指定每页可以显示多少条
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, page, pageSize);
                        }
                    }}
                />
                <Drawer
                    title="用户详情"
                    width={1024}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <UserModule user={this.state.selected}
                        handlePaging={this.handlePaging.bind(this)}
                        onClose={this.onClose.bind(this)} />
                </Drawer>
                <div>
                    <Modal
                        title="添加用户"
                        visible={this.state.visibleModal}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}

          >
            <Spin tip="加载中..." spinning={refunding}>
              <Form {...formItemLayout} onSubmit={this.creatUserSubmit}>
                <Form.Item label="用户名">
                  {getFieldDecorator('userName', {
                    rules: [
                      {
                        required: true,
                        message: '请输入用户',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="姓名">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: '请输入姓名',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="密码">
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: '请输入密码',
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                {/* <CreatUserRoles user={this.state.selected}/> */}
                <Form.Item wrapperCol={{ span: 16, offset: 5 }}>
                  <Button
                    style={{ float: "right"}}
                    type="primary"
                    htmlType="submit"
                  >
                    确认添加
                </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Modal>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}
const CreatUsers = Form.create()(Users);
const connectedUserPage = connect(mapState, null)(CreatUsers)
export { connectedUserPage as UsersPage }