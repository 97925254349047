import { accountService } from '../services';
import { userConstants } from '../constants';
import { alertActions } from './alert.actions'
import {history} from '../utils'

export const userActions = {
    login,
    logout,
    menus,
};
function login(username, password) {
    return dispatch => {
     
        dispatch(request({ username }));

        accountService.login(username, password)
            .then(
                response => {
                    var result = response.data;
                    if (result.success) {
                        sessionStorage.setItem("user", JSON.stringify(result.data));
                        dispatch(success(result.data));
                        history.push('/');
                    } 
                    else {
                        let error = result.message;
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));                                       
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    accountService.logout();
    sessionStorage.removeItem('user');
    history.push('/');
    return { type: userConstants.LOGOUT };
}
function menus(){
    accountService.menus();
}