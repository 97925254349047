import React from 'react';
import { menuService } from '../../services';
import { ChangeMenu } from '../../pages/MenusPage'
import { connect } from 'react-redux'
import {
    Descriptions,
    Tag,
    Modal,
    Button,
    message,
    Spin,
} from 'antd';

class MenuModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            data: {},
            delecting: false,
            changeMenuVisible: false
        }
    }

    //显示修改菜单modal事件
    showChangeMenuModal = () => {
        this.setState({
            changeMenuVisible: true,
        })
    }
    //关闭添加菜单配置modal事件
    handleChangeMenuCancel = () => {
        this.setState({
            changeMenuVisible: false
        })
    }
    deleteMenuSunbimit = async () => {
        try {
            this.setState({
                delecting: true
            })

            await menuService.deleteMenu([this.state.menu.id])
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                            message.error(result.message)
                        }
                        else {
                            message.success('删除成功')
                            this.setState({
                                delecting: false
                            })
                            this.props.onClose();
                            this.props.handlePaging();
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    renderKey(key) {
        if (key === 'icon') {
            return '图标编号';
        }
        if (key === 'url') {
            return '菜单url';
        }
        if (key === 'parentName') {
            return '父级菜单';
        }
        if (key === 'name') {
            return '菜单名';
        }
        if (key === 'status') {
            return '状态';
        }
        return key;
    }
    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 0:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        return value;
    }
    render() {
        const { menu } = this.state;
        const { delecting } = this.state;
        return (
            <Spin tip="删除中" spinning={delecting}>
                <Button type="danger"
                    onClick={this.deleteMenuSunbimit}
                    style={{ float: "right" }}
                >删除菜单</Button>
                <Button type="ghost"
                    onClick={this.showChangeMenuModal}
                    style={{ float: "right", marginRight: 15, marginBottom: 10 }}
                >
                    更改菜单信息
                        </Button>
                <Descriptions bordered title="用户信息" border>
                    {
                        Object.keys(menu).filter(key => {
                            if (key === 'id') return false;
                            if (key === 'key') return false;
                            return true;
                        }).map(key =>
                            <Descriptions.Item key={key}
                                label={this.renderKey(key)}
                            >
                                {this.renderValue(key, menu[key])}

                            </Descriptions.Item>
                        )
                    }
                </Descriptions>
                <Modal
                    title='修改菜单信息'
                    visible={this.state.changeMenuVisible}
                    onCancel={() => this.handleChangeMenuCancel()}
                    footer={null}
                >
                    <ChangeMenu menu={this.props.menu}
                        handlePaging={this.props.handlePaging.bind(this)}
                        handleChangeMenuCancel={this.handleChangeMenuCancel.bind(this)}
                        menunodelist={this.props.menunodelist}
                    />
                </Modal>
            </Spin>
        )
    }
}
export { MenuModule as MenuModule }