import React from 'react';
import {
    Table,
    Tag,
    Drawer,
    Spin,
    Input,
    Modal,
    Button,
    Form,
    Select,
    message
} from 'antd';
import { roleService } from '../../services';
import { connect } from 'react-redux';
import { RoleModule } from '../RolesPage'

class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            keyword: null,
            totalCount: 0,
            loggingIn: true,
            visibleModal: false,
            confirmDirty: false,
            autoCompleteResult: [],
            visibleDrawer: false,
        }
    }
    //表单提交事件
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    //显示Modal事件
    showModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
        });
    };
    //Modal关闭事件
    handleOk = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //Drawer关闭事件
    hanleCancel = () => {
        this.setState({
            visibleDrawer: false,
        });
    };
    //显示Drawer事件
    showDrawer = role => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: role,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }

    CreatRoleSubmit = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { roleName } = values;
                    try {
                        await roleService
                            .createRole(roleName)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    if (!result.success) {
                                        message.error(result.message);
                                    }
                                    else {
                                        message.success('添加成功');
                                        this.setState({
                                            visibleModal: false
                                        })
                                        this.handlePaging();
                                    }
                                }
                            })
                    }
                    catch (ex) {
                        message.error('保存失败', 5);
                    }
                }
                catch (ex) {
                    alert(ex);
                }
            }
        });
    }
    //查询用户信息，list分页，搜索功能
    handlePaging(keyword, page, size) {
        this.setState({
            ...this.state,
            loggingIn: true
        })
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const { statusValue } = values;
            roleService.roles(keyword, statusValue, page - 1, size)
                .then(response => {
                    if (response) {
                        var result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        this.setState({
                            page: page,
                            keyword: keyword,
                            pagesize: size,
                            list: list,
                            totalCount: result.count,
                            loggingIn: false,
                        })
                    }
                })
        });
    }
    //调取user.servicers allusers()方法，即调取所有用户信息
    componentDidMount() {
        const { pagesize, keyword } = this.state;
        this.handlePaging(keyword, 1, pagesize);
        this.setState({
            loggingIn: true
        })
    }
    render() {
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const { getFieldDecorator } = this.props.form;
        const { loggingIn } = this.state;

        const { Search } = Input;
        const { Option } = Select;
        const columns = [
            {
                title: '角色姓名',
                dataIndex: 'roleName',
                key: 'roleName',
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.Status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                  </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                </Tag>
                        )
                    }
                    if (record.status === 2) {
                        return (
                            <Tag color="red" key={record.status}>
                                删除
              </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },
        ];

        const data = this.state.list;
        return (
            <Spin tip="加载中..." spinning={loggingIn}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('statusValue', {
                            initialValue: 'all',
                            rules: [
                                {
                                    required: false,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select placeholder="请选择"
                                style={{ width: 120 }}
                            >
                                <Option value='all'>全部</Option>
                                <Option value={1}>启用</Option>
                                <Option value={0}>禁用</Option>
                                <Option value={2}>删除</Option>
                            </Select>)}
                    </Form.Item>
                </Form>
                <Search placeholder="请输入角色名"
                    onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Button type="primary"
                    style={{ float: "right" }}
                    onClick={this.showModal}>添加角色</Button>
                <Table columns={columns} dataSource={data}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (current, pagesize) => {
                            this.handlePaging(this.state.keyword, 1, pagesize);
                        },
                        pageSizeOptions: ['10', '25', '50', '100', '200'],
                        pageSize: this.state.pagesize,
                        current: this.state.page,
                        total: this.state.totalCount,
                        onChange: (page, pageSize) => {
                            this.handlePaging(this.state.keyword, page, pageSize);
                        }
                    }
                    }
                />

                <Drawer
                    title="用户详情"
                    width={1024}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <RoleModule role={this.state.selected}
                        handlePaging={this.handlePaging.bind(this)}
                        onClose={this.onClose.bind(this)}
                    />
                </Drawer>

                <Modal
                    title="添加角色"
                    visible={this.state.visibleModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form  {...formItemLayout} onSubmit={this.CreatRoleSubmit}>
                        <Form.Item label="角色名">
                            {getFieldDecorator('roleName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入角色名',
                                    },
                                ],
                            })(<Input />)}

                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 16, offset: 5 }}>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                htmlType="submit">
                                确认添加
                </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        )
    }
}
const CreatRole = Form.create()(Roles);
export { CreatRole as RolesPage }