import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import terminalSetting from './terminalSetting.reducer';
import setparameters from './setparameters.reducer';

const rootReducer = combineReducers({
    authentication,
    users,
    alert,
    terminalSetting,
    setparameters
});

export default rootReducer;