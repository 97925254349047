import React from 'react';
import { userService } from '../../services';
import { connect } from 'react-redux'
import {
    Descriptions,
    Table,
    Tag,
    Modal,
    Button,
    Input,
    message,
    Form,
    Spin,
    Radio,
    Checkbox,
    Row,
    Col,
    Divider
} from 'antd';
import { ChangeUser } from './ChangeUser'

class UserModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.user.id,
            data: {},
            deleterefunding: false,
            body: {},
            user: [],
            userRoles: [],
            loading: false,
            createing: false,
            changeUserVisible: false
        }
    }
    renderKey(key) {
        if (key === 'avatar') {
            return '头像';
        }
        if (key === 'createTime') {
            return '创建时间';
        }
        if (key === 'status') {
            return '状态';
        }
        if (key === 'userName') {
            return '用户名';
        }
        if (key === 'name') {
            return '姓名';
        }
        return key;
    }
    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 0:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        return value;
    }
    //删除用户
    deleteUserSubmit = async () => {
        try {
            this.setState({ deleterefunding: true });
            await userService.deleteUser([this.state.userId])
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                            message.error(result.message)
                        }
                        else {
                            message.success('删除成功');
                            this.setState({
                                deleterefunding: false
                            })
                            this.props.handlePaging(null, 1, this.state.pagesize);
                            this.props.onClose();
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    //页面加载所需信息
    componentDidMount() {
        this.userInfo();
        this.userRoles();
    }
    //加载用户个人信息
    userInfo() {
        userService.userInfo(this.state.userId)
            .then(response => {
                if (response) {
                    var result = response.data;
                    this.setState({
                        user: result.data
                    })
                }
            })
    }
    //获取用户与角色的关联
    userRoles() {
        this.setState({
            loading: true
        })
        userService.userRoles(this.state.userId)
            .then(response => {
                if (response) {
                    let result = response.data;
                    this.setState({
                        userRoles: result.data,
                        loading: false
                    })
                }
            })
    }
    //更改用户权限
    checkedUserRights = e => {
        e.preventDefault();

        const { form } = this.props;
        try {
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    this.setState({
                        createing: true
                    })
                    const { roles } = values;
                    await userService.changeUserRights(this.state.userId, roles)
                        .then(response => {
                            if (response) {
                                let isok = response.data;
                                if (!isok.success) {
                                    message.error(isok.message)
                                }
                                else {
                                    message.success('添加成功');
                                    this.setState({
                                        createing: false
                                    })
                                }
                            }
                        })
                }
            })
        }
        catch (ex) {
            message.error('保存失败', 5);
        }
    }

    //显示修改用户modal事件
    showChangeUserModal = () => {
        this.setState({
            changeUserVisible: true,
        })
    }
    //关闭添加设备配置modal事件
    handleChangeUserCancel = () => {        
        this.setState({
            changeUserVisible: false
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { deleterefunding } = this.state;
        const { user } = this.state;
        const { loading } = this.state;
        const { createing } = this.state;
        const roles = [];
        this.state.userRoles.filter(role => role.chooseRole)
            .map(role => roles.push(role.roleId));
        return (
            <Spin tip="添加中" spinning={createing}>
                <Spin tip="加载中" spinning={loading}>
                    <Spin tip="删除中..." spinning={deleterefunding}>
                        <Button type="danger"
                            user={this.state.user}
                            onClick={() => this.deleteUserSubmit()}
                            style={{ float: "right", marginRight: 15, marginBottom: 10 }}
                        >
                            删除用户
                        </Button>
                        <Button type="ghost"
                            onClick={this.showChangeUserModal}
                            style={{ float: "right", marginRight: 15, marginBottom: 10 }}
                        >
                            更改用户信息
                        </Button>
                        <Descriptions bordered title="用户信息" border>
                            {
                                Object.keys(user).filter(key => {
                                    if (key === 'id') return false;
                                    if (key === 'key') return false;
                                    if (key === 'avatar') return false;
                                    return true;
                                }).map(key =>
                                    <Descriptions.Item key={key}
                                        label={this.renderKey(key)}
                                    >
                                        {this.renderValue(key, user[key])}
                                    </Descriptions.Item>
                                )
                            }
                        </Descriptions>
                        <Modal
                            title='修改用户信息'
                            visible={this.state.changeUserVisible}
                            onCancel={() => this.handleChangeUserCancel()}
                            footer={null}
                        >
                            <ChangeUser user={this.props.user}
                                handlePaging={this.props.handlePaging.bind(this)}
                                handleChangeUserCancel={this.handleChangeUserCancel.bind(this)}
                            />
                        </Modal>
                        <Divider orientation="left">用户角色</Divider>
                        <Form onSubmit={e => this.checkedUserRights(e)}>
                            <Form.Item>
                                {getFieldDecorator('roles', {
                                    initialValue: roles,
                                })(
                                    <Checkbox.Group style={{ width: '100%' }}>
                                        <Row>
                                            {
                                                this.state.userRoles.map(role =>
                                                    <Col key={role.roleId}
                                                        span={8}
                                                    >
                                                        <Checkbox value={role.roleId}>
                                                            {role.roleName}
                                                        </Checkbox>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Checkbox.Group>,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit"
                                    key='confirm'
                                    type="primary"
                                    style={{ float: "right" }}
                                >
                                    确认
                        </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Spin>
            </Spin>
        )
    }
}
const UserModuleFrom = Form.create()(UserModule)
export default UserModuleFrom