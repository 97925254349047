import React from 'react';
import {
    Button,
    Table,
    Spin,
    message,
    Input,
    Tag,
    Form,
    Modal
} from 'antd';
import { marketingService, userService } from '../../services';

class DistributionLoginUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            search: null,
            visibleModal: false,
            refunding: false,
            distributioning: false
        }
    }
    componentDidMount = async () => {
        const { search } = this.state;
        await this.DistrbutionLoginUser(search);
        this.props.onRef(this)
    }

    //分配登陆用户
    async removeAssociation(record) {
        try {
            this.setState({
                distributioning: true
            })
            await marketingService
                .updateusermap(
                    record.id,
                    this.props.marketingUser.id, true)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                            message.error(result.message)
                            this.setState({
                                distributioning: false
                            })
                        }
                        else {
                            let name = record.loginName;
                            message.success("分配成功");
                            this.setState({
                                name: name,
                                distributioning: false
                            })
                            this.DistrbutionLoginUser();
                            this.props.handlePaging(null, 1, this.state.pagesize);
                            const { dataChangeCallBack } = this.props;
                            if (dataChangeCallBack) {
                                dataChangeCallBack(record);
                            }
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //创建用户点击事件
    creatUserSubmit = e => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { userName, name, password } = values;
                    this.setState({ refunding: true });
                    await userService.createUser(userName, name, password, '')
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    this.setState({
                                        refunding: false,
                                    })
                                }
                                else {
                                    message.success('添加成功');
                                    this.setState({
                                        visibleModal: false,
                                        refunding: false,
                                    })
                                    this.DistrbutionLoginUser()
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex);
                }
            }
        });
    }
    //获取登陆用户
    async DistrbutionLoginUser(search) {
        try {
            await marketingService.loginUser(search)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let list = result.data;
                        list.map((item, index) => item['key'] = index);
                        this.setState({
                            list: list
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //显示Modal事件
    showModal = user => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
            selected: user,
        });
    };

    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    render() {
        const { refunding, distributioning } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { Search } = Input;
        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        const columns = [
            {
                title: '用户名',
                dataIndex: 'loginName',
                key: 'loginName',
                width: "30%"
            },

            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                width: "30%"
            },
            {
                title: '已有分成用户',
                dataIndex: 'marketingNum',
                key: 'marketingNum',
                width: "20%"
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: "20%",
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.removeAssociation(record)}>分配该账号</a>
                    </span>
                ),
            },
        ];
        return (<>
            <Spin tip="分配中..." spinning={distributioning}>
                <Search placeholder="请输入登陆用户名"
                    onSearch={serach => this.DistrbutionLoginUser(serach)}
                    enterButton style={{ width: 330, marginBottom: 15 }}
                />
                <Button
                    icon="plus"
                    type="primary"
                    style={{ float: "right" }}
                    onClick={this.showModal}>添加用户</Button>
                <Table columns={columns} dataSource={this.state.list}
                    size="middle"
                    pagination={{ pageSize: 5 }}
                />
            </Spin>
            <Modal
                title="添加用户"
                visible={this.state.visibleModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}

            >
                <Spin tip="添加中..." spinning={refunding}>
                    <Form {...formItemLayout} onSubmit={this.creatUserSubmit}>
                        <Form.Item label="用户名">
                            {getFieldDecorator('userName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入用户',
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="姓名">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名',
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="密码">
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    },
                                ],
                            })(<Input.Password />)}
                        </Form.Item>
                        {/* <CreatUserRoles user={this.state.selected}/> */}
                        <Form.Item wrapperCol={{ span: 17, offset: 5 }}>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                htmlType="submit"
                            >
                                确认添加
                </Button>
                        </Form.Item>

                    </Form>
                </Spin>
            </Modal>
        </>
        )
    }
}
const DistrbutionLoginUserForm = Form.create()(DistributionLoginUser)
export { DistrbutionLoginUserForm as DistributionLoginUser }