import { Result, Button } from 'antd'
import React from 'react';
import HomePage from '../../pages/HomePage/HomePage';
import { Route, Link, Switch } from 'react-router-dom';
import { OrdersPage } from '../../pages/OrdersPage';
import { UsersPage } from '../../pages/UsersPage';
import { RolesPage } from '../../pages/RolesPage';
import { MenusPage } from '../../pages/MenusPage';
import { TerminalTurnoverReport , MarketingUsers,MarketingTerminalReport } from '../../pages/ReportPage';
import { TerminalManagementPage } from '../../pages/TerminalManagementPage';
import { TerminalSettings } from '../../pages/TerminalManagementPage';
import { obtainDeletedUser } from '../../pages/UsersPage';
import { obtainDeletedRole } from '../../pages/RolesPage';
import { obtainDeletedTerminal } from '../../pages/TerminalManagementPage';
import { obtainDeletedMenu } from '../../pages/MenusPage';
import { obtainDeletedMarketingUser } from '../../pages/MarketingUserPage';
import { MarketingTerminalePage } from '../../pages/MarketingTerminalPage';
import { MarketingUsersPage } from '../../pages/MarketingUserPage';

import {Error403} from '../../pages/ErrorPage';
import {Error500} from '../../pages/ErrorPage';

class SubRouter extends React.Component {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} component={HomePage} />
                <Route path={`${match.url}/order`} component={OrdersPage} />
                <Route path={`${match.url}/users`} component={UsersPage} />
                <Route path={`${match.url}/obtain-deleted-user`} component={obtainDeletedUser} />
                <Route path={`${match.url}/roles`} component={RolesPage} />
                <Route path={`${match.url}/obtain-deleted-role`} component={obtainDeletedRole} />
                <Route path={`${match.url}/menus`} component={MenusPage} />
                <Route path={`${match.url}/obtain-deleted-menu`} component={obtainDeletedMenu} />
                <Route path={`${match.url}/terminal-turnover`} component={TerminalTurnoverReport} />
                <Route path={`${match.url}/terminal-managenment`} component={TerminalManagementPage} />
                <Route path={`${match.url}/terminal-seetings`} component={TerminalSettings} />
                <Route path={`${match.url}/obtain-deleted-terminal`} component={obtainDeletedTerminal} />
                <Route path={`${match.url}/marketinguser`} component={MarketingUsersPage} />
                <Route path={`${match.url}/obtain-deleted-marketinguser`} component={obtainDeletedMarketingUser} />
                <Route path={`${match.url}/marketingterminal`} component={MarketingTerminalePage} />
                <Route path={`${match.url}/marketing-turnover`} component={MarketingUsers} />
                <Route path={`${match.url}/marketingterminal-turnover`} component={MarketingTerminalReport} />
                <Route path={`${match.url}/error/403`} component={Error403} />
                <Route path={`${match.url}/error/500`}  component={Error500} />
                <Route exact render={() =>
                    <Result
                        status="404"
                        title="404"
                        subTitle="你访问的页面不存在"
                        extra={
                            <Link to='/'>
                                <Button type="primary">返回首页</Button>
                            </Link>
                        }
                    />}
                />
            </Switch>
        )
    }
}
export default SubRouter