import axios from 'axios';
import { async } from 'q';

const hosts = {
    allusers: "/api/user",
    createUser: "/api/user/create",
    deleteUser: "/api/user/delete",
    userInfo: "/api/user",
    changeUser: "/api/user/change",
    userRoles: "/api/user/authenticate",
    changeUserRights: "/api/user/authenticate",
    erase: "/api/user/erase",
    undodelete: "/api/user/undodelete",
    changePassword:'/api/user/changepassword'
}
//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
export const userService = {
    allusers,
    createUser,
    deleteUser,
    userInfo,
    changeUser,
    userRoles,
    changeUserRights,
    erase,
    undodelete,
    changeUserPassword
}
//磁盘删除用户
async function erase(password, deleteIds) {
    const response = await mypost(hosts.erase, {
        password: password,
        deleteIds: deleteIds
    })
    return response;
}
//撤销删除
async function undodelete(ids, status, pwd) {
    const response = await mypost(hosts.undodelete, {
        ids: ids,
        status: status,
        pwd: pwd
    })
    return response;
}

//获取用户信息
async function allusers(search, status, pageindex, pagesize) {
    if (isNaN(status)) {
        status = null;
    }
    const response = await myget(hosts.allusers, {
        params: {
            search: search,
            status: status,
            pageindex: pageindex,
            pagesize: pagesize
        }
    });
    return response;
}

//删除用户
async function deleteUser(ids) {
    const response = await mypost(hosts.deleteUser, ids
    )
    return response;
}
//添加用户信息
async function createUser(userName, name, password, avatar) {
    const response = await mypost(hosts.createUser, {
        userName: userName,
        name: name,
        password: password,
        avatar: avatar,
    })
    return response;
}

//更改用户信息（管理员）
async function changeUser(id, name, avatar, status) {
    const response = await mypost(hosts.changeUser, {
        id: id,
        name: name,
        avatar: avatar,
        status: status
    })
    return response;
}

//更改用户密码（管理员）
async function changeUserPassword(id,newPassword){
    const response=await mypost(hosts.changePassword,{
        userId:id,
        password:newPassword
    });
    return response;
}

//获取用户详情
async function userInfo(userId) {
    const url = `${hosts.userInfo}/${userId}`;
    const response = myget(url)
    return response;
}

//获取用户与角色的关联
async function userRoles(userId) {
    const response = await myget(hosts.userRoles, {
        params: {
            userId: userId
        }
    })
    return response;
}

//更改用户权限
async function changeUserRights(userId, roles) {
    const response = await mypost(hosts.changeUserRights, {
        userId: userId,
        roles: roles
    })
    return response;
}