import React from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import { userService } from '../../services'

class ChangeUser extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            user:this.props.user
        }
    }
    //更改用户信息
    changeUser = e => {
        e.preventDefault();
        const { form } = this.props;
        try {
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { name, status,password } = values;
                    let id = this.props.user.id;
                    let avater = null;
                    let success=true;

                    await userService.changeUser(id, name, avater, status)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                    success&=false;
                                }
                                else {
                                    message.success('修改成功')
                                    success&=true;
                                }
                            }
                        });
                    
                    if (password) {
                        const response = await userService.changeUserPassword(id, password);
                        if (response) {
                            const result = response.data;
                            if (!result.success) {
                                message.error(result.message)
                                success&=false;
                            }
                            else {
                                message.success('修改密码成功')
                                success&=true;
                            }
                        }
                    }

                    if(success){
                        this.props.handlePaging(null, 1, 10);
                        this.props.handleChangeUserCancel();
                    }
                }
            })
        }
        catch (ex) {
            alert(ex)
        }
    }

    comparePassword=(rule,value,callback)=>{
        const {form}=this.props;

        const passwordValue=form.getFieldValue('password');
        if( passwordValue && value !== passwordValue){
            callback('输入的密码与新密码不一致');
        }else{
            callback();
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { user } = this.props;
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.changeUser}>
                <Form.Item label="姓名">
                    {getFieldDecorator('name', {
                        initialValue: user.name,
                        rules: [
                            {
                                required: true,
                                message: '请输入姓名',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="状态">
                    {getFieldDecorator('status', {
                        initialValue: user.status,
                        rules: [
                            {
                                required: true,
                                message: '请选择状态',
                            },
                        ],
                    })(
                    <Select
                        placeholder="请选择"
                    >
                        <Option value={1}>启用</Option>
                        <Option value={0}>禁用</Option>
                    </Select>)}
                </Form.Item>
                
                <Form.Item label="新密码">
                    {getFieldDecorator('password')(
                        <Input.Password placeholder='输入新密码'/>
                    )}
                </Form.Item>

                <Form.Item label="确认密码">
                    {getFieldDecorator('passConfirm',{
                        rules:[
                            {
                                validator:this.comparePassword.bind(this)
                            }
                        ]
                    })(
                        <Input.Password placeholder='请再次输入新密码'/>
                    )}
                </Form.Item>

                {/* <CreatUserRoles user={this.state.selected}/> */}
                <Form.Item wrapperCol={{ span: 16, offset: 5 }}>
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        确认添加
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

const ChangeUserForm = Form.create()(ChangeUser)
export { ChangeUserForm as ChangeUser } 