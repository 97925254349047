import React from 'react';
import {
    Button,
    Form,
    Modal,
    Input,
    message,
    Descriptions,
    Tag,
    Divider,
    Table,
    Spin,
    Drawer,
    Select
} from 'antd';
import { marketingService } from '../../services';
import {
    ChangeMarketingUser,
    NoDistributionAccountModule,
    YesDistributionAccountModule,
    DistributionLoginUser
} from '../MarketingUserPage';

const { Option } = Select;

class MarketingUserModule extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            marketingUser: this.props.marketingUser,
            username: this.props.marketingUser.username,

            visibleModal: false,
            page: 1,
            pagesize: 10,
            search: null,
            totalCount: 0,
            list: [],
            loading: false,
            removeing: false,
            visibleMarketingUserModal: false,
            visibleDrawerMarketing: false
        }
    }
    //回调
    callBack(record) {
        if (record !== null) {
            const { marketingUser } = this.state;
            marketingUser.username = record.loginName;
            this.setState({
                ...this.state,
                username: record.loginName,
                marketingUser: marketingUser
            })
        }
    }

    //显示Drawer事件
    showDrawerMarketing = record => {
        this.setState({
            ...this.state,
            visibleDrawerMarketing: true,
            selected: record.accountId,
        })
    }
    //关闭Drawer事件
    onCloseMarketing = () => {
        this.setState({
            ...this.state,
            visibleDrawerMarketing: false,
        });
    }

    //禁用分成用户
    disableIntoUser = e => {
        e.preventDefault();
        const { form } = this.props;
        const marketingUserid = this.state.marketingUser.id;
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { pwd, remark } = values;
                    await marketingService.disableUser(marketingUserid, pwd, remark)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                }
                                else {
                                    const userstatus = this.state.marketingUser;
                                    userstatus.status = 0;
                                    message.success("禁用成功")
                                    this.handleCancel();
                                    this.props.handlePaging(null, 1, this.state.pagesize);
                                    this.setState({
                                        status: 0
                                    })
                                }
                            }
                        })
                }
                catch (ex) {
                    alert(ex)
                }
            }
        })
    }
    //显示Modal事件
    showModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleModal: true,
        });
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };
    //显示修改MarketingUserModal事件
    showMarketingUserModal = () => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            visibleMarketingUserModal: true,
        });
    };
    //Modal关闭事件
    handleMarketingUserCancel = () => {
        this.setState({
            visibleMarketingUserModal: false,
        });
    };
    //移除分成用户与登陆用户的关联
    async removeAssociation() {
        try {
            this.setState({
                removeing: true
            })
            await marketingService
                .updateusermap(
                    this.state.marketingUser.userid,
                    this.state.marketingUser.id, false)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                            message.error(result.message)
                            this.setState({
                                removeing: false
                            })
                        }
                        else {
                            const marketing = this.state.marketingUser;
                            message.success("移除成功")
                            marketing.username = null;
                            this.setState({
                                username: null,
                                removeing: false
                            })
                            this.child.DistrbutionLoginUser();
                            this.props.handlePaging(null, 1, this.state.pagesize);
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //获取分成用户的分成设备
    async intoUserTerminals(marketingUserid, page, pagesize) {
        try {
            this.setState({
                loading: true
            })
            await marketingService.intoUserTerminal(marketingUserid, this.state.search, page - 1, pagesize)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        if (!result.success) {
                            message.error(result.data)
                            this.setState({
                                loading: false
                            })
                        }
                        this.setState({
                            list: list,
                            page: page,
                            search: this.state.search,
                            pagesize: pagesize,
                            totalCount: result.count,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
            this.setState({
                loading: false
            })
        }
    }
    onRef = (ref) => {
        this.child = ref
    }
    //加载信息
    async componentDidMount() {
        const { pagesize, search } = this.state;
        const marketingUserid = this.state.marketingUser.id;
        await this.intoUserTerminals(marketingUserid, 1, pagesize);
        this.setState({
            loading: false
        })
    }
    renderKey(key) {
        if (key === 'marketingusername') {
            return '分成用户姓名'
        }
        if (key === 'status') {
            return '分成用户状态'
        }
        if (key === 'username') {
            return '所属用户'
        }
        if (key === 'bankcardnumber') {
            return '银行卡号'
        }
        if (key === 'phonenumber') {
            return '手机号码'
        }
        if (key === 'wechat') {
            return '微信号码'
        }
        if (key === 'office') {
            return '描述'
        }
        return key;
    }

    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 0:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        if (key === 'username') {
            if (value !== null) {
                const { marketingUser } = this.props;
                return (
                    <>
                        {marketingUser.username}&nbsp;&nbsp;&nbsp;
                    <a onClick={() => this.removeAssociation()}>解除关联</a>
                    </>
                )
            }
            else {
                return <Tag color="black" >
                    禁止登陆
            </Tag>
            }
        }
        return value;
    }
    render() {
        const { marketingUser, loading, removeing } = this.state;
        const { TextArea, Search } = Input;
        const { getFieldDecorator } = this.props.form;

        const columns = [
            {
                title: '设备编码',
                dataIndex: 'terminalId',
                key: 'terminalId',
            },
            {
                title: '设备名称',
                dataIndex: 'terminalname',
                key: 'terminalname',
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawerMarketing(record)}>配置</a>
                    </span>
                ),
            },
        ];
        return (
            <Spin tip={"加载中..."} spinning={loading}>
                <Spin tip={"移除中..."} spinning={removeing}>
                    <Button type="primary"
                        style={{ float: "right" }}
                        onClick={this.showModal}>禁用分成用户
                </Button>
                    <Button type="ghost"
                        style={{ float: "right", marginRight: 10 }}
                        onClick={this.showMarketingUserModal}>修改分成用户
                </Button>
                    <Descriptions bordered title="分成用户信息" border >
                        {
                            Object.keys(marketingUser).filter(key => {
                                //filter过滤数组中元素
                                if (key === 'id') return false;
                                if (key === 'key') return false;
                                if (key === 'userid') return false;
                                return true;
                            }).map(key =>
                                <Descriptions.Item
                                    key={key}
                                    label={this.renderKey(key)}>
                                    {this.renderValue(key, marketingUser[key])}
                                </Descriptions.Item>
                            )
                        }
                    </Descriptions>
                    <Divider orientation="left"><b>分配所属用户</b></Divider>
                    <DistributionLoginUser
                        dataChangeCallBack={record => this.callBack(record)}
                        onRef={this.onRef}
                        marketingUser={this.state.marketingUser}
                        handlePaging={this.props.handlePaging.bind(this)}
                    />
                    <Divider orientation="left"><b>参与分成的设备</b></Divider>
                    <YesDistributionAccountModule marketingUser={this.state.marketingUser} />
                    <Divider orientation="left"><b>未参与分成设备</b></Divider>
                    <NoDistributionAccountModule marketingUser={this.state.marketingUser} />
                    <Drawer
                        placement="top"
                        height={"100%"}
                        onClose={this.onCloseMarketing}
                        visible={this.state.visibleDrawerMarketing}
                        destroyOnClose={true}>
                        <Button onClick={this.onCloseMarketing}>返回</Button>
                    </Drawer>
                    <Modal
                        title="禁用分成用户"
                        visible={this.state.visibleModal}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <Form onSubmit={this.disableIntoUser}>
                            <Form.Item>
                                {
                                    getFieldDecorator('pwd', {
                                        rules: [{
                                            required: true,
                                            message: '请输入当前管理员密码!'
                                        }],
                                    })(
                                        <Input.Password placeholder="请输入当前管理员密码" />)}
                            </Form.Item>
                            <Form.Item>
                                {
                                    getFieldDecorator('remark', {
                                        rules: [{
                                            required: true,
                                            message: '请输入禁用该用户原因!'
                                        }],
                                    })(
                                        <TextArea style={{ resize: "none" }}
                                            placeholder="请输入需要备注的信息"
                                            autosize={{ minRows: 3, maxRows: 3 }}
                                        />)
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    style={{ float: "right" }}
                                    type="primary"
                                    htmlType="submit">
                                    确认
                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title="修改分成用户"
                        visible={this.state.visibleMarketingUserModal}
                        onCancel={this.handleMarketingUserCancel}
                        footer={null}
                    >
                        <ChangeMarketingUser
                            marketingUser={this.props.marketingUser}
                            handleMarketingUserCancel={this.handleMarketingUserCancel.bind(this)}
                            onClose={this.props.onClose.bind(this)}
                            handlePaging={this.props.handlePaging.bind(this)}
                        />
                    </Modal>
                </Spin>
            </Spin>
        )
    }
}
const MarketingUserModuleForm = Form.create()(MarketingUserModule)
export { MarketingUserModuleForm as MarketingUserModule } 