import React from 'react';
import { roleService } from '../../services';
import {
    message,
    Button,
    Tree,
    Spin
} from 'antd';

const { TreeNode } = Tree;

class ActionModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            action: [],
            checkedKeys: {},
            selectedActions: [],
            expandedKeys: ['0'],
            selectedKeys: [],
            loading: false
        }
    }
    //加载角色权限节点
    async componentDidMount() {
        await this.action();
        const selectedActions = [];
        const { action } = this.state;
        const keys = Object.keys(action);
        keys.map(key => {
            action[key].filter(type => type.ischoose)
                .map(type => {
                    selectedActions.push(type.id)
                });
        })
        this.setState({
            checkedKeys: selectedActions
        })
    }
    //更改角色权限类型节点
    changeActions = async () => {
        try {
            this.setState({
                loading: true
            })
            const { checkedKeys } = this.state;
            if (checkedKeys.length != 0) {
                await roleService
                    .changeAction(this.props.role.id, checkedKeys.filter(key => key.indexOf('-') > 7))
                    .then(response => {
                        if (response) {
                            let changedata = response.data;
                            if (!changedata.success) {
                                message.error(changedata.message)
                                this.setState({
                                    loading: false
                                })
                            }
                            else {
                                message.success('修改成功')
                                this.setState({
                                    loading: false
                                })
                            }
                        }
                    })
            }
            else {
                message.error("角色权限不能为空")
                this.setState({
                    loading: false
                })
            }
        }
        catch (ex) {
            alert(ex)
        }
    }
    //获取角色权限节点
    async action() {
        try {
            await roleService.action(this.props.role.id)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        this.setState({
                            action: result.data
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //循环遍历角色权限类型节点
    renderTreeNodes = action =>
        Object.keys(action)
            .map(key => {
                return (
                    <TreeNode title={key} key={key} >
                        {
                            action[key].map(type =>
                                <TreeNode title={type.actionname} key={type.id} />
                            )
                        }
                    </TreeNode>
                );
            });
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = (checkedKeys, e) => {
        const checked = [...checkedKeys, ...e.halfCheckedKeys];
        this.setState({
            selectedKeys: checked,
            checkedKeys: checkedKeys
        });
    };

    render() {
        const { loading } = this.state;
        return (
            <div style={{ marginTop: 30 }}>
                <Spin tip="修改中..." spinning={loading}>
                    <Tree
                        defaultExpandAll={true}
                        checkable
                        selectable={false}
                        onExpand={this.onExpand}
                        expandedKeys={this.state.expandedKeys}
                        autoExpandParent={this.state.autoExpandParent}
                        checkedKeys={this.state.checkedKeys}
                        onCheck={this.onCheck}
                    >
                        <TreeNode key={0} title='所有接口类型'>
                            {this.renderTreeNodes(this.state.action)}
                        </TreeNode>
                    </Tree>
                    <Button
                        type="primary"
                        onClick={(e) => this.changeActions(e)}
                        style={{ float: 'right', marginLeft: 30, marginTop: -30 }}
                    >提交</Button>
                </Spin>
            </div>
        )
    }
}
export { ActionModule as ActionModule }