import React from 'react';
import {
    Form,
    Input,
    message,
    Button,
    Radio,
    Select,
    Spin
} from 'antd';
import { terminalManagenmentService } from '../../services/terminalManagenment.service';

class ChangeTerminalPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            random: 0,
            body: {},
            loading: false
        }
    }

    //加载信息
    componentDidMount() {
        this.loadTerminal();
    }
    //加载终端机信息
    loadTerminal() {
        try {
            this.setState({
                loading: true
            })
            let terminalId = this.props.terminal.terminalId;
            terminalManagenmentService.terminal(terminalId)
                .then(response => {
                    if (response) {
                        var body = response.data;
                        if (!body.success) {
                            message.error('未加载到信息')
                        }
                        else {
                            this.setState({
                                body: body.data,
                                loading: false
                            })
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    //修改终端机信息
    changeTreminal = e => {
        e.preventDefault();
        try {
            const { form } = this.props;
            try {
                form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        const { name,marketing, remark, gpsLng, status, gpsLag, driverbusiness } = values;
                        let uid = this.props.terminal.uid;
                        await terminalManagenmentService
                            .change(uid,marketing, name, status, remark, gpsLng, gpsLag, driverbusiness)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    if (!result.success) {
                                        message.error(result.message)
                                        this.setState({
                                            loading: false
                                        })
                                    }
                                    else {
                                        message.success('修改成功');
                                        this.setState({
                                            loading: false
                                        })
                                        this.props.handlePaging(null, 1, this.state.pagesize);
                                        this.props.handleChangeTerminalCancel();
                                    }
                                }
                            })
                    }
                })
            }
            catch (ex) {
                message.error('请检查网络出现问题')
                this.setState({
                    adding: false
                })
            }
        }
        catch (ex) {
            alert(ex);
        }
    }
    render() {
        const { loading } = this.state;
        const { TextArea } = Input;
        const { body } = this.state;
        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };
        return (
            <Spin tip="加载中" spinning={loading}>
                <Form onSubmit={e => this.changeTreminal(e)}>
                    <Form.Item label='授权驾驶证接口访问'>
                        {
                            getFieldDecorator('driverbusiness', {
                                initialValue: body.driverbusiness,
                                rules: [
                                    {
                                        required: true,
                                        message: '请授权驾驶证接口访问',
                                    }
                                ]
                            })(<Radio.Group>
                                <Radio.Button defaultChecked={true}
                                    value={true}>准许访问</Radio.Button>
                                <Radio.Button value={false}>禁止访问</Radio.Button>
                            </Radio.Group>)
                        }
                    </Form.Item>
                    <Form.Item label='是否参与营销'>
                    {
                        getFieldDecorator('marketing', {
                            initialValue: body.Marketing,
                            rules: [
                                {
                                    required: true,
                                    message: '请授权参与营销',
                                }
                            ]
                        })(<Radio.Group>
                            <Radio.Button defaultChecked={true}
                                value={true}>参与营销</Radio.Button>
                            <Radio.Button value={false}>不参与营销</Radio.Button>
                        </Radio.Group>)
                    }
                </Form.Item>
                    <Form.Item label='设备名'>
                        {
                            getFieldDecorator('name', {
                                initialValue: body.name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入设备名',
                                    }
                                ]
                            })(<Input placeholder='请输入设备名' />)
                        }
                    </Form.Item>
                    <Form.Item label='设备状态'>
                        {getFieldDecorator('status', {
                            initialValue: body.status,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择状态',
                                },
                            ],
                        })(
                            <Select>
                                <Option value={1}>启用</Option>
                                <Option value={2}>禁用</Option>
                            </Select>)}
                    </Form.Item>
                    <Form.Item label='经度'>
                        {
                            getFieldDecorator('gpsLng', {
                                initialValue: body.gpsLng,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入经度'
                                    }
                                ]
                            })(<Input placeholder='请输入经度' />)
                        }
                    </Form.Item>
                    <Form.Item label='纬度'>
                        {
                            getFieldDecorator('gpsLag', {
                                initialValue: body.gpsLag,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入纬度'
                                    }
                                ]
                            })(<Input placeholder='请输入纬度' />)
                        }
                    </Form.Item>
                    <Form.Item label='备注'>
                        {
                            getFieldDecorator('remark', {
                                initialValue: body.remark,
                                rules: [{
                                    required: false,
                                }]
                            })(
                                <TextArea style={{ resize: "none" }}
                                    placeholder="请输入需要备注的信息"
                                    autosize={{ minRows: 3, maxRows: 3 }}
                                />
                            )
                        }
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 18, offset: 5 }}>
                        <Button
                            style={{ float: "right" }}
                            type="primary"
                            htmlType="submit"
                        >
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}
const ChangeTerminalPageForm = Form.create()(ChangeTerminalPage);
export { ChangeTerminalPageForm as ChangeTerminalPage }