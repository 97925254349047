import React from 'react';
import {
    Button,
    Form,
    message,
    Input,
    Spin,
    InputNumber
} from 'antd';
import { terminalManagenmentService } from '../../services';

class PricelistPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false
        }
    }

    //加载信息
    componentDidMount() {
        this.priceList()
    }

    //获取指定设备价目表
    priceList = async () => {
        try {
            this.setState({
                loading: true
            })
            let uid = this.props.terminal.uid
            await terminalManagenmentService.pricelist(uid)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                        }
                        else {
                            this.setState({
                                data: result.data,
                                loading: false
                            })
                        }
                    }
                });
        }
        catch (ex) {
            alert(ex)
        }
    }

    //修改指定价目表
    changePrice = e => {
        e.preventDefault();
        try {
            const { form } = this.props;
            try {
                form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        let price = [...values.priceList]
                        await terminalManagenmentService.changepricelist(this.props.terminal.uid, price)
                            .then(response => {
                                if (response) {
                                    let result = response.data;
                                    try {
                                        if (!result.success) {
                                            message.error(result.message)
                                        }
                                        else {
                                            this.props.updataDeploy();
                                            message.success('修改成功')
                                            this.props.handlePriceListCancel();
                                        }
                                    }
                                    catch (ex) {
                                        message.success('修改成功')
                                        this.props.handlePriceListPageCancel();
                                    }
                                }
                            })
                    }
                })
            }
            catch (ex) {
                message.error('请检查网络出现问题')
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        const { loading } = this.state;
        const { price } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };

        return (
            <Spin tip="加载中" spinning={loading}>
                <Form onSubmit={e => this.changePrice(e)}>
                    {data.map((item, index) =>
                        [
                            <Form.Item key={`bin[${index}}]_${item.businessId}`} style={{ display: 'none' }} >
                                {
                                    getFieldDecorator(`priceList[${index}].businessid`, {
                                        initialValue: item.businessId,
                                    })(
                                        <InputNumber />
                                    )
                                }
                            </Form.Item>,
                            <Form.Item label={item.businessName} key={`price[${index}]_${item.businessId}`} >
                                {
                                    getFieldDecorator(`priceList[${index}].price`, {
                                        initialValue: item.price ? item.price : item.defaultPrice
                                    })(
                                        <Input disabled={!item.enabled} />
                                    )
                                }
                            </Form.Item>
                        ])}
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type='primary'
                            style={{ float: "right" }}>
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}

const PricelistPageForm = Form.create()(PricelistPage)

export { PricelistPageForm as PricelistPage }