import React from 'react';
import { Table, Spin, Button, Tooltip, Modal, Form, message } from 'antd';
import { marketingService } from '../../services';
import { connect } from 'react-redux';
import { DetailedConfig } from '../MarketingActionPage';

class Business extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            visibleModal: false,
            submiting: false,
            loading: false
        }
    }
    showModal = (e) => {
        //获取点击按钮的id
        let id = e.currentTarget.getAttribute('data-id')
        this.setState({
            visibleModal: true,
            id: id
        });
        const { resetFields } = this.props.form;
        resetFields();
    };
    //Modal关闭事件
    handleCancel = () => {
        this.setState({
            visibleModal: false,
        });
    };

    async componentDidMount() {
        await this.business();
    }
    async business() {
        try {
            this.setState({
                loading: true
            })
            await marketingService.business(this.props.record.terminalId)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let list = result.data;
                        if (!result.success) {
                            this.setState({
                                loading: false
                            })
                        }
                        this.setState({
                            data: list,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex)
        }
    }
    //提交分成信息
    async sumitMarketing() {
        this.setState({
            submiting: true
        })
        const { ifParticipationSplit, detailedConfig } = this.props;
        let marketingUserId = this.props.marketingUser.id;
        let terminalId = this.props.record.terminalId;
        await marketingService
            .createMarketing(
                marketingUserId,
                terminalId,
                ifParticipationSplit,
                detailedConfig
            )
            .then(response => {
                if (response) {
                    let result = response.data;
                    if (!result.success) {
                        message.error(result.message)
                        this.setState({
                            submiting: false
                        })
                    }
                    else {
                        message.success("保存成功")
                        this.setState({
                            submiting: false
                        })
                        this.props.onClose();
                        this.props.handlePaging(null, 1, 5)
                    }
                }
            })
    }
    //修改分成信息
    async changeMarketing() {
        this.setState({
            submiting: true
        })
        const { detailedConfig } = this.props;
        let accountId = this.props.record.accountId;
        await marketingService
            .update(
                accountId,
                detailedConfig
            )
            .then(response => {
                if (response) {
                    let result = response.data;
                    if (!result.success) {
                        message.error(result.message)
                        this.setState({
                            submiting: false
                        })
                    }
                    else {
                        message.success("保存成功")
                        this.setState({
                            submiting: false
                        })
                        this.props.onClose();
                        this.props.handlePaging(null, 1, 5)
                    }
                }
            })
    }
    render() {
        const { data, submiting, loading } = this.state;
        return (
            <Spin tip={"加载中..."} spinning={loading}>
                <Spin tip={"提交数据中,请稍后..."} spinning={submiting}>

                    {
                        data ? data.map((item, index) =>
                            <Tooltip key={`${"toll_"}${item.id}`} placement="topLeft" title={`${"该项业务推荐价格为 "}${item.price}${"元"}`}>
                                <Button key={`${"butt_"}${item.id}`} data-id={item.id}
                                    onClick={(e) => this.showModal(e)}
                                    style={{ marginLeft: 10, width: 400, marginTop: 20 }}
                                >
                                    {item.businessname}
                                </Button><br />
                            </Tooltip>
                        ) : null
                    }
                    <br />
                    <Button
                        type="primary"
                        style={{ width: 400, marginLeft: 12 }}
                        onClick={() => this.changeMarketing()}>修改数据</Button>
                    <Modal
                        title="配置价格"
                        visible={this.state.visibleModal}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <DetailedConfig
                            record={this.props.record}
                            id={this.state.id}
                            handleCancel={this.handleCancel.bind(this)} />
                    </Modal>
                </Spin>
            </Spin>)
    }
}

const mapStateToProps = state => {
    return { ...state.setparameters };
}
const BusinessPage = connect(mapStateToProps)(Business)
const BusinessPageForm = Form.create()(BusinessPage)
export { BusinessPageForm as Business }