import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

class Error500 extends React.Component {
    render() {
        return (
            <Result
                status="500"
                title="500"
                subTitle="服务器发生了错误,请检查网络"
                extra={
                    <Link to='/'>
                        <Button type="primary">返回首页</Button>
                    </Link>}
            />
        )
    }
}
export { Error500 as Error500 }