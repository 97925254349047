import axios from 'axios';

const hosts = {
    login: "/api/account/login",
    logout: "/api/account/logout",
    menus: "/api/account/menus",
    userauth: "/api/account/user",
}

export const accountService = {
    userauth,
    login,
    logout,
    menus,
}
//获得该用户信息
function userauth() {
    return axios.get(hosts.userauth)
}
//登陆
function login(username, password) {
    return axios.post(hosts.login, {
        userName: username,
        password: password
    })
}
//登出
function logout() {
    return axios.get(hosts.logout)
}
//菜单
function menus() {
    return axios.get(hosts.menus)
}