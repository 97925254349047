import React from 'react';
import { Form, Button, Select, Input,InputNumber } from 'antd';
import { connect } from 'react-redux';

const InputGroup = Input.Group;
const { Option } = Select;
class NoDetailedConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            change:false
        }
    }

    //详细配置
    DetailedConfig = e => {
        e.preventDefault();
        const { form } = this.props;
        const { dispatch } = this.props;

        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                dispatch({
                    type: 'SUBMIT_NODETAILEDCONFIG',
                    current: 0,
                    nodetailedConfig: values,
                    id: this.props.id,
                    change: this.state.change,
                    changeFixed: this.state.changeFixed
                })
                this.props.onClose();
            }
           
        })
        this.props.form.resetFields();
    }

    onClickChange0() {
        this.setState({
            change: false,
            changeFixed: true
        })
    }
    onClickChange1() {
        this.setState({
            change: true,
            changeFixed: false
        })
    }
    onClickChange2() {
        this.setState({
            change: true,
            changeFixed: true
        })
    }

    render() {
        const { nodetailedConfig, id, nostatusArray } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { onInput } = this.state;

        //从redux中取出状态值,显示默认值
        let selected = null;
        if (nodetailedConfig && nodetailedConfig.length) {
            const marketing = nodetailedConfig
                .filter(marketing => marketing.id == id);
            if (marketing) {
                selected = marketing[0];
            }
        }

        //从redux取出文本框状态值
        let stated = null;
        if (nostatusArray && nostatusArray.length) {
            const stateArray = nostatusArray.filter(state => state.id == id);
            if (stateArray) {
                stated = stateArray[0];
            }
        }

        let selset = selected ? selected.parameterValue ? selected.parameterValue.split(/[(,]/) : null : null;
        return (
            <Form  onSubmit={e => this.DetailedConfig(e)}>
                <Form.Item style={{ display: 'none' }}>
                    {
                        getFieldDecorator('id', {
                            initialValue: id
                        })(<Input />)
                    }
                </Form.Item>,
                <Form.Item label='类型'>
                    {
                        getFieldDecorator('operator', {
                            initialValue: selected ? selected.operator : null,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择分成类型',
                                }
                            ]
                        })(<Select >
                            <Option onClick={() => this.onClickChange0()} value={0}>不分成</Option>
                            <Option onClick={() => this.onClickChange1()} value={1}>固定分成</Option>
                            {/* <Option onClick={() => this.onClickChange2()} value={2}>阶梯</Option> */}
                        </Select>)
                    }
                </Form.Item>
                {/* {this.state.changeFixed !== false ? this.state.change !== false ?
                    stated ? stated.nochange === true && stated.nochangeFixed === true ?
                        <Form.Item label='范围'>
                            {
                                getFieldDecorator('type', {
                                    initialValue: selected ? selected.type : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择分成范围',
                                        }
                                    ]
                                })(<Select >
                                    <Option  value={2}>大于</Option>
                                </Select>)
                            }
                        </Form.Item> : null : <Form.Item label='范围'>
                            {
                                getFieldDecorator('type', {
                                    initialValue: selected ? selected.type : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择分成范围',
                                        }
                                    ]
                                })(<Select >
                                    <Option value={2}>大于</Option>
                    
                                </Select>)
                            }
                        </Form.Item> : null : null
                }
                { this.state.changeFixed !== false ? this.state.change !== false ?
                    stated ? stated.nochange === true && stated.nochangeFixed === true ? <Form.Item label='分成参数'>
                        {
                            getFieldDecorator('startValue', {
                                initialValue: selected ? selected.parameterValue : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成参数',
                                    }
                                ]
                            })(<Input />)
                        }
                    </Form.Item> : null : <Form.Item label='分成参数'>
                            {
                                getFieldDecorator('startValue', {
                                    initialValue: selected ? selected.parameterValue : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入分成参数',
                                        }
                                    ]
                                })(<Input />)
                            }
                        </Form.Item> : null : null
                } */}
                {this.state.change === false ? stated?stated.nochange!==false?
                    <Form.Item label='金额'>
                        {
                            getFieldDecorator('profit', {
                                initialValue: selected ? selected.profit : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成金额',
                                    }
                                ]
                            })(<InputNumber style={{width:'100%'}} />)
                        }
                    </Form.Item> :null:null:<Form.Item label='金额'>
                        {
                            getFieldDecorator('profit', {
                                initialValue: selected ? selected.profit : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分成金额',
                                    }
                                ]
                            })(<InputNumber style={{width:'100%'}} />)
                        }
                    </Form.Item>
                }
                <Form.Item >
                    <Button 
                        type="primary"
                        htmlType="submit"
                        style={{float:"right"}}
                    >
                        确定
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}
const mapStateToProps = state => {
    return { ...state.setparameters };
}
const NoDetailedConfigPage = connect(mapStateToProps)(NoDetailedConfig)
const NoDetailedConfigPageForm = Form.create()(NoDetailedConfigPage)
export { NoDetailedConfigPageForm as NoDetailedConfig }