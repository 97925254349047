import React from 'react';
import { Button, Form, Input, Select, message, Menu } from 'antd';
import { menuService } from '../../services'

class ChangeMenu extends React.Component {
    constructor(props) {
        super(props)
    }
    //更改用户信息
    changeMenu = e => {
        e.preventDefault();
        const { form } = this.props;
        try {
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { icon, menuname, url, parentid, status } = values;
                    let id = this.props.menu.id;
                    await menuService
                        .changeMenu(id, icon, menuname, url, parentid, status)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                }
                                else {
                                    message.success('修改成功')
                                    this.props.handlePaging();
                                    this.props.handleChangeMenuCancel();
                                }
                            }
                        })
                }
            })
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { menu } = this.props;
        const { menunodelist } = this.props;
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.changeMenu}>
                <Form.Item label="菜单名">
                    {getFieldDecorator('menuname', {
                        initialValue: menu.name,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜单名',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="图标">
                    {getFieldDecorator('icon', {
                        initialValue: menu.icon,
                        rules: [
                            {
                                required: true,
                                message: '请输入图标',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="状态">
                    {getFieldDecorator('status', {
                        initialValue: menu.status,
                        rules: [
                            {
                                required: true,
                                message: '请选择状态',
                            },
                        ],
                    })(<Select
                        placeholder="请选择"
                    >
                        <Option value={1}>启用</Option>
                        <Option value={0}>禁用</Option>
                    </Select>)}
                </Form.Item>
                <Form.Item label="地址">
                    {getFieldDecorator('url', {
                        initialValue: menu.url,
                        rules: [
                            {
                                required: false,
                                message: '请输入地址',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="父级菜单">
                    {getFieldDecorator('parentid', {
                        initialValue: menu.parentid,
                        rules: [
                            {
                                required: false,
                                message: '请选择父级菜单名',
                            },
                        ],
                    })(<Select>
                        <Option value={null} >系统根目录</Option>
                        {
                            menunodelist.map((item, index) => (
                                <Option value={item.id}
                                    key={item.index}
                                >
                                    {item.menuName}
                                </Option>
                            ))
                        }
                    </Select>)}
                </Form.Item>

                <Form.Item wrapperCol={{ span: 16, offset: 5 }}>
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        确认修改
                        </Button>
                </Form.Item>
            </Form>
        )
    }
}
const ChangeMenuForm = Form.create()(ChangeMenu)
export { ChangeMenuForm as ChangeMenu } 