import { Result, Button } from 'antd'
import { Route, Link, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import LoginPage from '../../pages/LoginPage/LoginPage';
import { PrivateRoute } from '../Routings/AuthenticationRouter';
import Main from '../../layouts/Main';
import SubRoute from './SubRoute'

export const Router = (props) => {
    return (
        <Switch>
            <Route exact path='/' render={() =>
                <Redirect to='/app' />
            } />
            <PrivateRoute path='/app' component={Main} />
            <Route path="/login" render={() =>
                sessionStorage.getItem('user') ?
                    <Redirect to='/' /> :
                    <LoginPage />
            } />

            <Route exact render={() =>
                <Result
                    status="404"
                    title="404"
                    subTitle="你访问的页面不存在"
                    extra={
                        <Link to='/'>
                            <Button type="primary">返回首页</Button>
                        </Link>
                    }
                />}
            />

        </Switch>
    )
}

export { SubRoute }