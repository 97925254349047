import React from 'react';
import { Tree, Spin, Button, message } from 'antd';
import { terminalManagenmentService } from '../../services'
import { connect } from 'react-redux'
const { TreeNode } = Tree;

class AddPhototypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phototypes: [],
            checkedKeys: {},
            selectedPhototypes: [],
            expandedKeys: ['0'],
            loading: false
        }
    }
    //上一步
    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
    }
    async componentDidMount() {
        await this.Addphototypes();
        const selectedPhototypes = [];
        //redux中取出照片类型选中值(id)
        const checked = this.props.phototype;
        const { phototypes } = this.state;
        const keys = Object.keys(phototypes);

        if (checked && checked.length > 0) {
            keys.map(key => phototypes[key].map(kid => {
                const checkedItems = checked.filter(check => check === kid.photoid);
                if (checkedItems.length > 0) {
                    kid.choosephoto = true;
                }
            }))
        }

        keys.map(key => {
            phototypes[key].filter(photo => photo.choosephoto)
                .map(photo => {
                    selectedPhototypes.push(photo.photoid)
                });
        })

        this.setState({
            checkedKeys: selectedPhototypes
        })
    }
    //获取终端机照片类型
    async Addphototypes() {
        try {
            this.setState({
                loading: true
            })
            await terminalManagenmentService.phototypes()
                .then(response => {
                    if (response) {
                        let result = response.data;
                        this.setState({
                            phototypes: result.data,
                            loading: false
                        })
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    //更改图片类型节点
    changePhototypes = () => {
        try {
            const { checkedKeys } = this.state;
            const { dispatch } = this.props;
            if (checkedKeys.length != 0) {
                let selectedKey = checkedKeys.filter(key => key.indexOf('-') > 7);
                dispatch({
                    type: 'SUBMIT_PHOTOTYPE',
                    current: 3,
                    phototype: selectedKey
                })
            }
            else {
                message.error('请选择照片类型')
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    renderTreeNodes = phototypes =>
        Object.keys(phototypes)
            .map(key => {
                return (
                    <TreeNode title={key} key={key} >
                        {
                            phototypes[key].map(type =>
                                <TreeNode title={type.photoname} key={type.photoid} />
                            )
                        }
                    </TreeNode>
                );
            });
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = (checkedKeys, e) => {
        const checked = [...checkedKeys, ...e.halfCheckedKeys];
        this.setState({
            selectedKeys: checked,
            checkedKeys: checkedKeys
        });
    };
    render() {
        const { loading } = this.state;
        return (
            <Spin tip="加载中" spinning={loading}>
                <Tree

                    checkable
                    selectable={false}
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={this.state.autoExpandParent}
                    checkedKeys={this.state.checkedKeys}
                    onCheck={this.onCheck}
                >
                    <TreeNode key={0} title='所有类型'>
                        {this.renderTreeNodes(this.state.phototypes)}
                    </TreeNode>
                </Tree>
                <Button onClick={() => this.prev()} style={{ float: "left" }} >上一步</Button>
                <Button onClick={() => this.changePhototypes()} type='primary' style={{ float: "right" }} >下一步</Button>
            </Spin>
        )
    }
}
const mapStateToProps = state => {
    return { ...state.terminalSetting }
}
const AddPhototypesConnect = connect(mapStateToProps)(AddPhototypes)
export { AddPhototypesConnect as AddPhototypes }