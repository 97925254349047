import React from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import { roleService } from '../../services'

class ChangeRole extends React.Component {
    constructor(props) {
        super(props)
    }
    //更改角色信息
    changeRole = e => {
        e.preventDefault();
        const { form } = this.props;
        try {
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { rolename, status } = values;
                    let id = this.props.role.id;
                    await roleService.changeRole(id, rolename, status)
                        .then(response => {
                            if (response) {
                                let result = response.data;
                                if (!result.success) {
                                    message.error(result.message)
                                }
                                else {
                                    message.success('修改成功')
                                    this.props.handlePaging(null, 1, 10);
                                    this.props.handleChangeRoleCancel();
                                }
                            }
                        })
                }
            })
        }
        catch (ex) {
            alert(ex)
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { role } = this.props;
        const formItemLayout = {
            labelCol: {
                sm: { span: 3 },
            },
            wrapperCol: {
                sm: { span: 18 },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={e => this.changeRole(e)}>
                <Form.Item label="角色名">
                    {getFieldDecorator('rolename', {
                        initialValue: role.roleName,
                        rules: [
                            {
                                required: true,
                                message: '请输入角色名',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="状态">
                    {getFieldDecorator('status',
                        {
                            initialValue: role.status,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择状态',
                                },
                            ],
                        })(<Select
                            placeholder="请选择"
                        >
                            <Option value={1}>启用</Option>
                            <Option value={0}>禁用</Option>
                            <Option value={2}>删除</Option>
                        </Select>)
                    }
                </Form.Item>

                <Form.Item wrapperCol={{ span: 16, offset: 5 }}>
                    <Button
                        style={{ float: "right" }}
                        type="primary"
                        htmlType="submit"
                    >
                        确认修改
                        </Button>
                </Form.Item>

            </Form>
        )
    }
}
const ChangeRoleForm = Form.create()(ChangeRole)
export { ChangeRoleForm as ChangeRole }