import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, Icon } from 'antd';

const Bread = withRouter((props) => {
    //location 为浏览器默认的api
    const { location } = props;
    const url = location.pathname

    let isIndex = false;
    if (url === '/app') {
        isIndex = true;
    }

    const breadcrumbItems = [(

        <Breadcrumb.Item key="home">
            <Link to=""><Icon type="home" /></Link>
        </Breadcrumb.Item>
    )]
    const { currentMenu } = props;

    if (currentMenu) {
        const menus = currentMenu.split(':');
        for (let index = 0; index < menus.length; index++) {
            const element = menus[index];
            breadcrumbItems.push((
                <Breadcrumb.Item key={element}>
                    {element}
                </Breadcrumb.Item>
            ))
        }
    }
    return (
        isIndex ?
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item key="home">
                    <Icon type="home" />
                </Breadcrumb.Item>
            </Breadcrumb> :
            <Breadcrumb style={{ margin: "16px 0" }}>
                {breadcrumbItems}
            </Breadcrumb>
    );
});

export default Bread;