import { node } from "prop-types";
import { isUnaryExpression } from "@babel/types";

//默认状态
const defaultStatus = 'process';
const initialState = () => {
    return ({
        last: 0,
        current: 0,
        posting: false,
        status: defaultStatus,
        Information: {},
        detailedConfig: [],
        detaid:{},
        nodetaid:{},
        marketingInfo:{},
        statusArray:[],
        nodetailedConfig:[],
        nostatusArray:[],
        detailedConnfigOnline:[],
        noConfig:[],
        dataSource:[]
        
    });
}

const setparameters = (state = initialState(), action) => {
    let marketingInfo=action.marketingInfo;
    if (action.type==='SAVE_MARKETING_INFO') {
        return{
            ...state,
            data:marketingInfo
        }
    }
    //返回上一步
    if (action.type === 'PREVIOUS') {
        return {
            ...state,
            current: state.last,
            last: --state.last,
            status: defaultStatus,
        }
    }


    //未分成详细配置
    if (action.type === 'SUBMIT_NODETAILEDCONFIG') {
        let nodetailedConfig = action.nodetailedConfig;
        let dataSource=action.nodetailedConfig;
        let nodetaid = action.id;
        let nochange = action.change;
        let nochangeFixed = action.changeFixed;
        let noConfig = state.nodetailedConfig ? state.nodetailedConfig : [];
        console.log("t", action);
        if (nodetailedConfig && nodetailedConfig.length) {
            const param = {
                id: 0,
                conditions: []
            };
            nodetailedConfig.map(cfg => {
                param.id = cfg.id;
                const item = {
                    operator: cfg.operator,
                    type: cfg.endValue===cfg.startValue?2:cfg.type,
                    profit: cfg.profit,
                    parameterValue: cfg.endValue !== undefined ?cfg.endValue===cfg.startValue?cfg.startValue:
                        `(${cfg.startValue},${cfg.endValue}]` :
                        cfg.startValue
                };
                param.conditions.push(item);
            })

            const pIndex = noConfig.findIndex(i => i.id == param.id);
            if (pIndex > -1) {
                noConfig[pIndex] = param;
            }
            else {
                noConfig.push(param);
            }

        }
        else {
            const pIndex = noConfig.findIndex(x => x.id == nodetailedConfig.id)
            if (pIndex > -1) {
                noConfig[pIndex] = nodetailedConfig;
            }
            else {
                noConfig.push(nodetailedConfig);
            }
        }

        console.log("conditions", noConfig);

        let nostatusArray = [];
        var id = { id: nodetaid };
        var noch = { nochange: nochange };
        var nochFixed = { nochangeFixed: nochangeFixed };
        if (state.nostatusArray) {
            nostatusArray = state.nostatusArray;
            if (nostatusArray.length === 0) {

                nostatusArray.push(Object.assign(id, noch, nochFixed))
            }
            else {
                for (let i = 0; i < nostatusArray.length; i++) {
                    if (nostatusArray[i].id === nodetaid) {
                        nostatusArray[i] = (Object.assign(id, noch, nochFixed));
                        break;
                    }
                    if (nostatusArray.length - 1 === i) {
                        nostatusArray.push(Object.assign(id, noch, nochFixed))
                        break;
                    }
                }
            }

        }
        return {
            ...state,
            noConfig: noConfig,
            detaid: nodetaid,
            nostatusArray: nostatusArray,
            dataSource:dataSource
        }
    }

    //以分成详细配置
    if (action.type === 'SUBMIT_DETAILEDCONFIG') {
        let detailedConfig = action.detailedConfig;
        let detaid=action.id;
        let change=action.change;
        let changeFixed=action.changeFixed;
        let operatorid=action.operator;
        let Config = []; 
         detailedConfig=Object
        .assign({
            bisid:detailedConfig.bisid,
            id:detailedConfig.id,
            operator:detailedConfig.operator,
            type:detailedConfig.type,
            profit:detailedConfig.profit,
            parameterValue:detailedConfig.endValue!==undefined?`(${detailedConfig.startValue},${detailedConfig.endValue}]`:detailedConfig.startValue})
            console.log(detailedConfig);
            
        if (state.detailedConfig) {
            Config = state.detailedConfig;
            if (Config.length===0){
                Config.push(detailedConfig);
            }
            else{
                for (var i = 0; i < Config.length; i++) {
                    if (Config[i].id === detaid) {
                        Config[i] = detailedConfig;
                        break;
                    }
                    if (Config.length-1===i) {
                        Config.push(detailedConfig);
                        break;
                    }
                }

                
            }     
        }
        let statusArray = [];
        var id={id:detaid};
        var ch={change:change};
        var chFixed={changeFixed:changeFixed};
        var opid={opid:operatorid}
        if (state.statusArray) {
            statusArray = state.statusArray;
            if (statusArray.length === 0) {
                statusArray.push(Object.assign(id, ch, chFixed,opid))
            }
            else {
                for (let i = 0; i < statusArray.length; i++) {
                    if (statusArray[i].id === detaid) {
                        statusArray[i] = (Object.assign(id, ch, chFixed,opid));
                        break;
                    }
                    if (statusArray.length-1===i) {
                        statusArray.push(Object.assign(id, ch, chFixed,opid))
                        break;
                    }
                }
            }   
        }
        return {
            ...state,
            detailedConfig: Config,
            detaid:detaid,
             statusArray:statusArray,
            
             
        }
    }
    //提交表单
    if (action.type === 'SUBMIT_FORM') {
        return {
            ...state,
            posting: true,
        };
    }
    //提交失败
    if (action.type === 'SUBMIT_FINISH') {
        let status = 'finish'
        let current = state.current + 1;
        let posting = true;
        if (action.error) {
            status = 'error';
            current -= 1;
            posting = false;
        }
        return {
            ...state,
            posting: posting,
            current: current,
            status: status
        }
    }
    return state;
}

const setparametersRoot = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined;
    }
    return setparameters(state, action);
}
export default setparametersRoot