import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

class Error403 extends React.Component {
    render() {
        return (
            <Result
                status="403"
                title="403"
                subTitle="你没有此页面的访问权限"
                extra={
                    <Link to='/'>
                        <Button type="primary">返回首页</Button>
                    </Link>}
            />
        )
    }
}
export { Error403 as Error403 }