import React from 'react';
import { roleService, menuService } from '../../services';
import { connect } from 'react-redux';
import { ChangeRole } from '../../pages/RolesPage'
import { ActionModule } from './ActionModule'
import {
    Descriptions,
    Table,
    Tag,
    Modal,
    Button,
    Input,
    message,
    Form,
    Spin,
    Radio,
    Checkbox,
    Row,
    Col,
    Divider,
    Tree
} from 'antd';
const { TreeNode } = Tree;
class RoleModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role: props.role,
            roleId: props.role.id,
            delectrole: {},
            roleMenus: [],
            createing: false,
            loading: false,
            delecting: false,
            selectedKeys: [],
            checkedKeys: {},
            expandedKeys: ['0'],
            changeRoleVisible: false,
        }
    }
    //页面自加载所需信息
    async componentDidMount() {
        await this.roleMenus();
        const selectedMenus = [];
        this.state.roleMenus.filter(menu => menu.chooseMenu)
            .map(menu => {
                if (!menu.children) {
                    selectedMenus.push(menu.menuId)
                }
            });

        this.state.roleMenus.filter(menu => menu.children)
            .map(menu => menu.children.map(sub => {
                if (sub.chooseMenu) {
                    selectedMenus.push(sub.menuId)
                }
            }))

        this.setState({
            checkedKeys: selectedMenus
        })
    }
    //获取角色与菜单的关联
    async roleMenus() {
        this.setState({
            loading: true
        })
        await roleService.roleMenus(this.state.role.id)
            .then(response => {
                if (response) {
                    let result = response.data;
                    this.setState({
                        roleMenus: result.data,
                        loading: false
                    })
                }
            })
    }

    //更改角色的菜单权限
    checkedRoleMenus = async () => {
        try {
            const { selectedKeys } = this.state;
            this.setState({
                createing: true
            });
            await roleService
                .changeRolesMenus(this.state.role.id, selectedKeys.filter(key => key !== '0'))
                .then(response => {
                    if (response) {
                        let isok = response.data;
                        if (!isok.success) {
                            message.error(isok.message)
                        }
                        else {
                            message.success('添加成功');
                            this.setState({
                                createing: false
                            })
                        }
                    }
                })
        }
        catch (ex) {
            message.error('保存失败', 5);
        }
    }

    delectRoleSubmit = async () => {
        try {
            this.setState({
                delecting: true
            })
            await roleService
                .deleteRole([this.state.role.id])
                .then(response => {
                    if (response) {
                        let result = response.data;
                        if (!result.success) {
                            message.error("删除失败")
                            this.setState({
                                delecting: false
                            })
                        }
                        else {
                            message.success('删除成功')
                            this.setState({
                                delecting: false
                            })
                            this.props.handlePaging();
                            this.props.onClose();
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }
    renderValue(key, value) {
        if (key === 'status') {
            switch (value) {
                case 0:
                    return (
                        <Tag color="black">
                            禁用
                        </Tag>
                    )
                case 1:
                    return (
                        <Tag color="skyblue">
                            启用
                        </Tag>
                    )
                case 2:
                    return (
                        <Tag color="red">
                            删除
                        </Tag>
                    )
            }
        }
        return value;
    }
    renderKey(key) {
        if (key === 'roleName') {
            return '角色名';
        }
        if (key === 'status') {
            return '状态';
        }
        return key;
    }
    showChangeRoleModal = () => {
        this.setState({
            changeRoleVisible: true,
        })
    }
    handleChangeRoleCancel = () => {
        this.setState({
            changeRoleVisible: false
        })
    }
    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = (checkedKeys, e) => {
        const checked = [...checkedKeys, ...e.halfCheckedKeys];
        this.setState({
            selectedKeys: checked,
            checkedKeys: checkedKeys
        });
    };

    renderTreeNodes = roleMenus =>
        roleMenus.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.menuName} key={item.menuId} >
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.menuName} key={item.menuId} />;
        });

    render() {
        const { role } = this.state;
        const { createing } = this.state;
        const { loading } = this.state;
        const { delecting } = this.state;

        return (
            <Spin tip="删除中" spinning={delecting}>
                <Spin tip="加载中" spinning={loading}>

                    <Button type="danger"
                        user={this.state.roleId}
                        onClick={this.delectRoleSubmit}
                        style={{ float: "right" }}
                    >删除角色
                </Button>
                    <Button type="ghost"
                        role={this.state.role}
                        onClick={this.showChangeRoleModal}
                        style={{ float: "right", marginRight: 15, marginBottom: 10 }}
                    >
                        更改角色信息
                        </Button>
                    <Descriptions title="角色详情" bordered>
                        {
                            Object.keys(role).filter(key => {
                                if (key === 'id') return false;
                                if (key === 'key') return false;

                                return true;
                            }).map(key =>
                                <Descriptions.Item key={key}
                                    label={this.renderKey(key)}
                                >
                                    {this.renderValue(key, role[key])}
                                </Descriptions.Item>
                            )
                        }
                    </Descriptions>
                    <Modal
                        title='修改角色信息'
                        visible={this.state.changeRoleVisible}
                        onCancel={() => this.handleChangeRoleCancel()}
                        footer={null}
                    >
                        <ChangeRole role={this.props.role}
                            handlePaging={this.props.handlePaging.bind(this)}
                            handleChangeRoleCancel={this.handleChangeRoleCancel.bind(this)}
                        />
                    </Modal>

                    <Divider orientation="left">角色菜单</Divider>
                    <><Spin tip="修改中" spinning={createing}>
                        <Tree
                            checkable
                            selectable={false}
                            onExpand={this.onExpand}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            checkedKeys={this.state.checkedKeys}
                            onCheck={this.onCheck}
                        >
                            <TreeNode key={0} title='系统根目录'>
                                {this.renderTreeNodes(this.state.roleMenus)}
                            </TreeNode>
                        </Tree>
                        <Button
                            title="提交"
                            type="primary"
                            onClick={() => this.checkedRoleMenus()}
                            style={{ float: "right" }}
                        >提交</Button>
                    </Spin>
                    </>
                    <Divider orientation="left">角色接口权限</Divider>
                    <ActionModule role={this.state.role} />

                </Spin>
            </Spin>
        )
    }
}
export { RoleModule }