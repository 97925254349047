import React from 'react';
import { Table, Button, Spin, Tag ,Drawer} from 'antd';
import { reportService } from '../../services';
import { MarketingOverViewReport } from './MarketingOverViewReport';


class MarketingUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            count:{},
            selected:{}
        }

    }
    async componentDidMount() {
        await this.users()
    }
    //获取登录用户的分成用户
    async users() {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            await reportService.users(user.id)
                .then(response => {
                    if (response) {
                        let result = response.data;
                        let count=result.count;
                        var list = result.data;
                        list.map((item, index) => item['key'] = index);
                        this.setState({
                            list: list,
                            count:count,
                            selected:list[0].id
                        })
                    }
                })
        }
        catch (ex) {
            
        }
    }
    //显示Drawer事件
    showDrawer = record => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: record,
        })

    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }
    render() {
        const columns = [
            {
                title: '分成用户姓名',
                dataIndex: 'marketingusername',
                key: 'marketingusername',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (record.Status === 0) {
                        return (
                            <Tag color="black" key={record.status}>
                                禁用
                  </Tag>
                        )
                    }
                    if (record.status === 1) {
                        return (
                            <Tag color="skyblue" key={record.status}>
                                启用
                </Tag>
                        )
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },
        ];
        const data = this.state.list;
        return (
            <>
               {this.state.count!==1? 
               <>
               <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                    defaultExpandAllRows={true}
                />
                <Drawer
                    title="用户营销报表"
                    width={'100%'}
                    onClose={this.onClose}
                    visible={this.state.visibleDrawer}
                    destroyOnClose={true}>
                    <MarketingOverViewReport record={this.state.selected} />
                </Drawer>
                </>:
                <MarketingOverViewReport record={this.state.selected} />
                
            }
            </>

        )
    }
}
export { MarketingUsers }