import axios from 'axios';
import { async } from 'q';
import responsiveObserve from 'antd/lib/_util/responsiveObserve';

const hosts = {
    roles: "/api/role",
    createRole: "/api/role/create",
    deleteRole: "/api/role/delete",
    changeRole: "/api/role/change",
    roleMenus: "/api/role/authenticate",
    changeRolesMenus: "/api/role/authenticate",
    erase: "/api/role/erase",
    undodelete: "/api/role/undodelete",
    action: "/api/role/action",
    changeAction: "/api/role/action"
}

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}

export const roleService = {
    roles,
    createRole,
    deleteRole,
    changeRole,
    roleMenus,
    changeRolesMenus,
    erase,
    undodelete,
    action,
    changeAction
}
//获取接口权限
async function action(roleid) {
    const response = await myget(hosts.action, {
        params: {
            roleid: roleid
        }
    })
    return response;
}
//更改接口权限
async function changeAction(roleid, actionsid) {
    const response = await mypost(hosts.changeAction, {
        roleid: roleid,
        actionsid: actionsid
    })
    return response;
}
//删除角色
async function erase(password, deleteIds) {
    const response = await mypost(hosts.erase, {
        password: password,
        deleteIds: deleteIds
    })
    return response;
}
//撤销删除
async function undodelete(ids, status, pwd) {
    const response = await mypost(hosts.undodelete, {
        ids: ids,
        status: status,
        pwd: pwd
    })
    return response;
}
//获取角色信息
async function roles(search, status, pageindex, pagesize) {
    if (isNaN(status)) {
        status = null;
    }

    const response = await myget(hosts.roles, {
        params: {
            search: search,
            status: status,
            pageindex: pageindex,
            pagesize: pagesize
        }
    })
    return response;
}
//更改角色
async function changeRole(id, roleName, status) {
    const response = await mypost(hosts.changeRole, {
        id: id,
        roleName: roleName,
        status: status
    })
    return response;
}
//添加角色信息
async function createRole(roleName) {
    const response = await mypost(hosts.createRole, {
        roleName: roleName,
    })
    return response;
}

//删除角色信息
async function deleteRole(id) {
    const response = await mypost(hosts.deleteRole, id)
    return response;
}

//获取角色与菜单的关联
async function roleMenus(roleId) {
    const response = await myget(hosts.roleMenus, {
        params: {
            roleId: roleId
        }
    })
    return response;
}

//更改角色的授权菜单
async function changeRolesMenus(roleId, menus) {
    const response = await mypost(hosts.changeRolesMenus, {
        roleId: roleId,
        menus: menus
    })
    return response;
}