import React from 'react';
import { Steps } from 'antd';
import { Pricelist } from './Pricelist';
import { AddPhototypes } from './AddPhototypes';
import { AddTreminal } from './AddTreminal';
import { TerminalConfig } from './TerminalConfig';
import { connect } from 'react-redux';
import { ConfirmPage } from './ConfirmPage';
import { SuccessPage } from './SuccessPage'
import '@/pages/TerminalManagementPage/TerminalSettings.css';

const Step = Steps.Step
const steps = [{
    title: '输入设备信息',
    content: '输入设备信息'
}, {
    title: '修改设配置信息',
    content: '修改设配置信息'
}, {
    title: '设备照片类型',
    content: '设备照片类型'
}, {
    title: '修改价目表',
    content: '修改价目表'
},
{
    title: '确认',
    connect: '确认添加'
},
{
    title: '完成',
    content: '完成设备配置添加'
}]


class TerminalSettings extends React.Component {
    render() {
        const { current, status } = this.props;
        return (
            <div style={{ minWidth: 720, padding: "5px", margin: "0 auto" }}>
                <Steps current={current} status={status}>
                    {steps.map(item => <Step key={item.title} title={item.title} />)}
                </Steps>
                {
                    current === 0 &&
                    <AddTreminal />
                }
                {
                    current === 1 &&
                    <TerminalConfig />
                }
                {
                    current === 2 &&
                    <AddPhototypes />
                }
                {
                    current === 3 &&
                    <Pricelist />
                }
                {
                    current === 4 &&
                    <ConfirmPage />
                }
                {
                    current === 5 &&
                    <SuccessPage />
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.terminalSetting
    }
}

const TerminalSettingsPage = connect(mapStateToProps)(TerminalSettings)
export { TerminalSettingsPage as TerminalSettings }