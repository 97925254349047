import axios from 'axios';
import { any } from 'prop-types';
import { async } from 'q';

const hosts = {
    allmenus: "/api/menu",
    deleteMenu: "/api/menu/delete",
    changeMenu: "/api/menu/change",
    createMenu: "/api/menu/create",
    rootMenus: "/api/menu/root",
    erase: "/api/menu/erase",
    undodelete: "/api/menu/undodelete"
}

//封装get方法,含http报错异常逻辑
async function myget(url, config) {
    const response = await axios.get(url, config).catch(error => {
        const { response } = error;
        const code = response.status;
        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
//封装post方法,含http报错异常逻辑
async function mypost(url, config) {
    const response = await axios.post(url, config).catch(error => {
        const { response } = error;
        const code = response.status;

        if (code === 401) {
            window.location.reload();
        }
        else {
            window.location.href = `/app/error/${code}`;
        }
    })
    return response;
}
export const menuService = {
    allMenus,
    deleteMenu,
    changeMenu,
    createMenu,
    rootMenus,
    erase,
    undodelete
}
//获取所有菜单信息
async function allMenus(search, status, pageindex, pagesize) {
    const response = await myget(hosts.allmenus, {
        params: {
            search: search,
            status: status,
            pageindex: pageindex,
            pagesize: pagesize
        }
    })
    return response;
}
//撤销删除
async function undodelete(ids, status, pwd) {
    const response = await mypost(hosts.undodelete, {
        ids: ids,
        status: status,
        pwd: pwd
    })
    return response;
}
//删除菜单
async function deleteMenu(id) {
    const response = await mypost(hosts.deleteMenu, id)
    return response;
}
//修改菜单
async function changeMenu(id, icon, menuname, url, parentId, status) {
    const response = await mypost(hosts.changeMenu, {
        id: id,
        icon: icon,
        menuname: menuname,
        url: url,
        parentId: parentId,
        status: status
    })
    return response;
}

//磁盘删除设备
async function erase(password, deleteIds) {
    const response = await mypost(hosts.erase, {
        password: password,
        deleteIds: deleteIds
    })
    return response;
}
//创建菜单
async function createMenu(icon, menuName, url, parentId, status) {
    const response = await mypost(hosts.createMenu, {
        icon: icon,
        menuName: menuName,
        url: url,
        parentId: parentId,
        status: status
    })
    return response;
}

//获取所有根菜单
async function rootMenus() {
    const response = await myget(hosts.rootMenus)
    return response;
}