import React from 'react';
import {
    Tabs,
    Input,
    Form,
    Button,
    Spin,
    InputNumber,
    Tooltip,
    Icon
} from 'antd';
import { terminalManagenmentService } from '../../services';
import { connect } from 'react-redux';

const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

class TerminalConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false
        }
    }
    prev() {
        const { dispatch } = this.props;
        dispatch({
            type: 'PREVIOUS'
        });
    }
    async componentDidMount() {
        await this.TerminalConfig();
    }
    //终端设备配置信息
    async TerminalConfig() {
        try {
            this.setState({
                loading: true
            })

            await terminalManagenmentService.TerminalConfig()
                .then(response => {
                    if (response) {
                        if (response) {
                            let result = response.data;
                            if (!result.success) {
                            }
                            else {
                                this.setState({
                                    data: result.data,
                                    loading: false
                                })
                            }
                        }
                    }
                })
        }
        catch (ex) {
            alert(ex);
        }
    }

    //修改终端配置信息
    changeTerminalConfig = e => {
        e.preventDefault();
        const { form } = this.props;
        const { dispatch } = this.props;
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                dispatch({
                    type: 'SUBMIT_CONFIG',
                    current: 2,
                    config: values
                });
            }
        })
    }
    render() {
        const { loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        const keys = Object.keys(data);
        const { config } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
        };
        return (
            <Spin tip="加载中" spinning={loading}>
                <Form onSubmit={e => this.changeTerminalConfig(e)}>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        {keys.map(key =>
                            <TabPane tab={key} key={`tab_${key}`}>{
                                data[key].map((item, index) => [
                                    <Form.Item label={item.name} key={`id[${index}]_${item.id}`} style={{ display: 'none' }}>
                                        {
                                            getFieldDecorator(`${key}[${index}].id`, {
                                                initialValue: item.id
                                            })(<InputNumber />)
                                        }
                                    </Form.Item>,
                                    <Form.Item
                                        label={<span>
                                            {item.name}&nbsp;
                                        <Tooltip title={item.description}>
                                                <Icon type="question-circle-o" style={{ color: "blue" }} />
                                            </Tooltip>
                                        </span>} key={`val[${index}_${item.id}]`}>
                                        {
                                            getFieldDecorator(`${key}[${index}].value`, {
                                                initialValue: config[key] ?
                                                    config[key][index].value :
                                                    (item.value ? item.value : item.defaultvalue)
                                            })(<Input
                                                suffix={
                                                    <Tooltip title={item.description}>
                                                        <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                } />)
                                        }
                                    </Form.Item>
                                ])
                            }
                            </TabPane>)}
                    </Tabs>
                    <Form.Item>
                        <Button onClick={() => this.prev()} style={{ float: "left" }} >上一步</Button>
                        <Button htmlType="submit" type='primary' style={{ float: "right" }} >下一步</Button>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
}
const mapStateToProps = state => {
    return {
        ...state.terminalSetting
    }
}

const TerminalConfigForm = Form.create()(TerminalConfig)
const TerminalConfigConnect = connect(mapStateToProps)(TerminalConfigForm)
export { TerminalConfigConnect as TerminalConfig }