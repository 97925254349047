import React from 'react';
import { Button, Table, Spin, Input, Drawer, Divider } from 'antd';
import { marketingService } from '../../services';
import { TerminalDetails } from '../MarketingUserPage';
import { TerminalIntoUsers } from '../MarketingTerminalPage'

class MarketingTerminalePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            pagesize: 10,
            value: null,
            totalCount: 0,
            visibleModal: false,
            visibleDrawer: false,
            loading: false,
        }
    }
    //显示Drawer事件
    showDrawer = record => {
        this.setState({
            ...this.state,
            visibleDrawer: true,
            selected: record,
        })
    }
    //关闭Drawer事件
    onClose = () => {
        this.setState({
            ...this.state,
            visibleDrawer: false,
        });
    }
    //查询用户信息，list分页，搜索功能
    handlePaging(value, page, size) {
        this.setState({
            ...this.state,
            loading: true
        })
        marketingService.marketiTerminals(value, page - 1, size)
            .then(response => {
                if (response) {
                    var result = response.data;
                    var list = result.data;
                    list.map((item, index) => item['key'] = index);
                    this.setState({
                        page: page,
                        value: value,
                        pagesize: size,
                        list: list,
                        totalCount: result.count,
                        loading: false,
                    })
                }
            });
    }
    //调取所有用户信息
    componentDidMount() {
        const { pagesize, value } = this.state;
        this.handlePaging(value, 1, pagesize);
    }

    render() {
        const { Search } = Input;
        const { loading } = this.state;
        const columns = [
            {
                title: '设备编号',
                dataIndex: 'terminalId',
                key: 'terminalId',
                width: "30%"
            },

            {
                title: '设备名',
                dataIndex: 'terminalname',
                key: 'terminalname',
                width: "30%"
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: "40%",
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.showDrawer(record)}>查看</a>
                    </span>
                ),
            },
        ];
        return (<Spin tip={"加载中..."} spinning={loading}>
            <Search placeholder="请输入分成设备名"
                onSearch={value => this.handlePaging(value, 1, this.state.pagesize)}
                enterButton style={{ width: 330, marginBottom: 15 }}
            />
            <Table columns={columns} dataSource={this.state.list}
                pagination={{
                    showSizeChanger: true,
                    onShowSizeChange: (current, pagesize) => {
                        this.handlePaging(this.state.value, 1, pagesize);
                    },
                    pageSizeOptions: ['10', '25', '50', '100', '200'],
                    pageSize: this.state.pagesize,
                    current: this.state.page,
                    total: this.state.totalCount,
                    onChange: (page, pageSize) => {
                        this.handlePaging(this.state.value, page, pageSize);
                    }
                }
                }
            />
            <Drawer
                title="分成用户详情页"
                width={"100%"}
                onClose={this.onClose}
                visible={this.state.visibleDrawer}
                destroyOnClose={true}>
                <TerminalDetails record={this.state.selected} />
                <Divider orientation="left"><b>该设备参与分成的用户</b></Divider>
                <TerminalIntoUsers record={this.state.selected} />
            </Drawer>
        </Spin>
        )
    }
}

export { MarketingTerminalePage }